export const paletteLight = {
  primary: "#031434",
  secondary: "#011f5d",
  feedback: {
    alert: "#fb882c",
    error: "#902727",
    success: "#55a63a",
  },
  gray: {
    dark: "#b4b4b4",
    medium: "#e9eaed",
    light: "#f7f8f9",
    neutral: "#979797",
    secondaryGray: "#eaeef4",
    conversationSubtitle: "#626262",
    borders: "#dbdce0",
  },
  statistics: ["#011f5d", "#4574d5", "#3eacf0", "#6be1fd", "#d1daeb"],
  bordersBlue: "#1e2a3f",
  brandBlue: "#005191",
  textBlue: "#7888a5",
  white: "#ffffff",
  yellow: "#e0a93e",
  background: {
    light: "#f7f7f7",
    lightBlue: "#f7f8f9",
  },
  green: "#55a63a",
};
