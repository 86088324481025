import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import clsx from "clsx";
import useTranslation from "../../../../hooks/useTranslation";
import { Door } from "../../../../store/user/userInterfaces";
import { ItemPrice, Product, Variant } from "../../../../interfaces/productInterface";
import { instanceOfProduct, instanceOfVariant } from "../../../../utils/productUtils";
import LoaderMultidoorSize from "../../../styled-UI/loader/LoaderMultidoorSize";
import SizeContainer from "../../size-container/SizeContainer";
import ConditionalRender from "../../ConditionalRender";
import TileFooterAccessories from "./TileFooterAccessories";
import TileFooterButton from "./TileFooterButton";
import CustomText from "../../../styled-UI/CustomText";
import AvailabilityStatus from "../../AvailabilityStatus";
import { useSelector } from "react-redux";
import {
  selectLoadingAvailability,
  selectProductAvailability,
} from "../../../../store/catalogue/catalogueSlice";
import Loader from "../../../styled-UI/Loader";
import { selectLocale, selectSelectedDoor } from "../../../../store/user/userSlice";
import {
  getLabelAvailability,
  getLabelAvailabilityShipping,
} from "../../../../utils/catalogueUtils";
import { formatDate } from "../../../../utils/dateUtils";
import TileFooterAddToCart from "./TileFooterAddToCart";
import { checkIsAFAOrHelmet } from "../../../../utils/AFAutils";
import { AvailabilityStatusByDoor } from "../../../../store/catalogue/catalogueInterface";

const ProductStatus = ({ data }: { data: Product | Variant }) => {
  const { translateLabel } = useTranslation();
  const loading = useSelector(selectLoadingAvailability);
  const productAvailabityStatus: AvailabilityStatusByDoor[] = useSelector(
    selectProductAvailability
  );
  const selectedDoor = useSelector(selectSelectedDoor);
  const currentLocale = useSelector(selectLocale);
  const isAfa = !!(data?.productCategory === "afa");
  const isHelmet = !!(data?.productCategory === "helmet");

  const skuID = (isAfa || isHelmet) && instanceOfProduct(data) ? data.skuUniqueID : data.uniqueID;

  const [currentAvailabilityStatus, setCurrentAvailabilityStatus] = useState<string | undefined>();
  const [currentAvailabilityDate, setCurrentAvailabilityDate] = useState<Date>(new Date());

  useEffect(() => {
    if (skuID !== undefined && productAvailabityStatus.length !== 0) {
      const availability = productAvailabityStatus.find(
        (_) => _.doorId === selectedDoor?.orgentityName
      );

      const currentAvailability = availability?.availabilityInfo?.find((_) => _.uniqueId === skuID);

      setCurrentAvailabilityStatus(currentAvailability?.x_state);
      if (currentAvailability?.availabilityDateTime)
        setCurrentAvailabilityDate(new Date(currentAvailability?.availabilityDateTime));
    }
  }, [productAvailabityStatus]);

  // useEffect(() => {
  //   if (data && skuID) {
  //     dispatch(
  //       getProductAvailability({
  //         ids: [skuID],
  //       })
  //     );
  //   }
  // }, [data]);

  return (
    <FooterContentAfa
      className={`${
        !!(
          currentAvailabilityStatus &&
          getLabelAvailabilityShipping(currentAvailabilityStatus) !== ""
        )
          ? "grid-mode"
          : "flex-mode"
      }`}
    >
      {loading && <Loader sizePx={16} />}
      {!loading && currentAvailabilityStatus && (
        <>
          <FooterAvailability>
            <AvailabilityStatus inventoryStatus={currentAvailabilityStatus} isAfa={isAfa} />
            <CustomText as="span" fontSizePx={13} font="font-bold" marginLeftPx={8}>
              {translateLabel(getLabelAvailability(currentAvailabilityStatus))}
            </CustomText>
          </FooterAvailability>

          <CustomText as="span" fontSizePx={13} marginLeftPx={12}>
            {translateLabel(getLabelAvailabilityShipping(currentAvailabilityStatus)).replace(
              "{DATE}",
              formatDate(currentAvailabilityDate, "P", currentLocale)
            )}
          </CustomText>
        </>
      )}
    </FooterContentAfa>
  );
};

interface Props {
  data: Product | Variant;
  isVariant?: boolean;
  isHorizontalTile?: boolean;
  showSizeRadio?: boolean;
  isCarousel?: boolean;
  isMultidoor?: boolean;
  productCode?: string; //TODO DA CAPIRE lo si può prendere da data
  togglePopup?: () => void;
  price?: ItemPrice;
  door?: Door;
  loadingAddSize?: boolean;
  showAddToCartButton?: boolean;
  addToCart?: (data: Variant) => void;
  starsSkus?: string[];
  compactMultidoorSelect?: boolean;
  isPDPTile?: boolean;
}

const TileFooter = ({
  data,
  isVariant = false,
  isHorizontalTile = false,
  isCarousel = false,
  productCode,
  isMultidoor,
  togglePopup,
  price, // for SizeContainer
  door, // for SizeContainer
  loadingAddSize,
  showAddToCartButton = false,
  addToCart,
  starsSkus,
  compactMultidoorSelect,
  isPDPTile,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const type = data.productCategory;
  const others: any[] = []; //TODO make buttons
  const isAfa = !!(data?.productCategory === "afa");

  const printContent = () => {
    if (!instanceOfProduct(data)) return;

    return (
      <>
        {(type == "afa" || type == "helmet") && <ProductStatus data={data} />}
        {type == "accessory" && data.uniqueID && (
          <TileFooterAccessories uniqueId={data.uniqueID} isCarousel={isCarousel} />
        )}
        {(type == "frame" || type == "goggle" || type === "smartglasses" || type === "audio") && (
          <FooterContentEyeWear
            className={clsx(others && others?.length === 1 && "footer-one-items")}
          >
            {(data as Product).ctas?.map((cta, i, ctas) => {
              let priv;
              if (cta.key === "SPARE_PARTS") priv = "SPARE_PARTS_SECTION";

              return (
                <ConditionalRender privilege={priv} key={cta.key}>
                  <FooterItem
                    className={clsx(others && others.length === 2 && "footer-item-two-items")}
                    style={{ width: ctas.length === 2 ? "100%" : "unset" }}
                  >
                    <TileFooterButton url={cta.url}>{translateLabel(cta.label)}</TileFooterButton>
                  </FooterItem>
                </ConditionalRender>
              );
            })}
          </FooterContentEyeWear>
        )}
      </>
    );
  };

  const printVariantContent = () => {
    if (!instanceOfVariant(data) || !data.skus) return;

    if (type == "afa" || type == "helmet") return <ProductStatus data={data} />;
    if (!(isHorizontalTile && isMultidoor && !door))
      return (
        <SizeContainer
          skus={data.skus}
          price={price ?? data.price}
          dataElementIdRXPLP="Variants_RX"
          code={productCode}
          enableDataDescription
          isMultidoor={isMultidoor}
          togglePopup={togglePopup}
          variantId={data.uniqueID}
          door={door}
          isLoading={loadingAddSize}
          isAfa={isAfa}
          starsSkus={starsSkus}
          compactMultidoorSelect={compactMultidoorSelect}
          showFamilySizeFrames={isPDPTile}
        />
      );
    else return <LoaderMultidoorSize isMultidoor={isMultidoor} />;
  };

  return (
    <Footer
      className={clsx(
        showAddToCartButton && "footer--min-height",
        checkIsAFAOrHelmet(type) && "footer--center"
      )}
    >
      <Container className={clsx(showAddToCartButton && "footer--max-height")}>
        {isVariant ? printVariantContent() : printContent()}
      </Container>
      {showAddToCartButton && (
        <TileFooterAddToCart addToCart={() => addToCart && addToCart(data as Variant)} />
      )}
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 4.75rem;
  width: 100%;

  &.footer--min-height {
    min-height: 17.25rem;
    flex-grow: 1;
  }

  &.footer--center {
    justify-content: center;
    min-height: 3rem;
  }
`;

const Container = styled.div`
  &.footer--max-height {
    max-height: 12rem;
    overflow: auto;
  }
`;

const FooterContentEyeWear = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: inherit;

  &.footer-one-items {
    align-items: flex-end;
  }
`;

const FooterContentAfa = styled.div`
  &.grid-mode {
    display: grid;
    grid-template-columns: 3.5fr 5.5fr;

    & > div > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &.flex-mode {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: inherit;
  }
`;

const FooterAvailability = styled.div`
  display: grid;
  grid-template-columns: 1fr 8fr;
`;
const FooterItem = styled.button`
  flex-grow: 1;
  min-width: 50%;
  padding: 0 0.25rem 0.25rem 0;
  justify-content: space-between;

  &.footer-item-two-items {
    min-width: 100%;
  }
`;

const FooterNotAvailable = styled.div`
  margin: 3.75rem 1rem;
  text-align: center;
`;

const FooterContentSize = styled.div`
  padding: 0;

  > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.footer-horizontal {
    padding: 0;
    > div {
      margin-bottom: 0.5rem;
    }
  }
`;

export default TileFooter;
