import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, xor } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, fork, join, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import { Col, ItemsMap, Page } from "../../interfaces/gridInterfaces";
import { BrandGroupsValue, SecondLevelMenu } from "../../interfaces/menuInterfaces";
import { Product, Sku, Variant } from "../../interfaces/productInterface";
import { getBoolAttribute } from "../../utils/productUtils";
import { QueryParams } from "../search/searchInterfaces";
import { selectProductPerPage } from "../search/searchSlice";
import { getPageLayoutService } from "../store/storeInterfaces";
import {
  genericChannelWatcher,
  getCmsContent,
  handleError,
  takeEveryUnlessSameKey,
} from "../store/storeSagas";
import storeService from "../store/storeService";
import {
  addTechIconContents,
  CMS_ROUTES,
  saveBrandList,
  saveLpVariantsList,
  saveMenu,
  savePageLayout,
  selectBrandGroups,
  selectItemsMap,
  selectMenu,
  selectPagesLayout,
  setLpVariantsListStatus,
} from "../store/storeSlice";
import {
  AvailabilityStatus,
  BestsellersCatalogue,
  DoorInfo,
  GetAttachmentsVariantPayload,
  GetLandingPageVariantsPayload,
  GetPDPVariantInfoAllDoorsPayload,
  GetPlpCataloguePayload,
  GetPlpLayoutPayload,
  GetPlpRecommandedPayload,
  getPlpRecommendedOptions,
  GetPlpStarsCataloguePayload,
  GetPlpStarsStatisticsPayload,
  GetPrePlpCataloguePayload,
  getPrePlpContentsPayload,
  GetPrePLPStarsContentsPayload,
  GetProductAvailabilityPayload,
  GetProductWithVariantsPayload,
  GetVariantDetailsPayload,
  GetVariantsExpandedTilePayload,
  InitializePDPVariantInfoPayload,
  InstagramBadgesPayload,
  KeylookProductsPayload,
  LoadingPdp,
  PDPCmsContents,
  PDPVariantInfo,
  PDPVariantsInfo,
  PlpCatalogue,
  PlpCatalogueNoAdv,
  PlpRecommendedPayload,
  PlpStarsCatalogue,
  PlpVariantsCatalogue,
  PlpVariantsOptions,
  PrePlpStarsCatalogueVisibility,
  ProductInstagramBadge,
  ProductRecommendationCatalogue,
  ProductsCompatiblePayload,
  ProductWithVariantsPayload,
  QRPayload,
  ResolvePlpSlugPayload,
  SetPDPVariantInfoPayload,
  SimilarProductsPayload,
  VideoAvailability,
} from "./catalogueInterface";
import catalogueService from "./catalogueService";
import {
  appendPlpVariants,
  resetState,
  saveAdvContents,
  saveAlternativeProductId,
  saveExplosionVideo,
  saveImagesCarouselPdp,
  saveImagesCarouselPdpStatus,
  saveInstagramBadges,
  saveKeylookContents,
  saveMoodboard,
  saveOpticianTips,
  savePDPKeylookProducts,
  savePDPProductsCompatible,
  savePDPSimilarProducts,
  savePDPVariantFacets,
  savePDPVariantsInfo,
  savePDPVariantsInfoLoading,
  savePdpVariantsList,
  savePlpCatalogue,
  savePlpFacetView,
  savePlpStarsCatalogue,
  savePlpStarsFacetView,
  savePlpStarsRestOfCatalogue,
  savePlpStarsRestOfCatalogueCMSInfo,
  savePlpStarsRestOfCatalogueStatus,
  savePlpStarsStatistics,
  savePlpStarsStatisticsStatus,
  savePlpVariants,
  savePlpVariantsCatalogue,
  savePlpVariantsFacetView,
  savePrePLPBrandGroup,
  savePrePLPCarousels,
  savePrePLPStarsCarousels,
  savePrePLPStarsCarouselsStats,
  savePrePLPStarsCarouselsVisibility,
  saveProductAvailability,
  saveProductRecommendation,
  saveProductRecommendationStatus,
  saveProductsCompatibleProductId,
  saveProductWithVariants,
  saveQRCode,
  saveSeeOnInstagramLink,
  saveSeethroughImage,
  saveSeethroughWithoutImage,
  saveSimilarProductId,
  saveSportContents,
  saveVariantDetails,
  saveVariantsForRTR,
  saveVideoAvailability,
  saveVideoContents,
  saveView360Pdp,
  saveVTOTransitionLens,
  selectCurrentCategory,
  selectCurrentProduct,
  selectCurrentVariant,
  selectExpandedTilePageSize,
  selectHasPricePrivilege,
  selectMultidoorHasPricePrivilege,
  selectPDPVariantsInfo,
  selectPdpVariantsList,
  selectPopupMultidoorSetting,
  selectPrePLPBrandGroup,
  selectPrePLPCarousels,
  selectProductWithVariants,
  selectVTOTransitionLens,
  setBestSeller,
  setBestSellerRequestStatus,
  setCurrentCategoryPlp,
  setCurrentProductPdp,
  setExpandedTileRequestStatus,
  setIsPrePLPBrandGroupStars,
  setIsPrePLPBrandGroupStarsStatus,
  setLoadingAvailability,
  setLoadingPdp,
  setLoadingPlp,
  setLoadingPrePlp,
  sliceName,
} from "./catalogueSlice";

import {
  addPricesToPlpCatalogue,
  addPricesToPlpVariants,
  addPricesToPlpVariantsCatalogue,
  addPriceToProductorVariant,
  getProductCategory,
  mapAttachments,
  mapBestsellersCatalogue,
  mapKeylookResponse,
  mapPlpCatalogue,
  mapPlpCatalogueNoAdv,
  mapPlpStarsCatalogue,
  mapPrePlpCatalogue,
  mapPriceResponse,
  mapTechIconsResponse,
  mapVideoAvailabilityArray,
  PREPLP_STARS_CONFIG,
  setupUpdatedPDPVariantInfo,
} from "../../utils/catalogueUtils";
import {
  mapAvailability,
  mapProductObj,
  mapProductsArray,
  mapVariantObj,
  mapVariantsArray,
  putCurrentVariantAsFirst,
} from "../../utils/productUtils";

import { FacetView, FacetViewEntry, FacetViewMacroFamily } from "../../interfaces/facetInterfaces";
import { checkIsAFAOrHelmet } from "../../utils/AFAutils";
import {
  getAdvContents,
  getCategoryInfo,
  getFiltersFromMenu,
  getMoodBoard,
  getOpticianTips,
} from "../../utils/cmsUtils";
import { getFiltersFromParams } from "../../utils/filterUtils";
import { initializeGrid } from "../../utils/gridUtils";
import { getUniqueIdFrames } from "../../utils/menuUtils";
import { composeUrlWithParams, groupArrayIntoArray, splitArrayInChunks } from "../../utils/utils";
import { setLoadingAnalyticsData } from "../analytics/analyticsSlice";
import { AvailableProduct } from "../messages/messagesInterfaces";
import messagesService from "../messages/messagesServices";
import { Door } from "../multidoor/multidoorInterface";
import {
  selectAtLeastOneTruePrivileges,
  selectDoorIdsWithStarsPrivilege,
  selectDoorsForMultidoorAddSize,
  selectIsMultidoor,
  selectIsStarsMultidoor,
  selectIsSubuser,
  selectSelectedDoor,
  selectSelectedDoorIfStarsMultidoor,
} from "../user/userSlice";
import {
  GetAlternativeModelPayload,
  GetAlternativeVariantServicePayload,
} from "../warranty-wizard/warrantyWizardInterface";
import warrantyWizardService from "../warranty-wizard/warrantyWizardService";
import { getAftersalesType } from "../aftersales/aftersalesUtility";

/* ACTIONS */

/////////////////// PRE-PLP
export const getPrePlpContents = createAction<getPrePlpContentsPayload>(
  sliceName + "/getPrePlpContents"
);
export const getPrePlpCatalogue = createAction<GetPrePlpCataloguePayload>(
  sliceName + "/getPrePlpCatalogue"
);

export const getPrePLPStarsContents = createAction<GetPrePLPStarsContentsPayload>(
  sliceName + "/getPrePLPStarsContents"
);

/////////////////// PLP
export const resolvePlpSlug = createAction<ResolvePlpSlugPayload>(sliceName + "/resolvePlpSlug");
export const getPlpVariants = createAction<PlpVariantsOptions>(sliceName + "/getPlpVariants");
export const getPlpRecommendedVariants = createAction<PlpRecommendedPayload>(
  sliceName + "/getPlpRecommendedVariants"
);
export const getPlpLayout = createAction<GetPlpLayoutPayload>(sliceName + "/getPlpLayout");
export const getPlpCatalogue = createAction<GetPlpCataloguePayload>(sliceName + "/getPlpCatalogue");
export const getPlpRecommended = createAction<GetPlpRecommandedPayload>(
  sliceName + "/getPlpRecommended"
);

export const getPlpStarsCatalogue = createAction<GetPlpStarsCataloguePayload>(
  sliceName + "/getPlpStarsCatalogue"
);
export const getPlpStarsStatistics = createAction<GetPlpStarsStatisticsPayload>(
  sliceName + "/getPlpStarsStatistics"
);

export const getPLPInstagramBadges = createAction<InstagramBadgesPayload>(
  sliceName + "/getPLPInstagramBadges"
);

export const getPlpStarsRestOfCatalogue = createAction<GetPlpCataloguePayload>(
  sliceName + "/getPlpStarsRestOfCatalogue"
);

export const getPlpStarsRestOfCataloguePrices = createAction<PlpCatalogue>(
  sliceName + "/getPlpStarsRestOfCataloguePrices"
);

export const cancelPlpStarsRestOfCatalogue = createAction(
  sliceName + "/cancelPlpStarsRestOfCatalogue"
);

/////////////////// PDP
export const resolvePdpSlug = createAction<{ slug: string; isReset?: boolean }>(
  sliceName + "/resolvePdpSlug"
);
export const getVariantDetails = createAction<GetVariantDetailsPayload>(
  sliceName + "/getVariantDetails"
);
export const getVariantVideoAvailability = createAction<string>(
  sliceName + "/getVariantVideoAvailability"
);
export const getProductWithVariants = createAction<ProductWithVariantsPayload>(
  sliceName + "/getProductWithVariants"
);
export const getRTRItem = createAction<string>(sliceName + "/getRTRItem");
export const getPdpCmsContents = createAction<PDPCmsContents>(sliceName + "/getPdpCmsContents");
export const getPDPTechIconsCms = createAction<PDPCmsContents>(
  sliceName + "/getPDPTechIconsCmsSaga"
);
export const getPDPCMChannelKeylookCms = createAction<PDPCmsContents & { sport?: string }>(
  sliceName + "/getPDPCMChannelKeylookCms"
);
export const getPDPCMChannelSportCms = createAction<PDPCmsContents & { sport?: string }>(
  sliceName + "/getPDPCMChannelSportCms"
);
export const getPDPImagesCarousel = createAction<string>(sliceName + "/getPDPImagesCarousel");
export const getPDPView360 = createAction<string>(sliceName + "/getPDPView360");
export const getAllPDPImages = createAction<string>(sliceName + "/getAllPDPImages");
export const getQRCode = createAction<QRPayload>(sliceName + "/getQRCode");
export const getVMMVTransitions = createAction(sliceName + "/getVMMVTransitions");
export const getPDPVariantsList = createAction<GetProductWithVariantsPayload>(
  sliceName + "/getPDPVariantsList"
);
export const setPDPVariantInfo = createAction<SetPDPVariantInfoPayload>(
  sliceName + "/setPDPVariantInfo"
);
export const initializePDPVariantInfo = createAction<InitializePDPVariantInfoPayload>(
  sliceName + "/initializePDPVariantInfo"
);
export const getPDPVariantInfoAllDoors = createAction<GetPDPVariantInfoAllDoorsPayload>(
  sliceName + "/getPDPVariantInfoAllDoors"
);
export const cancelPDPVariantInfoAllDoors = createAction(
  sliceName + "/cancelPDPVariantInfoAllDoors"
);

/////////////////// COMMONS
export const getProductAvailability = createAction<GetProductAvailabilityPayload>(
  sliceName + "/getProductAvailability"
);
export const getVariantsExpandedTile = createAction<GetVariantsExpandedTilePayload>(
  sliceName + "/getVariantsExpandedTile"
);

/////////////////// BESTSELLERS
export const getBestSellers = createAction<number | undefined>(sliceName + "/getBestSellers");
export const getBestSellersPrices = createAction<BestsellersCatalogue>(
  sliceName + "/getBestSellersPrices"
);
export const cancelBestSellers = createAction(sliceName + "/cancelBestSellers");

///////////////// ALTERNATIVE PRODUCT
export const resolveAlternativeProductSlug = createAction<string>(
  sliceName + "/resolveAlternativeProductSlug"
);
export const getAlternativeProduct = createAction<GetAlternativeModelPayload>(
  sliceName + "/getAlternativeProduct"
);
export const getAlternativeVariant = createAction<GetAlternativeVariantServicePayload>(
  sliceName + "/getAlternativeVariant"
);

///////////////// KEYLOOK
export const getPDPKeylookProducts = createAction<KeylookProductsPayload>(
  sliceName + "/getPDPKeylookProducts"
);
export const getPDPKeylookProductsPrices = createAction<Product[]>(
  sliceName + "/getPDPKeylookProductsPrices"
);

export const cancelPDPKeylookProducts = createAction(sliceName + "/cancelPDPKeylookProducts");

///////////////// SIMILAR PRODUCT
export const getPLPSimilarProducts = createAction<SimilarProductsPayload>(
  sliceName + "/getPLPSimilarProducts"
);
export const getPDPSimilarProducts = createAction<SimilarProductsPayload>(
  sliceName + "/getPDPSimilarProducts"
);
export const getPDPSimilarProductsPrices = createAction<Product[]>(
  sliceName + "/getPDPSimilarProductsPrices"
);

export const cancelPDPSimilarProducts = createAction(sliceName + "/cancelPDPSimilarProducts");
export const resolveSimilarProductSlug = createAction<string>(
  sliceName + "/resolveSimilarProductSlug"
);

///////////////// PRODUCTS COMPATIBLE WITH ACCESSORIES
export const getPLPProductsCompatible = createAction<ProductsCompatiblePayload>(
  sliceName + "/getPLPProductsCompatible"
);

export const getPDPProductsCompatible = createAction<ProductsCompatiblePayload>(
  sliceName + "/getPDPProductsCompatible"
);

export const getPDPProductsCompatiblePrices = createAction<Product[]>(
  sliceName + "/getPDPProductsCompatiblePrices"
);

export const cancelPDPProductsCompatible = createAction(sliceName + "/cancelPDPProductsCompatible");

export const resolveProductsCompatibleSlug = createAction<string>(
  sliceName + "/resolveProductsCompatibleSlug"
);

//////////////// LANDING PAGE
export const getLPVariantsList = createAction<GetLandingPageVariantsPayload>(
  sliceName + "/getLPVariantsList"
);

//////////////// PRODUCT RECOMMENDATION
export const getProductRecommendation = createAction<number | undefined>(
  sliceName + "/getProductRecommendation"
);

export const getProductRecommendationPrices = createAction<ProductRecommendationCatalogue>(
  sliceName + "/getProductRecommendationPrices"
);

export const cancelProductRecommendation = createAction(sliceName + "/cancelProductRecommendation");

/* SAGAS */

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// PRE-PLP SAGAS ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getPrePlpContentsSaga({
  payload,
}: PayloadAction<getPrePlpContentsPayload>): SagaIterator {
  try {
    yield put(setLoadingPrePlp({ type: "prePLPContent", value: "LOADING" }));
    yield put(setIsPrePLPBrandGroupStarsStatus("LOADING"));
    let brandGroupCode = yield select(selectPrePLPBrandGroup); //GET BRAND GROUP CODE FROM REDUX

    if (!brandGroupCode) {
      const { data } = yield call(catalogueService.resolveSlug, payload.brand);
      brandGroupCode = data?.data?.contents?.[0]?.tokenExternalValue;
      if (brandGroupCode) yield put(savePrePLPBrandGroup(brandGroupCode));
    }

    if (brandGroupCode) {
      const params: getPageLayoutService = {
        page: "preplp",
        pageType: brandGroupCode,
        doorId: payload.doorId,
      };

      /* GET MENU OBJECT */
      /* check if we already have menu saved, if not wait for the action to be dispatched */
      let menu = yield select(selectMenu);
      if (menu.length === 0) {
        const saveMenuAction = yield take(saveMenu.type);
        menu = saveMenuAction.payload;
      }

      /* GET STARS PREPLP IF BRAND IS STARS AND USER HAS PRIVILEGE */
      let isStars = false;
      if (payload.canRenderPrivileges?.STARS) {
        isStars =
          menu?.[0]?.catalogGroupView?.find(
            (brand: SecondLevelMenu) => brand.identifier === brandGroupCode
          )?.isStars ?? false;

        yield put(setIsPrePLPBrandGroupStars(isStars));
        yield put(setIsPrePLPBrandGroupStarsStatus("SUCCESS"));
        if (isStars) {
          params.starsRequired = "true";
          if (!payload.saveRestOfCatalogue)
            yield put(setLoadingPrePlp({ type: "starsCarousels", value: "LOADING" }));
        }
      }

      /* CALL API FOR PREPLP LAYOUT */
      const layouts = yield select(selectPagesLayout);
      let pageLayout: Page = layouts["preplp"];
      if (!pageLayout || (pageLayout && !pageLayout.rows)) {
        const response = yield call(storeService.getPageLayout, params);
        if (response.data) {
          pageLayout = initializeGrid("preplp", response.data.data);
          yield put(savePageLayout(pageLayout));
        }
      }

      if (pageLayout && pageLayout.rows) {
        /* INITIALIZE GRID */
        const isSubuser = yield select(selectIsSubuser);
        const rows = pageLayout.rows;

        /***** CALL API FOR EACH PREPLP CONTENT *****/
        /* IF IS STARS, CHECK FOR CONTENT VISIBILITY */
        const visibility: PrePlpStarsCatalogueVisibility = {
          LATEST_INTRODUCTION: false,
          CURRENT_ASSORTMENT: true, //ALWAYS VISIBLE because if the full catalog
        };
        let hasCheckedVisibilityStars = false;
        const latestConfig = PREPLP_STARS_CONFIG["pre-plp-stars-latest-introduction"];
        const currentConfig = PREPLP_STARS_CONFIG["pre-plp-stars-current-assortment"];

        for (let i = 0; i < rows.length; i++) {
          const placementName = rows[i]?.name;
          const items: Col[] = rows[i]?.cols ?? [];

          if (
            isStars &&
            !payload.saveRestOfCatalogue &&
            !hasCheckedVisibilityStars &&
            (placementName === "pre-plp-stars-latest-introduction" ||
              placementName === "pre-plp-stars-current-assortment") &&
            items.length > 0
          ) {
            hasCheckedVisibilityStars = true;
            const { data } = yield call(catalogueService.getPrePLPStarsContents, {
              brandGroup: brandGroupCode,
              facetName: ["STARS_ASSORTMENT"],
              doorId: payload.doorId,
            });

            if (
              data.data.recordSetTotal > 0 &&
              data.data.facetView &&
              data.data.facetView.length > 0
            ) {
              const facetView = data.data.facetView;

              facetView?.[0]?.entry.map((_: FacetViewEntry) => {
                if (_.identifier === latestConfig.values?.[0]) {
                  visibility[latestConfig.identifier as keyof typeof visibility] = true;
                }
                // if (_.identifier === currentConfig.values[0]) {
                //   visibility[currentConfig.identifier as keyof typeof visibility] = true;
                // }
              });
            }
            yield put(savePrePLPStarsCarouselsVisibility(visibility));
          }

          if (items) {
            for (let j = 0; j < items.length; j++) {
              try {
                const id = items[j].id;

                /* CALL API FOR PREPLP CAROUSELS */
                if (items[j].type === "CMYLPrePlp") {
                  if (!isStars || (isStars && payload.saveRestOfCatalogue)) {
                    /* GET IDENTIFIER OF CAROUSEL */
                    const externalReference = items[j].subjectTaxonomy?.[0]?.externalReference;
                    const checkPrivilege =
                      externalReference === "accessories" && isSubuser
                        ? payload.canRenderPrivileges?.ACCESSORIES_SECTION
                        : payload.canRenderPrivileges?.FINISHED_PRODUCT_SECTION;

                    if (externalReference && checkPrivilege) {
                      let params: QueryParams = {};

                      /* GET BRAND GROUP INFO */
                      let brandGroup = yield select(selectBrandGroups);
                      if (Object.keys(brandGroup).length === 0) {
                        const brandGroupAction = yield take(saveBrandList.type);
                        brandGroup = brandGroupAction.payload;
                      }

                      /* GET BRAND FOR THE BRAND GROUP AND APPEND AS PARAMS */
                      const brandsByBrandgroup = brandGroup[brandGroupCode]?.map(
                        (brand: BrandGroupsValue): string => brand.brand
                      );
                      params["manufacturer.raw"] = brandsByBrandgroup.map((brand: string) => brand);

                      /* GET INFO TO POPULATE PREPLP CAROUSEL SECTION */
                      const catInfo = getCategoryInfo(menu, externalReference);

                      /* GET FILTERS FOR API CALL */
                      const filterParams = getFiltersFromMenu(menu, externalReference);
                      params = { ...params, ...filterParams };

                      if (catInfo) {
                        yield put(
                          getPrePlpCatalogue({
                            contentId: id,
                            categoryId: catInfo.categoryId,
                            productPerPage: payload.productsPerCarousel,
                            identifier: externalReference,
                            title: catInfo.title,
                            url: catInfo.url,
                            params: params,
                            brandGroupCode,
                            doorId: payload.doorId,
                          })
                        );
                      }
                    }
                  }
                } else {
                  /* API CALL FOR CMS CONTENT */
                  if (
                    isStars &&
                    !payload.saveRestOfCatalogue &&
                    ((placementName === "pre-plp-stars-latest-introduction" &&
                      visibility[latestConfig.identifier as keyof typeof visibility]) ||
                      (placementName === "pre-plp-stars-current-assortment" &&
                        visibility[currentConfig.identifier as keyof typeof visibility]))
                  ) {
                    /* GET IDENTIFIER OF CAROUSEL */
                    const externalReference = items[j].subjectTaxonomy?.[0]?.externalReference;

                    /* GET INFO TO POPULATE PREPLP CAROUSEL SECTION */
                    const catInfo = getCategoryInfo(menu, externalReference);
                    if (catInfo) {
                      catInfo.url = catInfo.url?.replace("/plp/", "/plp-stars/");

                      /* GET FILTERS FOR API CALL */
                      const facet = getFiltersFromMenu(menu, externalReference, true);

                      const config =
                        placementName === "pre-plp-stars-latest-introduction"
                          ? latestConfig
                          : currentConfig;

                      const url = catInfo.url?.split("?") ?? [];
                      let completeUrl = catInfo.url;

                      //ADD STARS_ASSORTMENT FILTER
                      const params = url[1] ? new URLSearchParams(url[1]) : new URLSearchParams();

                      if (placementName === "pre-plp-stars-latest-introduction")
                        params.append("STARS_ASSORTMENT", config.values?.[0]); //TAKE ONLY FIRST FILTER FOR URL

                      facet["STARS_ASSORTMENT"] = config.values;
                      completeUrl = url[0] + "?" + params.toString();

                      //ADD STATISTIC FILTERS
                      // const facetNameStats =
                      //   STATISTICS_MAP[
                      //     externalReference
                      //       ?.replace("-", "_")
                      //       ?.toUpperCase() as keyof typeof STATISTICS_MAP
                      //   ] ?? [];

                      yield put(
                        getPrePLPStarsContents({
                          contentId: id,
                          brandGroup: brandGroupCode,
                          facet: facet,
                          facetName: ["MACROFAMILY", "manufacturer.raw"],
                          title: catInfo?.title,
                          url: completeUrl,
                          categoryIdentifier: externalReference,
                          doorId: payload.doorId,
                        })
                      );
                    }
                  } else if (
                    placementName !== "pre-plp-stars-latest-introduction" &&
                    placementName !== "pre-plp-stars-current-assortment"
                  ) {
                    const itemsMap = yield select(selectItemsMap);

                    // The parameter 'fallback: true' is a temporary choice -> Has to be changed in future
                    if (!itemsMap[id] && placementName === "top-banner") {
                      yield put(getCmsContent({ id: id, fallback: true }));
                    } else if (!itemsMap[id]) {
                      yield put(getCmsContent({ id: id }));
                    }
                  }
                }
              } catch (err) {
                return err;
              }
            }
          }
        }
      }
    }
    yield put(setLoadingPrePlp({ type: "prePLPContent", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPrePlp({ type: "prePLPContent", value: "ERROR" }));
    console.log("error", error);
  }
}

/**
 * Get pre-plp contents to populate carousels
 *
 * @param {PayloadAction<GetPrePlpCataloguePayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPrePlpCatalogueSaga(action: PayloadAction<GetPrePlpCataloguePayload>): SagaIterator {
  try {
    yield put(setLoadingPrePlp({ type: "catalogue", value: "LOADING" }));
    ////////////////////////////////////// get PrePLP items

    const { data } = yield call(catalogueService.getPlpCatalogue, {
      contentId: action.payload.contentId,
      categoryId: action.payload.categoryId,
      productPerPage: action.payload.productPerPage,
      params: action.payload.params ?? {},
      facetName: "manufacturer.raw",
      doorId: action.payload.doorId,
    });

    if (data.data) {
      yield put(setLoadingAnalyticsData(true));
      let prePlpCatalogue = mapPrePlpCatalogue(data.data);

      /* APPEND NEW PRODUCTS TO EXISTING CATALOGUE */
      const prePlpCarousels = yield select(selectPrePLPCarousels);
      const oldCatalogue = prePlpCarousels[action.payload.contentId];
      let newResultList: Product[] = [];

      if (oldCatalogue) {
        newResultList = [...oldCatalogue.resultList, ...prePlpCatalogue.resultList];
        prePlpCatalogue = {
          ...prePlpCatalogue,
          //Assign resultList removing duplicates
          resultList: [...new Map(newResultList.map((_) => [_.uniqueID, _])).values()],
        };
      }

      /* GET BRANDS FROM BRAND GROUP */
      const brandGroupCode = action.payload.brandGroupCode;
      let brandGroup = yield select(selectBrandGroups);
      if (Object.keys(brandGroup).length === 0) {
        const brandGroupAction = yield take(saveBrandList.type);
        brandGroup = brandGroupAction.payload;
      }
      const brandsByBrandgroup =
        brandGroupCode &&
        brandGroup[brandGroupCode]?.map((brand: BrandGroupsValue): string => brand.brand);
      const brandCodes: string[] = [];

      /* CHECK WHICH BRANDS ARE IN THE FACETS AND APPEND THEM TO URL */
      const facets = data.data.facetView;
      brandsByBrandgroup?.forEach((brand: string) => {
        facets[0].entry.map((entry: any) => {
          if (brand === entry.identifier) {
            brandCodes.push(brand);
          }
        });
      });

      const brandParams = brandCodes.map((brand: string) => {
        return { key: "manufacturer.raw", value: brand };
      });

      prePlpCatalogue = {
        ...prePlpCatalogue,
        identifier: action.payload.identifier,
        title: action.payload.title,
        url: action.payload.url ? composeUrlWithParams(action.payload.url, brandParams) : "",
        contentId: action.payload.contentId,
        categoryId: action.payload.categoryId,
        params: action.payload.params,
      };

      if (prePlpCatalogue) {
        yield put(
          savePrePLPCarousels({
            id: action.payload.contentId,
            content: prePlpCatalogue,
          })
        ); // if present, save results (and stop loading)
        // yield put(setLoadingPrePlp({ type: "catalogue", value: "SUCCESS" }));
      }

      const productIds: string[] = []; // get ids
      data.data.resultList.forEach((result: any) => {
        result.uniqueID && productIds.push(result.uniqueID);
      });

      ////////////////////////////////////// GET PRICES
      try {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege) {
          const { data: price } = yield call(
            catalogueService.getPriceService,
            productIds,
            action.payload.doorId
          );
          const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

          const plpCatalogueWithPrices = addPricesToPlpCatalogue(prePlpCatalogue, priceData); // add prices to current catalogue
          yield put(
            savePrePLPCarousels({
              id: action.payload.contentId,
              content: plpCatalogueWithPrices,
            })
          );
        }
      } catch (error) {
        yield put(handleError(error));
      }

      //GET AVAIABILITY FOR AFA OR HELMET
      if (prePlpCatalogue?.resultList?.length > 0) {
        const productCategory = getProductCategory(prePlpCatalogue?.resultList);

        if (productCategory && checkIsAFAOrHelmet(productCategory))
          yield put(getProductAvailability({ ids: productIds }));
      }

      yield put(setLoadingAnalyticsData(false));
    } else yield put(setLoadingPrePlp({ type: "catalogue", value: "ERROR" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPrePlp({ type: "catalogue", value: "ERROR" }));
  }
}

/**
 * Get content for preplp stars carousel
 * @param  {PayloadAction<GetPrePLPStarsContentsPayload>} action
 */
function* getPrePLPStarsContentsSaga(
  action: PayloadAction<GetPrePLPStarsContentsPayload>
): SagaIterator {
  try {
    const { title, url, saveStats, sectionId, ...payload } = action.payload;

    if (saveStats) yield put(setLoadingPrePlp({ type: "starsStatistics", value: "LOADING" }));
    // else yield put(setLoadingPrePlp({ type: "starsCarousels", value: "LOADING" }));

    const { data } = yield call(catalogueService.getPrePLPStarsContents, payload);

    // save statistics
    if (saveStats) {
      if (data.data.facetView)
        yield put(
          savePrePLPStarsCarouselsStats({
            id: payload.categoryIdentifier + sectionId,
            content: data.data.facetView ?? [],
          })
        );
      yield put(setLoadingPrePlp({ type: "starsStatistics", value: "SUCCESS" }));

      return;
    }

    // save preplp components
    if (data.data.facetView) {
      const starsCarousel = data.data;

      /* GET BRANDS FROM BRAND GROUP */
      const brandGroupCode = action.payload.brandGroup;
      let brandGroup = yield select(selectBrandGroups);
      if (Object.keys(brandGroup).length === 0) {
        const brandGroupAction = yield take(saveBrandList.type);
        brandGroup = brandGroupAction.payload;
      }
      const brandsByBrandgroup =
        brandGroupCode &&
        brandGroup[brandGroupCode]?.map((brand: BrandGroupsValue): string => brand.brand);
      const brandCodes: string[] = [];

      /* CHECK WHICH BRANDS ARE IN THE FACETS AND APPEND THEM TO URL */
      const facets: FacetViewMacroFamily[] = starsCarousel.facetView;
      brandsByBrandgroup?.forEach((brand: string) => {
        facets?.forEach((_) => {
          if (_.value === "manufacturer.raw") {
            _.entry.map((entry) => {
              if (brand === entry.identifier) {
                brandCodes.push(brand);
              }
            });
          }
        });
      });

      const brandParams = brandCodes.map((brand: string) => {
        return { key: "manufacturer.raw", value: brand };
      });

      if (payload.contentId) {
        const content = {
          id: payload.contentId,
          content: {
            facet: payload.facet,
            brandGroup: brandGroupCode,
            categoryIdentifier: payload.categoryIdentifier,
            title,
            url: url ? composeUrlWithParams(url, brandParams) : "",
            ...(starsCarousel as any),
          },
        };

        yield put(savePrePLPStarsCarousels(content));
        // saving of status is done inside savePrePLPStarsCarousels otherwise error message is shown for a split second
      }
    } else yield put(setLoadingPrePlp({ type: "starsCarousels", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPrePlp({ type: "starsCarousels", value: "ERROR" }));
  }
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// PLP SAGAS ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Resolve the slug in url, gets category id for plp
 * @param  {PayloadAction<string>} action
 */
function* resolvePlpSlugSaga(action: PayloadAction<ResolvePlpSlugPayload>): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "slug", value: "LOADING" }));
    /* RESOLVE PLP SLUG */
    const { data } = yield call(catalogueService.resolveSlug, action.payload.slug);

    if (data?.data?.contents && data.data.contents.length > 0) {
      const category = data.data?.contents?.[0]?.identifier;
      /* SAVE CURRENT CATEGORY INFO */
      yield put(
        setCurrentCategoryPlp({
          category,
          categoryId: data?.data?.contents?.[0]?.tokenValue,
          tokenExternalValue: data?.data?.contents?.[0]?.tokenExternalValue,
          slug: action.payload.slug,
        })
      );

      yield put(setLoadingPlp({ type: "slug", value: "SUCCESS" }));

      /* GET NEW PLP LAYOUT BASED ON FILTERS AND CATEGORY */
      yield put(
        getPlpLayout({
          category: category,
          params: action.payload.params,
          starsRequired: action.payload.starsRequired ?? false,
          doorId: action.payload.doorId,
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPlp({ type: "slug", value: "ERROR" }));
  }
}

/**
 * Get the ids for the contents in PLP page, then will get content in the next saga.
 *
 * @param  {PayloadAction<CmsItem>} action
 */
function* getPlpCatalogueSaga(action: PayloadAction<GetPlpCataloguePayload>): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingPlp({ type: "facets", value: "LOADING" }));

    yield put(setLoadingAnalyticsData(true));

    const { categoryId } = yield select(selectCurrentCategory); // get categoryId to make call
    const productPerPage = yield select(selectProductPerPage); // get number of products to display

    ////////////////////////////////////// get PLP items

    const { data } = yield call(catalogueService.getPlpCatalogue, {
      contentId: action.payload.item.id,
      categoryId: categoryId,
      productPerPage: action.payload.productPerPage ?? productPerPage,
      params: action.payload.params ?? {},
      starsRequired: action.payload.starsRequired ?? false,
      doorId: action.payload.doorId,
    });

    yield put(savePlpCatalogue(null));

    const mappedData: { catalogue: PlpCatalogue; facetView: FacetView[] } | undefined =
      data?.data && mapPlpCatalogue(data.data);

    // if present, save results (and stop loading)
    if (mappedData?.catalogue?.resultList && mappedData?.catalogue?.resultList?.length > 0) {
      yield put(savePlpCatalogue(mappedData?.catalogue as PlpCatalogue));
      yield put(setLoadingPlp({ type: "catalogue", value: "SUCCESS" }));

      yield put(savePlpFacetView(mappedData?.facetView ?? null));
      yield put(
        setLoadingPlp({ type: "facets", value: mappedData?.facetView ? "SUCCESS" : "ERROR" })
      );

      const productIds: string[] = []; // get ids
      mappedData?.catalogue?.resultList?.forEach((result: any) => {
        result?.uniqueID && productIds.push(result.uniqueID);
      });

      ////////////////////////////////////// get prices
      try {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege && productIds.length > 0) {
          const { data: price } = yield call(
            catalogueService.getPriceService,
            productIds,
            action.payload.doorId
          );
          const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

          const plpCatalogueWithPrices = addPricesToPlpCatalogue(mappedData?.catalogue, priceData); // add prices to current catalogue
          yield put(savePlpCatalogue(plpCatalogueWithPrices)); // save updated catalogue
        }
      } catch (error) {
        yield put(handleError(error));
      }

      ////////////////////////////////////// get availability for AFA or helmet
      if (mappedData?.catalogue?.resultList?.length > 0) {
        const productCategory = getProductCategory(mappedData?.catalogue?.resultList);
        if (productCategory && checkIsAFAOrHelmet(productCategory)) {
          const idsForAFA: string[] = []; // get ids
          mappedData?.catalogue?.resultList?.forEach((result: any) => {
            result?.skuUniqueID && idsForAFA.push(result.skuUniqueID);
          });
          yield put(getProductAvailability({ ids: idsForAFA }));
        }
      }

      ///////////////////////////////////// get Instagram badges
      const parNumbers: string[] = [];
      mappedData?.catalogue?.resultList?.forEach((_: any) => {
        _?.productCode && parNumbers.push(_.productCode);
      });
      yield put(getPLPInstagramBadges({ partNumbers: parNumbers }));
    } else {
      yield put(savePlpCatalogue(null)); // save empty catalogue
      yield put(savePlpFacetView(null));
      yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
      yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
    }

    yield put(setLoadingAnalyticsData(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpCatalogue(null)); // save empty catalogue
    yield put(savePlpFacetView(null));
    yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
  }
}

/**
 * Recommended page PLP
 */
function* getPlpRecommendedSaga(action: PayloadAction<GetPlpRecommandedPayload>): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingPlp({ type: "facets", value: "LOADING" }));
    const productPerPage = yield select(selectProductPerPage);

    const { data } = yield call(catalogueService.getPlpRecommended, {
      productPerPage: action.payload.productPerPage ?? productPerPage,
      params: action.payload.params ?? {},
    });
    const plpVariantsCatalogue: PlpVariantsCatalogue = {
      resultList: mapVariantsArray(data.data?.catalogEntryView),
      resultsCount: data.data?.recordSetCount,
      totalProducts: data.data?.recordSetTotal,
    };
    const facets: FacetView[] = data.data?.facetView;

    if (plpVariantsCatalogue) {
      yield put(savePlpVariantsCatalogue(plpVariantsCatalogue));
      yield put(setLoadingPlp({ type: "catalogue", value: "SUCCESS" }));

      const productIds: string[] = []; // get ids
      plpVariantsCatalogue?.resultList?.forEach((result: any) => {
        result?.uniqueID && productIds.push(result.uniqueID);
      });

      ////////// get availability
      try {
        const productCategory = plpVariantsCatalogue.resultList[0]?.productCategory;
        const skuIds: string[] = []; // store all variants' skus' ids that appear in plpVariants

        plpVariantsCatalogue.resultList.forEach((variant) => {
          const variantSkuIds = variant.skus
            ? variant.skus?.map((_) => {
                return _.uniqueID;
              })
            : [];
          skuIds.push(...variantSkuIds);
        });

        if (productCategory && !checkIsAFAOrHelmet(productCategory))
          yield put(getProductAvailability({ ids: skuIds }));
      } catch (error) {
        yield put(handleError(error));
      }

      ////////////////////////////////////// get prices
      try {
        const { data: price } = yield call(
          catalogueService.getPriceService,
          productIds,
          action.payload.doorId
        );
        const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array
        const newResults = addPricesToPlpVariantsCatalogue(plpVariantsCatalogue, priceData); // add prices to current search results
        yield put(savePlpVariantsCatalogue(newResults));
      } catch (error) {
        yield put(handleError(error));
      }
    }
    if (facets) {
      yield put(savePlpVariantsFacetView(facets));
      yield put(setLoadingPlp({ type: "facets", value: facets ? "SUCCESS" : "ERROR" }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
  }
}

/**
 * Flow to the contents from the cms of the PLP page
 *
 * @param {PayloadAction<PlpCmsId>} action
 * @return {*}  {SagaIterator}
 */
function* getPlpLayoutSaga(action: PayloadAction<GetPlpLayoutPayload>): SagaIterator {
  yield put(setLoadingPlp({ type: "layout", value: "LOADING" }));

  const layout: { [page: string]: Page } = yield select(selectPagesLayout);
  const { starsRequired, category, doorId } = action.payload;
  let pageLayout = layout[CMS_ROUTES.PLP]; // get PLP layout from redux

  // if layout doesn't exist, call service to get it
  if (!pageLayout.rows) {
    const params: getPageLayoutService = {
      page: CMS_ROUTES.PLP,
      pageType: category,
      starsRequired: starsRequired?.toString() ?? "false",
      doorId: doorId,
    };

    try {
      const { data } = yield call(storeService.getPageLayout, params);
      if (data?.data) {
        pageLayout = initializeGrid(CMS_ROUTES.PLP, data.data); // map response into Page object
        yield put(savePageLayout(pageLayout)); // save layout in redux
      }
    } catch (error) {
      yield put(handleError(error));

      yield put(
        savePageLayout({
          page: "CMS_ROUTES.PLP",
        })
      ); // save empty page layout
    }
  }

  const brandParam = action.payload.params?.["manufacturer.raw"]; // get current brand from url, if it's there
  const itemsMap: ItemsMap = yield select(selectItemsMap); // get current
  yield put(setLoadingPlp({ type: "layout", value: "SUCCESS" }));

  try {
    const rows = pageLayout?.rows;
    if (!rows) return; // if layout rows are empty, return

    for (let i = 0; i < rows.length; i++) {
      const items = rows[i].cols as Col[];
      // if (!items) break; // if no items are present in row, return
      if (items) {
        for (let j = 0; j < items.length; j++) {
          try {
            switch (rows[i].name) {
              case "plp-body": // get plp catalogue contents (filters and products list)
                if (!starsRequired)
                  yield put(
                    getPlpCatalogue({
                      item: items[j],
                      params: action.payload.params,
                      doorId: doorId,
                    })
                  );
                break;

              case "plp-body-stars":
                //GET TITLES FOR BODY PLP
                yield put(
                  getCmsContent({
                    id: items[j].id,
                    starsRequired: true,
                  })
                );
                //GET CATALOGUE
                yield put(
                  getPlpStarsCatalogue({
                    params: action.payload.params,
                    doorId: doorId,
                  })
                );
                break;

              case "plp-stars-rest-of-catalogue":
                yield put(savePlpStarsRestOfCatalogueCMSInfo(items[j]));
                break;

              case "top-banner": // get top banner
                if (
                  !itemsMap[items[j].id] || // if it's not already in redux
                  xor(itemsMap[items[j].id]?.brand, brandParam).length !== 0 // or if the brands selected have changed
                )
                  yield put(
                    getCmsContent({
                      id: items[j].id,
                      brand: brandParam && brandParam.length > 0 ? brandParam : ["noBrand"],
                    })
                  );
                break;

              default:
                // get any other CMS content, if it's not already in redux
                if (!itemsMap[items[j].id])
                  yield put(
                    getCmsContent({
                      id: items[j].id,
                    })
                  );
                break;
            }
          } catch (error) {
            yield put(handleError(error));
          }
        }
      }
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPlp({ type: "layout", value: "ERROR" }));
  }
}

/**
 * Get the ids for the contents in PLP page, then will get content in the next saga.
 *
 * @param  {PayloadAction<CmsItem>} action
 */
function* getPlpStarsCatalogueSaga(
  action: PayloadAction<GetPlpStarsCataloguePayload>
): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingPlp({ type: "facets", value: "LOADING" }));

    yield put(setLoadingAnalyticsData(true));

    const { categoryId } = yield select(selectCurrentCategory); // get categoryId to make call
    const productPerPage = yield select(selectProductPerPage); // get number of products to display

    ////////////////////////////////////// get PLP items

    const { data } = yield call(catalogueService.getPlpStarsCatalogue, {
      categoryId: categoryId,
      productPerPage,
      params: action.payload.params ?? {},
      doorId: action.payload.doorId,
    });

    yield put(savePlpStarsCatalogue(null));

    const mappedData: { catalogue: PlpStarsCatalogue; facetView: FacetView[] } | undefined =
      data?.data && mapPlpStarsCatalogue(data.data);

    if (
      mappedData?.catalogue?.catalogEntryView &&
      mappedData?.catalogue?.catalogEntryView?.length > 0
    ) {
      yield put(savePlpStarsCatalogue(mappedData?.catalogue));
      yield put(setLoadingPlp({ type: "catalogue", value: "SUCCESS" }));

      yield put(savePlpStarsFacetView(mappedData?.facetView ?? null));
      yield put(
        setLoadingPlp({ type: "facets", value: mappedData?.facetView ? "SUCCESS" : "ERROR" })
      );

      ////////////////////////////////////// get prices
      try {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege) {
          const priceIds: string[] = []; // get ids
          mappedData.catalogue.catalogEntryView.forEach((result: any) => {
            result.uniqueID && priceIds.push(result.uniqueID);
          });

          if (priceIds.length > 0) {
            const { data: price } = yield call(
              catalogueService.getPriceService,
              priceIds,
              action.payload.doorId
            );
            const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

            const newResultList = cloneDeep(mappedData.catalogue.catalogEntryView);

            newResultList.map((sku: Sku) => {
              return addPriceToProductorVariant(sku, priceData);
            });

            const catalogueWithPrices = {
              ...mappedData.catalogue,
              catalogEntryView: newResultList,
            }; // add prices to current catalogue
            yield put(savePlpStarsCatalogue(catalogueWithPrices)); // save updated catalogue
          }
        }
      } catch (error) {
        yield put(handleError(error));
      }
    } else {
      yield put(savePlpStarsCatalogue(null)); // save empty catalogue
      yield put(savePlpStarsFacetView(null));
      yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
      yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
    }

    yield put(setLoadingAnalyticsData(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpStarsCatalogue(null)); // save empty catalogue
    yield put(savePlpStarsFacetView(null));
    yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
  }
}

/**
 * Get the ids for the contents in PLP page, then will get content in the next saga.
 *
 * @param  {PayloadAction<CmsItem>} action
 */
function* getPlpStarsStatisticsSaga(
  action: PayloadAction<GetPlpStarsStatisticsPayload>
): SagaIterator {
  try {
    yield put(savePlpStarsStatistics([]));
    yield put(savePlpStarsStatisticsStatus("LOADING"));

    const { data } = yield call(catalogueService.getPrePLPStarsContents, action.payload);

    const facetView = data.data.facetView;

    yield put(savePlpStarsStatistics(facetView));
    yield put(savePlpStarsStatisticsStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpStarsStatistics([]));
    yield put(savePlpStarsStatisticsStatus("ERROR"));
  }
}

/**
 * Get the ids for the contents in PLP page, then will get content in the next saga.
 *
 * @param  {PayloadAction<CmsItem>} action
 */
function* getPlpStarsRestOfCatalogueSaga(payload: GetPlpCataloguePayload): SagaIterator {
  yield put(savePlpStarsRestOfCatalogueStatus("LOADING"));

  try {
    const { categoryId } = yield select(selectCurrentCategory); // get categoryId to make call
    const productPerPage = yield select(selectProductPerPage); // get number of products to display

    ////////////////////////////////////// get PLP items

    const { data } = yield call(catalogueService.getPlpCatalogue, {
      contentId: payload.item.id,
      categoryId: categoryId,
      productPerPage: payload.productPerPage ?? productPerPage,
      params: payload.params ?? {},
      starsRequired: payload.starsRequired ?? false,
      doorId: payload.doorId,
    });

    const mappedData: { catalogue: PlpCatalogue; facetView: FacetView[] } | undefined =
      data?.data && mapPlpCatalogue(data.data);

    // if present, save results (and stop loading)
    if (mappedData?.catalogue) {
      yield put(
        savePlpStarsRestOfCatalogue({
          type: "add",
          catalogue: mappedData?.catalogue as PlpCatalogue,
        })
      );

      yield put(savePlpStarsRestOfCatalogueStatus("SUCCESS"));

      ////////////////////////////////////// get prices
      yield put(getPlpStarsRestOfCataloguePrices(mappedData?.catalogue));
    } else yield put(savePlpStarsRestOfCatalogueStatus("ERROR"));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpStarsRestOfCatalogue({ type: "reset" })); // save empty catalogue
    yield put(savePlpStarsRestOfCatalogueStatus("ERROR"));
  }
}

function* getPlpStarsRestOfCataloguePricesSaga(catalogue: PlpCatalogue): SagaIterator {
  try {
    const selectedDoorIfStarsMultidoor = yield select(selectSelectedDoorIfStarsMultidoor);
    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }

    if (hasPricePrivilege) {
      const priceIds: string[] = []; // get ids
      catalogue.resultList.forEach((result: any) => {
        result.uniqueID && priceIds.push(result.uniqueID);
      });

      if (priceIds.length > 0) {
        const { data: price } = yield call(
          catalogueService.getPriceService,
          priceIds,
          selectedDoorIfStarsMultidoor?.orgentityId
        );
        const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

        yield put(
          savePlpStarsRestOfCatalogue({
            type: "prices",
            prices: priceData,
          })
        ); // save updated catalogue
      }
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// PDP SAGAS ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Resolve the slug in url, gets productId for pdp
 *
 * @param {PayloadAction<string>} action slug
 * @return {*}  {SagaIterator}
 */
function* resolvePdpSlugSaga(
  action: PayloadAction<{ slug: string; isReset?: boolean }>
): SagaIterator {
  try {
    // TODO: temporary yikes workaround to clean up state when switching product
    // to be deleted when PDP will only use product as slug, and variant will be a query parameter
    if (action.payload.isReset) yield put(resetState());

    yield put(setLoadingPdp({ type: "slug", value: "LOADING" }));
    const currentProduct = yield select(selectCurrentProduct);

    const { data } = yield call(catalogueService.resolveSlug, action.payload.slug);

    const type = data?.data?.contents?.[0]?.page?.type; // whether we entered with a product or variant part number
    const tokenValue = data?.data?.contents?.[0]?.tokenValue; // the uniqueId associated with the product/variant

    if (tokenValue && type) {
      switch (type) {
        case "ProductPage": // if is product id
          yield put(setLoadingPdp({ type: "productWithVariants", value: "LOADING" }));
          yield put(setLoadingPdp({ type: "variantDetails", value: "LOADING" }));

          yield put(setCurrentProductPdp({ productId: tokenValue, variantId: null, type: type })); // save product id and reset variant id
          yield put(setLoadingPdp({ type: "slug", value: "SUCCESS" }));
          break;

        case "VariantPage": // if is variant id
          yield put(setLoadingPdp({ type: "variantDetails", value: "LOADING" }));
          if (!currentProduct.productId)
            yield put(setLoadingPdp({ type: "productWithVariants", value: "LOADING" }));

          yield put(
            setCurrentProductPdp({
              productId: currentProduct.productId,
              variantId: tokenValue,
              type: type,
            })
          );
          yield put(setLoadingPdp({ type: "slug", value: "SUCCESS" }));

          // we need the variant details at least once for both grid/list, to know which product we're dealing with
          // yield put(getVariantDetails({ variantId: tokenValue }));
          break;

        default:
          yield put(setCurrentProductPdp({ productId: null, variantId: null, type: null })); // reset current product
          yield put(setLoadingPdp({ type: "slug", value: "ERROR" }));
          break;
      }
    } else {
      yield put(setCurrentProductPdp({ productId: null, variantId: null, type: null })); // reset current product
      yield put(setLoadingPdp({ type: "slug", value: "ERROR" }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setCurrentProductPdp({ productId: null, variantId: null, type: null })); // reset current product
    yield put(setLoadingPdp({ type: "slug", value: "ERROR" }));
  }
}

/**
 * Get variant/moco for pdp page
 *
 * @param {PayloadAction<getVariantPdpPayload>} action productId and variantId
 * @return {*}  {SagaIterator}
 */
function* getVariantDetailsSaga(action: PayloadAction<GetVariantDetailsPayload>): SagaIterator {
  try {
    yield put(setLoadingPdp({ type: "variantDetails", value: "LOADING" }));
    const currentProduct = yield select(selectCurrentProduct);

    const { data } = yield call(catalogueService.getVariantDetails, action.payload);

    const variant =
      data.data?.catalogEntryView?.length > 0 && mapVariantObj(data.data?.catalogEntryView?.[0]);

    if (variant) {
      yield put(setLoadingAnalyticsData(true));
      yield put(saveVariantDetails(variant));
      yield put(setLoadingPdp({ type: "variantDetails", value: "SUCCESS" }));

      // if the productId already saved doesn't match the product corresponding to this variant
      // save the parentCatalogEntryID as the current product
      if (variant.parentCatalogEntryID && currentProduct.productId !== variant.parentCatalogEntryID)
        yield put(
          setCurrentProductPdp({
            ...currentProduct,
            productId: variant.parentCatalogEntryID,
          })
        );

      ////////////////////////////////////// get carousel's + 360's images
      // yield put(getPDPImagesCarousel(variant.uniqueID));
      // yield put(getPDPView360(variant.uniqueID));
      yield put(setLoadingAnalyticsData(false));
    } else {
      yield put(setLoadingPdp({ type: "variantDetails", value: "ERROR" }));
      yield put(saveVariantDetails(null));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPdp({ type: "variantDetails", value: "ERROR" }));
    yield put(saveVariantDetails(null));
  }
}

/**
 * Get video for SeeThemOn
 *
 * @param {PayloadAction<string>} action partnumber
 * @return {*}  {SagaIterator}
 */
function* getVariantVideoAvailabilitySaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoadingPdp({ type: "CMVideoAvailability", value: "LOADING" }));
    const { data } = yield call(catalogueService.getVideoAvailability, action.payload);

    const videos: VideoAvailability[] | null =
      data.data?.catalogEntryView?.[0]?.videos?.length &&
      mapVideoAvailabilityArray(data.data?.catalogEntryView?.[0]?.videos);

    if (videos) {
      yield put(saveVideoAvailability(videos));
      yield put(setLoadingPdp({ type: "CMVideoAvailability", value: "SUCCESS" }));
    } else {
      yield put(setLoadingPdp({ type: "CMVideoAvailability", value: "ERROR" }));
      yield put(saveVideoAvailability(null));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPdp({ type: "CMVideoAvailability", value: "ERROR" }));
    yield put(saveVideoAvailability(null));
  }
}

/**
 * Get PDP Variants for a product, passing product id.
 *
 *
 * @param {PayloadAction<GetProductWithVariantsPayload>} action productId
 * @return {*}  {SagaIterator}
 */
function* getPDPVariantsListSaga(
  action: PayloadAction<GetProductWithVariantsPayload>
): SagaIterator {
  try {
    const { variantId } = yield select(selectCurrentProduct);

    yield put(setLoadingPdp({ type: "productWithVariants", value: "LOADING" }));

    const { data } = yield call(catalogueService.getProductWithVariants, {
      ...action.payload,
    });

    const facetView = data.data?.facetView;
    if (facetView) yield put(savePDPVariantFacets(facetView));
    else yield put(savePDPVariantFacets([]));

    const product = mapProductObj(data.data?.catalogEntryView?.[0]);
    if (product) {
      yield put(saveProductWithVariants(product)); // need to save this regardless, as it's used to show the product's info

      if (product?.variants) {
        // if in currentProduct we have a variantId, it means we entered with a specified variant
        // put it in first place, if present (could not, based on filters)
        const variants = variantId
          ? putCurrentVariantAsFirst(product?.variants, variantId)
          : product.variants;

        // if we don't already have a variantId saved (ie. it's the first call after the productId changed)
        // save the first variant returned by the product as current variant
        const currentProduct = yield select(selectCurrentProduct);
        if (!currentProduct.variantId && variants?.[0]?.uniqueID) {
          yield put(
            setCurrentProductPdp({
              ...currentProduct,
              variantId: variants[0].uniqueID,
            })
          );
        }

        yield put(setLoadingPdp({ type: "productWithVariants", value: "SUCCESS" }));
        yield put(savePdpVariantsList(variants));
      } else {
        yield put(setLoadingPdp({ type: "productWithVariants", value: "ERROR" }));
        yield put(savePdpVariantsList([]));
      }
    } else {
      yield put(setLoadingPdp({ type: "productWithVariants", value: "ERROR" }));
      yield put(savePdpVariantsList([]));
      yield put(saveProductWithVariants(null));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPdp({ type: "productWithVariants", value: "ERROR" }));
    yield put(savePdpVariantsList([]));
  }
}

/**
 * Get variants for a product, passing product id.
 * Get variants without size, used in pdp page.
 * Take first variant id and get current variant info
 *
 * @param {PayloadAction<ProductWithVariantsPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getProductWithVariantsSaga(
  action: PayloadAction<ProductWithVariantsPayload>
): SagaIterator {
  try {
    yield put(setLoadingPdp({ type: "productWithVariants", value: "LOADING" }));
    const currentProduct = yield select(selectCurrentProduct);

    const productId = action.payload.productId;

    const { data } = yield call(catalogueService.getProductWithVariants, {
      productId,
      facets: action.payload.facets,
    });

    const facetView = data.data?.facetView;
    if (facetView) yield put(savePDPVariantFacets(facetView));
    else yield put(savePDPVariantFacets([]));

    const product = mapProductObj(data.data?.catalogEntryView?.[0]);
    if (product) {
      yield put(saveProductWithVariants(product)); // save product info + list of variants for carousel
      yield put(setLoadingPdp({ type: "productWithVariants", value: "SUCCESS" }));

      // if we don't already have a variantId saved (ie. it's the first call after the productId changed)
      // save the first variant returned by the product as current variant
      if (!currentProduct.variantId && product.variants?.[0]?.uniqueID) {
        yield put(
          setCurrentProductPdp({
            ...currentProduct,
            variantId: product.variants[0].uniqueID,
          })
        );
      }
    } else {
      yield put(saveProductWithVariants(null));
      yield put(setLoadingPdp({ type: "productWithVariants", value: "ERROR" }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveProductWithVariants(null));
    yield put(setLoadingPdp({ type: "productWithVariants", value: "ERROR" }));
  }
}

/**
 *
 *
 * @param {PayloadAction<SetPDPVariantInfoPayload>} action
 * @return {*}  {SagaIterator}
 */
function* setPDPVariantInfoSaga(action: PayloadAction<SetPDPVariantInfoPayload>): SagaIterator {
  try {
    //////////////////
    //////////////////
    ////////////////// setup:
    // - variantsInfoResult array with:
    //      - all the info previously available
    //      - new item for each new Variant
    //      - selectedDoor for each Variant as either (in order of availability): the one explicitely given as payload, the existing one, or the fallback
    // - variantsNeedingData array with list of each Variant id and corresponding door that requires the prices API + eventually the stars API
    // - uniqueIDsNeedingData array with list of all the UNIQUE Variant id requiring data, indipendently from the door

    const { fallbackDoor, PDPVariantDoor, forAllDoors } = action.payload;
    const isMassiveOrderMode = forAllDoors && forAllDoors.length > 0;
    const existingVariantsInfo: PDPVariantsInfo = yield select(selectPDPVariantsInfo); // get info for ALL variants currently stored

    const {
      variantsNeedingData,
      uniqueIDsNeedingData,
      variantsInfoResult,
    } = setupUpdatedPDPVariantInfo(PDPVariantDoor, existingVariantsInfo, fallbackDoor);

    // save results to have selected doors for each variant as soon as possible in the interface
    yield put(savePDPVariantsInfo(cloneDeep(variantsInfoResult)));

    if (variantsNeedingData.length === 0) return; // if no additional info is required, saving new selected door is enough

    //////////////////
    //////////////////
    ////////////////// if instead we need to retrieve additional prices / stars info:

    // set loading status for all variants requiring prices
    yield put(
      savePDPVariantsInfoLoading(
        uniqueIDsNeedingData.map((uniqueID) => {
          return {
            uniqueID: uniqueID,
            status: "LOADING",
          };
        })
      )
    );

    //////////////////
    ////////////////// MASSIVE ORDER MODE:
    ////////////////// requires prices for all doors, but can skip stars for those !== selectedDoor b/c they are not displayed

    if (isMassiveOrderMode && forAllDoors) {
      for (let i = 0; i < forAllDoors.length; i++) {
        // for each door retrieve prices for all variants
        try {
          const door = forAllDoors[i];

          // get prices for all variants for this door
          const { data: priceResponse } = yield call(
            catalogueService.getPriceService,
            uniqueIDsNeedingData,
            door?.orgentityId
          );

          const pricesResult = mapPriceResponse(priceResponse?.data);
          if (pricesResult) {
            for (let j = 0; j < pricesResult.length; j++) {
              const price = pricesResult[j];

              const currentVariant = variantsInfoResult?.find((_) => _.uniqueID === price.id);

              if (currentVariant) {
                currentVariant.doorInfo?.push({
                  doorId: door?.orgentityId,
                  price: price?.price,
                });

                yield put(savePDPVariantsInfo([cloneDeep(currentVariant)])); // save each variant at a time
              }
            }
          }
        } catch (error) {
          yield put(handleError(error));
        }
      }
    }

    //////////////////
    ////////////////// NORMAL MODE:
    ////////////////// requires prices for doors specified in the payload, or fallbackdoor
    else {
      // group variants by either the given or the fallback door, to reduce number of API calls
      const variantsNeedingDataGroupedByDoor = groupArrayIntoArray(
        variantsNeedingData,
        (i) => i.door?.orgentityId ?? fallbackDoor?.orgentityId
      );

      for (let i = 0; i < variantsNeedingDataGroupedByDoor.length; i++) {
        // for each door
        try {
          // get ALL the uniqueIDs of each variant that requires updating for this door
          const uniqueIDs: string[] = variantsNeedingDataGroupedByDoor[i].map((_) => _.uniqueID);
          const door = variantsNeedingDataGroupedByDoor[i]?.[0]?.door ?? fallbackDoor;

          // get prices for the variants
          const { data: priceResponse } = yield call(
            catalogueService.getPriceService,
            uniqueIDs,
            door?.orgentityId
          );

          const pricesResult = mapPriceResponse(priceResponse.data);
          if (pricesResult) {
            for (let j = 0; j < pricesResult.length; j++) {
              const price = pricesResult[j];

              const currentVariant = variantsInfoResult?.find((_) => _.uniqueID === price.id);

              if (currentVariant) {
                currentVariant.doorInfo?.push({
                  doorId: door?.orgentityId,
                  price: price?.price,
                });

                yield put(savePDPVariantsInfo([cloneDeep(currentVariant)])); // save each variant at a time
              }
            }
          }
        } catch (error) {
          yield put(handleError(error));
        }
      }
    }

    const isStarsMultidoor = yield select(selectIsStarsMultidoor);
    const doorIdsWithStarPrivilege: string[] = yield select(selectDoorIdsWithStarsPrivilege);

    if (isStarsMultidoor) {
      for (let i = 0; i < variantsNeedingData.length; i++) {
        const door = variantsNeedingData[i].door?.orgentityId ?? fallbackDoor?.orgentityId;

        if (doorIdsWithStarPrivilege.find((_) => _ === door)) {
          const { data } = yield call(catalogueService.getSkusByVariant, {
            variantId: variantsNeedingData[i].uniqueID,
            doorId: door,
          });

          const skusStars: string[] = [];

          data?.data?.catalogEntryView?.forEach((sku: any) => {
            if (sku?.uniqueID && getBoolAttribute(sku?.attributes, "STARS_NEW"))
              skusStars.push(sku?.uniqueID);
          });

          const currentVariant = variantsInfoResult.find(
            (_) => _.uniqueID === variantsNeedingData[i].uniqueID
          );

          if (currentVariant) {
            const currentDoor = currentVariant.doorInfo.find((_) => _.doorId === door);

            if (currentDoor) {
              currentDoor.starsSkus = skusStars;
              yield put(savePDPVariantsInfo([cloneDeep(currentVariant)])); // save each variant at a time
            }
          }
        }
      }
    }

    // yield put(savePDPVariantsInfo(variantsInfoResult)); // save results

    const isMultidoorPopupOpen = yield select(selectPopupMultidoorSetting);

    if (!isMultidoorPopupOpen.open) {
      // set loading status for all of them, but only if multidoor popup is not open
      yield put(
        savePDPVariantsInfoLoading(
          uniqueIDsNeedingData.map((uniqueID) => {
            return {
              uniqueID: uniqueID,
              status: "SUCCESS",
            };
          })
        )
      );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<InitializePDPVariantInfoPayload>} action
 * @return {*}  {SagaIterator}
 */
function* initializePDPVariantInfoSaga(
  action: PayloadAction<InitializePDPVariantInfoPayload>
): SagaIterator {
  try {
    const { fallbackDoor, indexStart, indexEnd, type } = action.payload;

    const pdpVariantsList: Variant[] = yield select(selectPdpVariantsList);
    const pdpVariantGrid: Variant = yield select(selectCurrentVariant);

    const variants =
      type === "list"
        ? pdpVariantsList.slice(indexStart, indexEnd ? indexEnd : indexStart)
        : [pdpVariantGrid];

    if (variants.length > 0) {
      // get prices for given variants and selected door
      yield put(
        setPDPVariantInfo({
          PDPVariantDoor: variants.map((_) => {
            return {
              uniqueID: _.uniqueID,
            };
          }),
          fallbackDoor,
          forAllDoors: action.payload.forAllDoors,
        })
      );

      // get availability
      const skusId: string[] = [];
      for (let i = 0; i < variants.length; i++) {
        const currentSkus = variants?.[i]?.skus?.map((sku) => sku.uniqueID);
        currentSkus && skusId.push(...currentSkus);
      }

      yield put(getProductAvailability({ ids: skusId }));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 *
 *
 * @param {PayloadAction<GetPDPVariantInfoAllDoorsPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPDPVariantInfoAllDoorsSaga(payload: GetPDPVariantInfoAllDoorsPayload): SagaIterator {
  try {
    const { uniqueID, doors } = payload;

    const existingVariantsInfo: PDPVariantsInfo = yield select(selectPDPVariantsInfo); // get info for ALL variants currently stored
    const isStarsMultidoor = yield select(selectIsStarsMultidoor);
    const doorIdsWithStarPrivilege: string[] = yield select(selectDoorIdsWithStarsPrivilege);

    // set loading status
    yield put(
      savePDPVariantsInfoLoading([
        {
          uniqueID: uniqueID,
          status: "LOADING",
        },
      ])
    );

    const newVariantInfo: PDPVariantInfo = existingVariantsInfo?.[uniqueID]
      ? cloneDeep(existingVariantsInfo?.[uniqueID])
      : {
          selectedDoor: doors[0],
          doorInfo: [],
          uniqueID: uniqueID,
        };

    ////////////////// retrieve prices
    // iterate on the doors
    for (let i = 0; i < doors.length; i++) {
      try {
        // check if we have info regarding the current door
        const currentDoorInfo = newVariantInfo.doorInfo.filter(
          (_) => _.doorId === doors[i]?.orgentityId
        )?.[0];

        const newDoorInfo: DoorInfo = {
          doorId: doors[i]?.orgentityId,
        };

        if (!currentDoorInfo) {
          // if it's missing, get corresponding price
          try {
            const { data: priceResponse } = yield call(
              catalogueService.getPriceService,
              [uniqueID],
              doors[i]?.orgentityId
            );

            const pricesResult = mapPriceResponse(priceResponse.data);

            if (pricesResult) newDoorInfo.price = pricesResult?.[0].price;
          } catch (error) {
            yield put(handleError(error));
          }

          // and if it's stars multidoor, get corresponding info
          if (isStarsMultidoor) {
            try {
              const skusStars: string[] = [];

              if (doorIdsWithStarPrivilege.find((_) => _ === doors[i]?.orgentityId)) {
                const { data } = yield call(catalogueService.getSkusByVariant, {
                  variantId: uniqueID,
                  doorId: doors[i]?.orgentityId,
                });

                data?.data?.catalogEntryView?.forEach((sku: any) => {
                  const uniqueID = sku?.uniqueID;
                  if (uniqueID && getBoolAttribute(sku?.attributes, "STARS_NEW"))
                    skusStars.push(uniqueID);
                });
              }

              newDoorInfo.starsSkus = skusStars;
            } catch (error) {
              yield put(handleError(error));
            }
          }

          newVariantInfo.doorInfo.push(newDoorInfo);

          yield put(savePDPVariantsInfo([cloneDeep(newVariantInfo)])); // save results
        } else if (isStarsMultidoor && currentDoorInfo?.starsSkus === undefined) {
          // even if we already have currentDoorInfo, but we are in starsMultidoor and starSkus has never bee defined, add only stars info
          try {
            const skusStars: string[] = [];

            if (doorIdsWithStarPrivilege.find((_) => _ === doors[i]?.orgentityId)) {
              const { data } = yield call(catalogueService.getSkusByVariant, {
                variantId: uniqueID,
                doorId: doors[i]?.orgentityId,
              });

              data?.data?.catalogEntryView?.forEach((sku: any) => {
                const uniqueID = sku?.uniqueID;
                if (uniqueID && getBoolAttribute(sku?.attributes, "STARS_NEW"))
                  skusStars.push(uniqueID);
              });
            }

            const existingDoorInfo = newVariantInfo.doorInfo.find(
              (door: DoorInfo) => door.doorId === doors[i]?.orgentityId
            );
            if (existingDoorInfo) existingDoorInfo.starsSkus = skusStars;

            yield put(savePDPVariantsInfo([cloneDeep(newVariantInfo)])); // save results
          } catch (error) {
            yield put(handleError(error));
          }
        }
      } catch (error) {
        yield put(handleError(error));
      }
    }

    // set loading status
    yield put(
      savePDPVariantsInfoLoading([
        {
          uniqueID: uniqueID,
          status: "SUCCESS",
        },
      ])
    );
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get partnumbers of variants enabled to RTR for the selected product used to show RTR button in PDP
 *
 * @param {}
 * @return {*}  {SagaIterator}
 */
function* getRTRItemSaga(action: PayloadAction<string>): SagaIterator {
  const currentProduct: Product | null = yield select(selectProductWithVariants);
  try {
    const { data } = yield call(catalogueService.getRTRItem, action.payload);
    if (data?.data?.catalogEntryView && data?.data?.catalogEntryView.length > 0 && currentProduct)
      yield put(
        saveVariantsForRTR({
          productPartNumber: currentProduct.productCode,
          RTRVariants: data?.data?.catalogEntryView,
        })
      );
    else
      put(
        saveVariantsForRTR({
          productPartNumber: currentProduct?.productCode ?? "",
          RTRVariants: [],
        })
      );
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get PDP cms contents
 * used for: CMRCAdvContent,CMRCCouvette, CMRCExplosionVideo, CMRCMoodBoard,
 * CMRCSeethrough, CMRCSeethroughWithout, CMRCOpticalTips
 *
 * @param {PayloadAction<PDPCmsContents>} action
 * @return {*}  {SagaIterator}
 */
function* getPDPCmsContentsSaga(action: PayloadAction<PDPCmsContents>): SagaIterator {
  try {
    yield put(setLoadingPdp({ type: action.payload.type as LoadingPdp, value: "LOADING" }));

    const { data } = yield call(catalogueService.getPdpCmsContents, action.payload);

    const result = data?.data?.content?.result;
    if (result && result.length > 0) {
      if (action.payload.type === "CMRCExplosionVideo") {
        const explosionVideoUrl = result[0]?.explosionVideo[0]?.data?.uri;

        if (explosionVideoUrl) {
          yield put(saveExplosionVideo(explosionVideoUrl));
        }
      }

      if (action.payload.type === "CMRCSeethrough") {
        const seethroughImgUrl = result[0]?.with[0]?.data?.uri;

        if (seethroughImgUrl) {
          yield put(saveSeethroughImage(seethroughImgUrl));
        }
      }

      if (action.payload.type === "CMRCSeethroughWithout") {
        const seethroughWithoutImgUrl = result[0]?.image[0]?.data?.uri;

        if (seethroughWithoutImgUrl) {
          yield put(saveSeethroughWithoutImage(seethroughWithoutImgUrl));
        }
      }

      if (action.payload.type === "CMRCOpticalTips") {
        const opticianTips = getOpticianTips(result);
        yield put(saveOpticianTips(opticianTips));
      }

      if (action.payload.type === "CMRCMoodBoard") {
        const moodboard = getMoodBoard(result);
        yield put(saveMoodboard(moodboard));
      }

      if (action.payload.type === "CMRCAdvContent") {
        const advContents = getAdvContents(result);
        yield put(saveAdvContents(advContents));
      }

      if (action.payload.type === "CMVideo") {
        yield put(saveVideoContents(result));
      }

      if (action.payload.type === "CMRCInstagramLink") {
        yield put(saveSeeOnInstagramLink(data?.data?.content));
      }
    }
    yield put(setLoadingPdp({ type: action.payload.type as LoadingPdp, value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPdp({ type: action.payload.type as LoadingPdp, value: "ERROR" }));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// KEYLOOK ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get Keylook products for PDP page
 *
 * @param {PayloadAction<KeylookProductsPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPDPKeylookProductsSaga(payload: KeylookProductsPayload): SagaIterator {
  try {
    yield put(
      savePDPKeylookProducts({
        type: "add",
        requestStatus: "LOADING",
      })
    );
    const { data } = yield call(catalogueService.getKeylookProducts, payload);
    let keylookProducts: Product[] = [];
    if (data.data.catalogEntryView) {
      keylookProducts = mapProductsArray(data.data.catalogEntryView);
    }

    yield put(
      savePDPKeylookProducts({
        type: "add",
        catalogue: keylookProducts,
        totalProducts: data.data?.recordSetTotal,
        requestStatus: "SUCCESS",
      })
    );

    if (keylookProducts.length > 0) {
      const priceIds: string[] = []; // get ids
      keylookProducts.forEach((product: Product) => {
        product.skuUniqueID && priceIds.push(product.skuUniqueID);
      });

      ////////////////////////////////////// get availabilty
      yield put(getProductAvailability({ ids: priceIds }));
      ////////////////////////////////////// get prices
      yield put(getPDPKeylookProductsPrices(keylookProducts));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(
      savePDPKeylookProducts({
        type: "add",
        requestStatus: "ERROR",
      })
    );
  }
}

/**
 * Get Keylook products prices for PDP page
 *
 * @param {PayloadAction<SimilarProductsPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPDPKeylookProductsPricesSaga(keylookProducts: Product[]): SagaIterator {
  try {
    ////////////////////////////////////// get prices

    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }

    if (hasPricePrivilege && keylookProducts.length > 0) {
      const priceIds: string[] = []; // get ids
      keylookProducts.forEach((product: Product) => {
        product.uniqueID && priceIds.push(product.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data);
      const newKeylookProducts = cloneDeep(keylookProducts);

      newKeylookProducts.map((product: Product) => {
        return addPriceToProductorVariant(product, priceData) as Product;
      });

      yield put(
        savePDPKeylookProducts({
          type: "prices",
          prices: priceData,
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get Keylook contents from cms
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */

function* getPDPCMChannelKeylookCmsSaga(
  action: PayloadAction<PDPCmsContents & { sport?: string }>
): SagaIterator {
  const { sport, ...payload } = action.payload;
  try {
    yield put(setLoadingPdp({ type: "CMChannelKeylook", value: "SUCCESS" }));
    if (sport && payload.brand) {
      payload.additionalTags = [...(payload.additionalTags ?? []), sport];
      const { data } = yield call(catalogueService.getPdpCmsContents, payload);
      const result = data?.data?.content?.result[0];
      const mappedData = mapKeylookResponse(result);
      yield put(saveKeylookContents({ brand: payload.brand, sport: sport, keylook: mappedData }));
    }
    yield put(setLoadingPdp({ type: "CMChannelKeylook", value: "SUCCESS" }));
  } catch (error) {
    console.error(error);
    yield put(handleError(error));
    yield put(setLoadingPdp({ type: "CMChannelKeylook", value: "ERROR" }));
  }
}

/**
 * Get Sport contents from cms
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */

function* getPDPCMChannelSportCmsSaga(
  action: PayloadAction<PDPCmsContents & { sport?: string }>
): SagaIterator {
  const { sport, ...payload } = action.payload;
  try {
    yield put(setLoadingPdp({ type: "CMChannelSport", value: "SUCCESS" }));
    if (sport && payload.brand) {
      payload.additionalTags = [...(payload.additionalTags ?? []), sport];
      const { data } = yield call(catalogueService.getPdpCmsContents, payload);
      const result = data?.data?.content?.result[0];
      const mappedData = mapKeylookResponse(result);
      yield put(saveSportContents({ brand: payload.brand, sport: sport, keylook: mappedData }));
    }
    yield put(setLoadingPdp({ type: "CMChannelSport", value: "SUCCESS" }));
  } catch (error) {
    console.error(error);
    yield put(handleError(error));
    yield put(setLoadingPdp({ type: "CMChannelSport", value: "ERROR" }));
  }
}

/**
 * Get PDP tech icons from cms type CMChannel
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */

function* getPDPTechIconsCmsSaga(action: PayloadAction<PDPCmsContents>): SagaIterator {
  try {
    yield put(setLoadingPdp({ type: "CMChannelTechIcons", value: "SUCCESS" }));
    const { data } = yield call(catalogueService.getPdpCmsContents, action.payload);
    if (action.payload.additionalTags && data?.data?.content?.result.length > 0) {
      const techIconMapped = mapTechIconsResponse(data.data.content.result[0]);
      if (techIconMapped["ksp-sustainability"]?.length > 0)
        yield put(
          addTechIconContents({
            brand: action.payload.additionalTags[1],
            type: "ksp-sustainability",
            icons: techIconMapped["ksp-sustainability"],
          })
        );
      if (techIconMapped["ksp-technology"]?.length > 0)
        yield put(
          addTechIconContents({
            brand: action.payload.additionalTags[1],
            type: "ksp-technology",
            icons: techIconMapped["ksp-technology"],
          })
        );
      if (techIconMapped.tech?.length > 0)
        yield put(
          addTechIconContents({
            brand: action.payload.additionalTags[1],
            type: "tech",
            icons: techIconMapped.tech,
          })
        );
    }
    yield put(setLoadingPdp({ type: "CMChannelTechIcons", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPdp({ type: "CMChannelTechIcons", value: "ERROR" }));
  }
}

/**
 * Get images for main PDP carousel
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getPDPImagesCarouselSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(saveImagesCarouselPdpStatus("LOADING"));
    const payload: GetAttachmentsVariantPayload = {
      variantId: action.payload,
      type: "PHOTO",
      attachments: "attachments",
    };
    const { data } = yield call(catalogueService.getAttachmentsVariant, payload);
    const imagesCarousel = mapAttachments(data?.data?.catalogEntryView?.[0]?.attachments);
    if (imagesCarousel) yield put(saveImagesCarouselPdp(imagesCarousel));
    else yield put(saveImagesCarouselPdp([]));
    yield put(saveImagesCarouselPdpStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveImagesCarouselPdp([]));
    yield put(saveImagesCarouselPdpStatus("ERROR"));
  }
}

/**
 * Get images for 360 view in PDP
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getPDPView360Saga(action: PayloadAction<string>): SagaIterator {
  try {
    const payload: GetAttachmentsVariantPayload = {
      variantId: action.payload,
      type: "PHOTO_360",
      attachments: "attachments",
    };
    const { data } = yield call(catalogueService.getAttachmentsVariant, payload);
    const imagesCarousel = mapAttachments(data?.data?.catalogEntryView?.[0]?.attachments);

    yield put(saveView360Pdp(imagesCarousel));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getAllPDPImagesSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(saveImagesCarouselPdpStatus("LOADING"));
    const payload: GetAttachmentsVariantPayload = {
      variantId: action.payload,
      type: ["PHOTO", "PHOTO_360"],
      attachments: "multiAttachments",
    };
    const { data } = yield call(catalogueService.getAttachmentsVariant, payload);
    const images = mapAttachments(data?.data?.catalogEntryView?.[0]?.attachments);
    const imagesCarousel360 = images.filter((img) => img.usage === "PHOTO_360");
    const imagesCarousel = images.filter((img) => img.usage === "PHOTO");
    if (imagesCarousel) yield put(saveImagesCarouselPdp(imagesCarousel));
    else yield put(saveImagesCarouselPdp([]));
    if (imagesCarousel360) yield put(saveView360Pdp(imagesCarousel360));
    else yield put(saveView360Pdp([]));
    yield put(saveImagesCarouselPdpStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveImagesCarouselPdp([]));
    yield put(saveView360Pdp([]));
    yield put(saveImagesCarouselPdpStatus("ERROR"));
  }
}

function* getQRCodeSaga(action: PayloadAction<QRPayload>): SagaIterator {
  try {
    const { data } = yield call(catalogueService.getQRCode, action.payload);
    yield put(saveQRCode(data.hash));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getVMMVTransitionsSaga(): SagaIterator {
  try {
    const VTOTransitionLens = yield select(selectVTOTransitionLens);
    if (!VTOTransitionLens) {
      const { data } = yield call(catalogueService.getVMMVTransitions);
      yield put(saveVTOTransitionLens(data?.transitionLens?.[0]));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// COMMON SAGAS //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get variants for single product in expanded tile popup
 * The variants are paginated.
 *
 * @param {PayloadAction<PlpVariantsOptions>} action
 * @return {*}  {SagaIterator}
 */
function* getPlpVariantsSaga(action: PayloadAction<PlpVariantsOptions>): SagaIterator {
  try {
    ////////////////////////////////////// get PLP variants
    yield put(setExpandedTileRequestStatus("LOADING"));

    const qparams = action.payload;
    const aftersalesType = getAftersalesType();
    if (aftersalesType) qparams.aftersalesType = aftersalesType;

    const { data } = yield call(catalogueService.getPlpVariants, qparams);

    const variants = mapVariantsArray(data?.data?.catalogEntryView);

    const variantsResults = {
      items: variants,
      recordSetCount: data?.data?.recordSetCount,
      recordSetTotal: data?.data?.recordSetTotal,
    };
    const productCategory = variantsResults?.items?.[0]?.productCategory;

    if (action.payload.mode !== "append") {
      // save results
      if (variants) yield put(savePlpVariants(variantsResults));
      else yield put(setExpandedTileRequestStatus("ERROR"));
    } else {
      if (variants) yield put(appendPlpVariants(variantsResults));
    }

    ////////////////////////////////////// get availability
    if (action.payload.catalogType !== "aftersales") {
      try {
        const skuIds: string[] = []; // store all variants' skus' ids that appear in plpVariants

        variantsResults.items.forEach((variant) => {
          const variantSkuIds = variant.skus
            ? variant.skus?.map((_) => {
                return _.uniqueID;
              })
            : [];
          skuIds.push(...variantSkuIds);
        });

        if (productCategory && !checkIsAFAOrHelmet(productCategory))
          yield put(getProductAvailability({ ids: skuIds }));

        const isStarsMultidoor = yield select(selectIsStarsMultidoor);
        const selectedDoor: Door = yield select(selectSelectedDoor);
        const doorsWithPrivilege: Door[] = yield select(selectDoorsForMultidoorAddSize);

        const defaultDoor: Door = isStarsMultidoor ? selectedDoor : doorsWithPrivilege?.[0];

        const PDPVariantDoor = variantsResults.items.map((variant) => {
          return { door: defaultDoor, uniqueID: variant.uniqueID };
        });

        yield put(
          setPDPVariantInfo({
            PDPVariantDoor,
            fallbackDoor: defaultDoor,
          })
        );
      } catch (error) {
        yield put(handleError(error));
      }
    }
    const productIds: string[] = []; // get ids
    variants.forEach((variant: Variant) => {
      const variantSkus = variant.skus
        ? variant.skus?.map((_) => {
            return _.uniqueID;
          })
        : [];
      productIds.push(...variantSkus);
    });

    //GET AVAIABILITY FOR AFA OR HELMET
    if (productCategory && checkIsAFAOrHelmet(productCategory)) {
      yield put(getProductAvailability({ ids: productIds }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setExpandedTileRequestStatus("ERROR"));
  }
}

/**
 * Get variants for single product in expanded tile popup
 * The variants are paginated.
 *
 * @param {PayloadAction<PlpVariantsOptions>} action
 * @return {*}  {SagaIterator}
 */
function* getPlpRecommendedVariantsSaga(
  action: PayloadAction<PlpRecommendedPayload>
): SagaIterator {
  try {
    ////////////////////////////////////// get PLP variants
    yield put(setExpandedTileRequestStatus("LOADING"));
    const payload: getPlpRecommendedOptions = {
      pageNumber: action.payload.pageNumber.toString(),
      productPerPage: action.payload.pageSize.toString(),
      params: getFiltersFromParams(action.payload.searchTerm) ?? {},
    };
    const { data } = yield call(catalogueService.getPlpRecommended, payload);

    const variants = mapVariantsArray(data?.data?.catalogEntryView);

    const variantsResults = {
      items: variants,
      recordSetCount: data?.data?.recordSetCount,
      recordSetTotal: data?.data?.recordSetTotal,
    };
    const productCategory = variantsResults?.items?.[0]?.productCategory;

    // save results
    if (variants) yield put(savePlpVariants(variantsResults));
    else yield put(setExpandedTileRequestStatus("ERROR"));

    const productIds: string[] = []; // get ids
    variants.forEach((variant: Variant) => {
      variant.uniqueID && productIds.push(variant.uniqueID);
    });

    try {
      const skuIds: string[] = []; // store all variants' skus' ids that appear in plpVariants

      variantsResults.items.forEach((variant) => {
        const variantSkuIds = variant.skus
          ? variant.skus?.map((_) => {
              return _.uniqueID;
            })
          : [];
        skuIds.push(...variantSkuIds);
      });

      if (productCategory && !checkIsAFAOrHelmet(productCategory))
        yield put(getProductAvailability({ ids: skuIds }));
    } catch (error) {
      yield put(handleError(error));
    }

    ////////////////////////////////////// get prices
    try {
      const { data: price } = yield call(
        catalogueService.getPriceService,
        productIds,
        action.payload.doorId
      );
      const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array
      const newResults = addPricesToPlpVariants(variantsResults, priceData); // add prices to current search results

      if (newResults) {
        // save updated results
        yield put(savePlpVariants(newResults));
      }
    } catch (error) {
      yield put(handleError(error));
    }

    if (productCategory && checkIsAFAOrHelmet(productCategory)) {
      yield put(getProductAvailability({ ids: productIds }));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setExpandedTileRequestStatus("ERROR"));
  }
}

/**
 * Get inventory and availability info for each sku in the page
 *
 * @param  {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getProductAvailabilitySaga({
  payload,
}: PayloadAction<GetProductAvailabilityPayload>): SagaIterator {
  try {
    yield put(setLoadingAvailability(true));
    const idsSplit = splitArrayInChunks(payload.ids, payload.numIds ? 25 : 150);

    const pendingQueries = [];

    for (let i = 0; i < idsSplit.length; i++) {
      pendingQueries.push(
        yield fork(getProductAvailabilitySplitSaga, {
          ids: idsSplit?.[i],
        })
      );
    }

    yield join(pendingQueries);
    yield put(setLoadingAvailability(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingAvailability(false));
  }
}

export function* getProductAvailabilitySplitSaga(
  payload: GetProductAvailabilityPayload
): SagaIterator {
  try {
    if (payload.ids.length !== 0) {
      const { data } = yield call(catalogueService.getProductAvailability, payload.ids);
      let inventoryAvailability = mapAvailability(data?.data?.doorInventoryAvailability);

      const privileges: string[] = yield select(selectAtLeastOneTruePrivileges);

      // show backorderbells
      if (privileges.includes("GREEN_IS_BACK")) {
        const backorderProducts: string[] = [];
        inventoryAvailability.forEach((door) => {
          door.availabilityStatus.forEach((status: AvailabilityStatus) => {
            if (Object.values(status)?.[0] === "BACKORDER") {
              backorderProducts.push(Object.keys(status)?.[0]);
            }
          });
        });
        if (backorderProducts.length > 0) {
          try {
            const backorderRes = yield call(messagesService.areProductsTracked, backorderProducts);
            inventoryAvailability = inventoryAvailability.map((door) => ({
              ...door,
              availabilityStatus: door.availabilityStatus.map((status: AvailabilityStatus) => {
                const catentryId = Object.keys(status)[0];
                if (Object.values(status)[0] === "BACKORDER") {
                  const followedProduct: AvailableProduct = backorderRes?.data?.data?.products.find(
                    (product: AvailableProduct) => product.catentryId === catentryId
                  );
                  const backorderProduct = {
                    [catentryId]: followedProduct
                      ? "BACKORDER_FOLLOWING"
                      : "BACKORDER_NOT_FOLLOWING",
                  };
                  if (followedProduct) backorderProduct.trackingId = followedProduct.id;
                  return backorderProduct;
                }
                return status;
              }),
            }));
          } catch (error) {
            yield put(handleError(error));
          }
        }
      }
      yield put(saveProductAvailability(inventoryAvailability));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get bestsellers
 *
 * @param {(PayloadAction<number | undefined>)} action
 * @return {*}  {SagaIterator}
 */
function* getBestSellersSaga(payload: number | undefined): SagaIterator {
  try {
    yield put(setBestSellerRequestStatus("LOADING"));

    /////////////////////// get menu (used to get unique id as params)
    let menu = yield select(selectMenu);

    if (menu.length === 0) {
      const menuAction = yield take(saveMenu.type);
      menu = menuAction.payload;
    }

    /////////////////////// params for api call
    const params = {
      searchTerm: "*",
      pageSize: 3,
      pageNumber: payload ?? 1,
      categoryId: getUniqueIdFrames(menu),
      facet: ["BESTSELLER:TRUE"],
      orderBy: "BESTSELLER",
    };

    ///////////////////// make api call
    const { data } = yield call(catalogueService.getBestSellers, params);

    if (data?.data?.catalogEntryView && data.data.catalogEntryView.length > 0) {
      const bestsellersCatalogue = mapBestsellersCatalogue(data.data);

      yield put(setBestSeller({ type: "add", catalogue: bestsellersCatalogue }));
      yield put(setBestSellerRequestStatus("SUCCESS"));

      ////////////////////////////////////// get prices
      yield put(getBestSellersPrices(bestsellersCatalogue));

      //GET AVAIABILITY FOR AFA OR HELMET
      const productIds: string[] = []; // get ids
      bestsellersCatalogue.resultList.forEach((result: Product) => {
        result.uniqueID && productIds.push(result.uniqueID);
      });

      if (bestsellersCatalogue.resultList?.length > 0) {
        const productCategory = bestsellersCatalogue.resultList[0]?.productCategory;

        if (productCategory && checkIsAFAOrHelmet(productCategory)) {
          yield put(getProductAvailability({ ids: productIds }));
        }
      }
    } else {
      yield put(setBestSellerRequestStatus("ERROR"));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setBestSellerRequestStatus("ERROR"));
  }
}

function* getBestSellersPricesSaga(bestsellersCatalogue: BestsellersCatalogue): SagaIterator {
  try {
    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }

    if (hasPricePrivilege) {
      const priceIds: string[] = []; // get ids
      bestsellersCatalogue.resultList.forEach((result: Product) => {
        !result.price && result.uniqueID && priceIds.push(result.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

      if (priceData.length > 0) yield put(setBestSeller({ type: "prices", prices: priceData }));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getVariantsExpandedTileSaga(
  action: PayloadAction<GetVariantsExpandedTilePayload>
): SagaIterator {
  try {
    const {
      pageNumber,
      pageSize,
      partNumber,
      filters,
      catalogType,
      searchTerm,
      alternativeProduct,
      doorId,
      mode,
      onlyAvailableProducts,
    } = action.payload;

    const defaultPageSize = yield select(selectExpandedTilePageSize);

    if (alternativeProduct) {
      yield put(
        getAlternativeVariant({
          pageNumber: String(pageNumber ?? 1),
          pageSize: String(pageSize ?? defaultPageSize),
          partNumber,
          productId: alternativeProduct,
          filters,
          catalogType,
          doorId,
          mode,
        })
      );
    } else {
      yield put(
        getPlpVariants({
          pageNumber,
          pageSize,
          partNumber,
          filters,
          searchTerm,
          catalogType, // important that it's a param, b/c it's used in PLP Aftersales
          doorId,
          mode,
          onlyAvailableProducts,
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////// ALTERNATIVE PRODUCT ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* resolveAlternativeProductSlugSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "slug", value: "LOADING" }));
    const { data } = yield call(catalogueService.resolveSlug, action.payload);

    const productId = data?.data?.contents?.[0]?.tokenValue;
    if (productId) yield put(saveAlternativeProductId(productId));

    yield put(setLoadingPlp({ type: "slug", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPlp({ type: "slug", value: "ERROR" }));
    yield put(saveAlternativeProductId(null));
  }
}

/**
 * Get alternative models
 *
 * @param {PayloadAction<GetAlternativeModelPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getAlternativeProductSaga(
  action: PayloadAction<GetAlternativeModelPayload>
): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingPlp({ type: "facets", value: "LOADING" }));

    yield put(setLoadingAnalyticsData(true));

    const productPerPage = yield select(selectProductPerPage); // get number of products to display

    ////////////////////////////////////// get PLP items

    const { data } = yield call(warrantyWizardService.getWarrantyAlternativeModel, {
      ...action.payload,
      pageSize: productPerPage.toString(),
    });

    yield put(savePlpCatalogue(null));

    const plpCatalogue: PlpCatalogueNoAdv | undefined =
      data?.data && mapPlpCatalogueNoAdv(data.data);

    // if present, save results (and stop loading)
    if (plpCatalogue) {
      yield put(savePlpCatalogue(plpCatalogue as PlpCatalogue));
      yield put(setLoadingPlp({ type: "catalogue", value: "SUCCESS" }));

      yield put(savePlpFacetView(plpCatalogue?.facetView ?? null));
      yield put(
        setLoadingPlp({ type: "facets", value: plpCatalogue?.facetView ? "SUCCESS" : "ERROR" })
      );

      ////////////////////////////////////// get prices
      try {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege) {
          const priceIds: string[] = []; // get ids
          plpCatalogue?.resultList?.forEach((result: any) => {
            result?.uniqueID && priceIds.push(result.uniqueID);
          });

          if (priceIds.length > 0) {
            const { data: price } = yield call(catalogueService.getPriceService, priceIds);
            const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

            const plpCatalogueWithPrices = addPricesToPlpCatalogue(plpCatalogue, priceData); // add prices to current catalogue
            yield put(savePlpCatalogue(plpCatalogueWithPrices)); // save updated catalogue
          }
        }

        ///////////////////////////////////// get Instagram badges
        const parNumbers: string[] = [];
        plpCatalogue?.resultList?.forEach((_: any) => {
          _?.productCode && parNumbers.push(_.productCode);
        });
        yield put(getPLPInstagramBadges({ partNumbers: parNumbers }));
      } catch (error) {
        yield put(handleError(error));
      }
    } else {
      yield put(savePlpCatalogue(null)); // save empty catalogue
      yield put(savePlpFacetView(null));
      yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
      yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
    }

    yield put(setLoadingAnalyticsData(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpCatalogue(null)); // save empty catalogue
    yield put(savePlpFacetView(null));
    yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
  }
}

/**
 * Get alternative variants for single product in expanded tile popup
 * The variants are paginated.
 *
 * @param {PayloadAction<GetAlternativeVariantServicePayload>} action
 * @return {*}  {SagaIterator}
 */
function* getAlternativeVariantSaga(
  action: PayloadAction<GetAlternativeVariantServicePayload>
): SagaIterator {
  try {
    ////////////////////////////////////// get PLP variants
    yield put(setExpandedTileRequestStatus("LOADING"));
    const { data } = yield call(
      warrantyWizardService.getWarrantyAlternativeVariant,
      action.payload
    );

    const variants = mapVariantsArray(data?.data?.catalogEntryView);

    const variantsResults = {
      items: variants,
      recordSetCount: data?.data?.recordSetCount,
      recordSetTotal: data?.data?.recordSetTotal,
    };

    if (action.payload.mode !== "append") {
      // save results
      if (variants) yield put(savePlpVariants(variantsResults));
      else yield put(setExpandedTileRequestStatus("ERROR"));
    }

    ////////////////////////////////////// get availability
    const isMultidoor = yield select(selectIsMultidoor);
    if (!isMultidoor) {
      const skuIds: string[] = []; // store all variants' skus' ids that appear in plpVariants

      variantsResults.items.forEach((variant) => {
        const variantSkuIds = variant.skus
          ? variant.skus?.map((_) => {
              return _.uniqueID;
            })
          : [];
        skuIds.push(...variantSkuIds);
      });

      yield put(getProductAvailability({ ids: skuIds }));
    }

    ////////////////////////////////////// get prices
    try {
      const priceIds: string[] = []; // get ids
      variants.forEach((variant: Variant) => {
        variant.uniqueID && priceIds.push(variant.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array
      const newResults = addPricesToPlpVariants(variantsResults, priceData); // add prices to current search results

      if (newResults) {
        // save updated results
        if (action.payload.mode === "append") yield put(appendPlpVariants(newResults));
        else yield put(savePlpVariants(newResults));
      } else {
        if (action.payload.mode === "append") yield put(appendPlpVariants(variantsResults));
      }
    } catch (error) {
      yield put(handleError(error));
      if (action.payload.mode === "append") yield put(appendPlpVariants(variantsResults));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setExpandedTileRequestStatus("ERROR"));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// SIMILAR PRODUCT ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
/**
 * Get Similar Products for PDPor
 *
 * @param {PayloadAction<SimilarProductsPayload>} action
 * @return {*}  {SagaIterator}
 */

function* getPDPSimilarProductsSaga(payload: SimilarProductsPayload): SagaIterator {
  try {
    yield put(
      savePDPSimilarProducts({
        type: "add",
        requestStatus: "LOADING",
      })
    );
    const { data } = yield call(catalogueService.getSimilarProducts, payload);

    let similarProducts: Product[] = [];
    if (data.data.catalogEntryView) {
      similarProducts = mapProductsArray(data.data.catalogEntryView);
    }

    yield put(
      savePDPSimilarProducts({
        type: "add",
        catalogue: similarProducts,
        totalProducts: data.data?.recordSetTotal,
        requestStatus: "SUCCESS",
      })
    );

    ////////////////////////////////////// get availabilty

    if (similarProducts.length > 0) {
      const priceIds: string[] = []; // get ids
      similarProducts.forEach((product: Product) => {
        product.skuUniqueID && priceIds.push(product.skuUniqueID);
      });
      yield put(getProductAvailability({ ids: priceIds }));
      yield put(getPDPSimilarProductsPrices(similarProducts));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(
      savePDPSimilarProducts({
        type: "add",
        requestStatus: "ERROR",
      })
    );
  }
}

function* getPDPSimilarProductsPricesSaga(similarProducts: Product[]): SagaIterator {
  ////////////////////////////////////// get prices
  try {
    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }

    if (hasPricePrivilege && similarProducts.length > 0) {
      const priceIds: string[] = []; // get ids
      similarProducts.forEach((product: Product) => {
        product.uniqueID && priceIds.push(product.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data);
      const newSimilarProducts = cloneDeep(similarProducts);

      newSimilarProducts.map((product: Product) => {
        return addPriceToProductorVariant(product, priceData) as Product;
      });

      yield put(
        savePDPSimilarProducts({
          type: "prices",
          prices: priceData,
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get Similar products for PLP page
 *
 * @param {PayloadAction<SimilarProductsPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPLPSimilarProductsSaga(action: PayloadAction<SimilarProductsPayload>): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingPlp({ type: "facets", value: "LOADING" }));

    yield put(setLoadingAnalyticsData(true));

    const productPerPage = yield select(selectProductPerPage); // get number of products to display

    ////////////////////////////////////// get PLP items

    const { data } = yield call(catalogueService.getSimilarProducts, {
      ...action.payload,
      pageSize: productPerPage,
    });

    yield put(savePlpCatalogue(null));

    const plpCatalogue: PlpCatalogueNoAdv | undefined =
      data?.data && mapPlpCatalogueNoAdv(data.data);

    // if present, save results (and stop loading)
    if (plpCatalogue) {
      yield put(savePlpCatalogue(plpCatalogue as PlpCatalogue));
      yield put(setLoadingPlp({ type: "catalogue", value: "SUCCESS" }));

      yield put(savePlpFacetView(plpCatalogue?.facetView ?? null));
      yield put(
        setLoadingPlp({ type: "facets", value: plpCatalogue?.facetView ? "SUCCESS" : "ERROR" })
      );

      const productIds: string[] = []; // get ids
      plpCatalogue.resultList.forEach((result: any) => {
        result.uniqueID && productIds.push(result.uniqueID);
      });

      ////////////////////////////////////// get prices

      try {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege && productIds.length > 0) {
          const { data: price } = yield call(
            catalogueService.getPriceService,
            productIds,
            action.payload.doorId
          );
          const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

          const plpCatalogueWithPrices = addPricesToPlpCatalogue(plpCatalogue, priceData); // add prices to current catalogue
          yield put(savePlpCatalogue(plpCatalogueWithPrices)); // save updated catalogue
        }
      } catch (error) {
        yield put(handleError(error));
      }

      ///////////////////////////////////// get Instagram badges
      const parNumbers: string[] = [];
      plpCatalogue?.resultList?.forEach((_: any) => {
        _?.productCode && parNumbers.push(_.productCode);
      });
      yield put(getPLPInstagramBadges({ partNumbers: parNumbers }));

      //GET AVAIABILITY FOR AFA OR HELMET
      // if (plpCatalogue?.resultList?.length > 0) {
      //   const productCategory = getProductCategory(plpCatalogue?.resultList);

      //   if (productCategory && checkIsAFAOrHelmet(productCategory)) {
      //     yield put(getProductAvailability({ ids: productIds }));
      //   }
      // }
    } else {
      yield put(savePlpCatalogue(null)); // save empty catalogue
      yield put(savePlpFacetView(null));
      yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
      yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
    }

    yield put(setLoadingAnalyticsData(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpCatalogue(null)); // save empty catalogue
    yield put(savePlpFacetView(null));
    yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
  }
}

/**
 * Resolve slug for similar products plp
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* resolveSimilarProductSlugSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "slug", value: "LOADING" }));
    const { data } = yield call(catalogueService.resolveSlug, action.payload);

    const productId = data?.data?.contents?.[0]?.tokenValue;
    if (productId) yield put(saveSimilarProductId(productId));

    yield put(setLoadingPlp({ type: "slug", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPlp({ type: "slug", value: "ERROR" }));
    yield put(saveSimilarProductId(null));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////// PRODUCTS COMPATIBLE WITH ACCESSORIES  ///////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Resolve slug for compatible with PLP
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* resolveProductsCompatibleSlugSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "slug", value: "LOADING" }));
    const { data } = yield call(catalogueService.resolveSlug, action.payload);

    const productId = data?.data?.contents?.[0]?.tokenValue;
    if (productId) yield put(saveProductsCompatibleProductId(productId));

    yield put(setLoadingPlp({ type: "slug", value: "SUCCESS" }));
  } catch (error) {
    yield put(handleError(error));
    yield put(setLoadingPlp({ type: "slug", value: "ERROR" }));
    yield put(saveProductsCompatibleProductId(null));
  }
}

/**
 * Get products for compatible with PLP
 *
 * @param {PayloadAction<ProductsCompatiblePayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPLPProductsCompatibleSaga(
  action: PayloadAction<ProductsCompatiblePayload>
): SagaIterator {
  try {
    yield put(setLoadingPlp({ type: "catalogue", value: "LOADING" }));
    yield put(setLoadingPlp({ type: "facets", value: "LOADING" }));

    yield put(setLoadingAnalyticsData(true));

    const productPerPage = yield select(selectProductPerPage); // get number of products to display

    ////////////////////////////////////// get PLP items

    const { data } = yield call(catalogueService.getProductsCompatibleWithAccessories, {
      ...action.payload,
      pageSize: productPerPage,
    });

    yield put(savePlpCatalogue(null));

    const plpCatalogue: PlpCatalogueNoAdv | undefined =
      data?.data && mapPlpCatalogueNoAdv(data.data);

    // if present, save results (and stop loading)
    if (plpCatalogue) {
      yield put(savePlpCatalogue(plpCatalogue as PlpCatalogue));
      yield put(setLoadingPlp({ type: "catalogue", value: "SUCCESS" }));

      yield put(savePlpFacetView(plpCatalogue?.facetView ?? null));
      yield put(
        setLoadingPlp({ type: "facets", value: plpCatalogue?.facetView ? "SUCCESS" : "ERROR" })
      );

      const productIds: string[] = []; // get ids
      plpCatalogue.resultList.forEach((result: any) => {
        result.uniqueID && productIds.push(result.uniqueID);
      });

      ////////////////////////////////////// get prices
      try {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege && productIds.length > 0) {
          const { data: price } = yield call(
            catalogueService.getPriceService,
            productIds,
            action.payload.doorId
          );
          const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

          const plpCatalogueWithPrices = addPricesToPlpCatalogue(plpCatalogue, priceData); // add prices to current catalogue
          yield put(savePlpCatalogue(plpCatalogueWithPrices)); // save updated catalogue
        }
      } catch (error) {
        yield put(handleError(error));
      }

      ///////////////////////////////////// get Instagram badges
      const parNumbers: string[] = [];
      plpCatalogue?.resultList?.forEach((_: any) => {
        _?.productCode && parNumbers.push(_.productCode);
      });
      yield put(getPLPInstagramBadges({ partNumbers: parNumbers }));

      //GET AVAIABILITY FOR AFA OR HELMET
      // if (plpCatalogue?.resultList?.length > 0) {
      //   const productCategory = getProductCategory(plpCatalogue?.resultList);

      //   if (productCategory && checkIsAFAOrHelmet(productCategory)) {
      //     yield put(getProductAvailability({ ids: productIds }));
      //   }
      // }
    } else {
      yield put(savePlpCatalogue(null)); // save empty catalogue
      yield put(savePlpFacetView(null));
      yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
      yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
    }

    yield put(setLoadingAnalyticsData(false));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePlpCatalogue(null)); // save empty catalogue
    yield put(savePlpFacetView(null));
    yield put(setLoadingPlp({ type: "catalogue", value: "ERROR" }));
    yield put(setLoadingPlp({ type: "facets", value: "ERROR" }));
  }
}

/**
 * Get product compatible with for PDP
 *
 * @param {PayloadAction<ProductsCompatiblePayload>} action
 * @return {*}  {SagaIterator}
 */
function* getPDPProductsCompatibleSaga(payload: ProductsCompatiblePayload): SagaIterator {
  try {
    // yield put(setPDPProductsCompatibleLoading(true));
    yield put(
      savePDPProductsCompatible({
        type: "add",
        requestStatus: "LOADING",
      })
    );

    const { data } = yield call(catalogueService.getProductsCompatibleWithAccessories, payload);

    let compatibleProducts: Product[] = [];
    if (data.data?.catalogEntryView) {
      compatibleProducts = mapProductsArray(data.data.catalogEntryView);
    }

    yield put(
      savePDPProductsCompatible({
        type: "add",
        catalogue: compatibleProducts,
        totalProducts: data?.data?.recordSetTotal,
        requestStatus: "SUCCESS",
      })
    );

    if (compatibleProducts.length > 0)
      yield put(getPDPProductsCompatiblePrices(compatibleProducts));
  } catch (error) {
    yield put(handleError(error));
    yield put(
      savePDPProductsCompatible({
        type: "add",
        requestStatus: "ERROR",
      })
    );
  }
}
/**
 * Get LP Variants for a product, passing the product id
 *
 *
 * @param {PayloadAction<GetProductWithVariantsPayload>} action productId
 * @return {*} {SagaIterator}
 */
function* getLPVariantsListSaga(
  action: PayloadAction<GetLandingPageVariantsPayload>
): SagaIterator {
  try {
    yield put(setLpVariantsListStatus("LOADING"));
    const { data } = yield call(catalogueService.getLPVariants, action.payload);
    const variants = mapVariantsArray(data.data?.catalogEntryView);
    const variantsResults = {
      items: variants,
      recordSetTotal: data?.data?.recordSetTotal,
    };

    if (variantsResults.items) {
      yield put(saveLpVariantsList(variantsResults));
      yield put(setLpVariantsListStatus("SUCCESS"));
    } else {
      yield put(setLpVariantsListStatus("ERROR"));
    }

    ////////////////////////////////////// get prices
    try {
      const isMultidoor = yield select(selectIsMultidoor);
      let hasPricePrivilege = false;
      if (isMultidoor) {
        hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
      } else {
        hasPricePrivilege = yield select(selectHasPricePrivilege);
      }

      if (hasPricePrivilege) {
        const pricesIds: string[] = []; //get prices ids
        variants.forEach((variant: Variant) => {
          variant.uniqueID && pricesIds.push(variant.uniqueID);
        });

        const { data: price } = yield call(catalogueService.getPriceService, pricesIds);
        const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array
        if (priceData.length > 0) {
          const variantsListCopy = cloneDeep(variantsResults.items);
          variantsListCopy.map((variant: Variant) => {
            return addPriceToProductorVariant(variant, priceData);
          });
          yield put(
            saveLpVariantsList({
              items: variantsListCopy,
              recordSetTotal: variantsResults.recordSetTotal,
            })
          );
        }
      }
    } catch (error) {
      yield put(handleError(error));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(setLpVariantsListStatus("ERROR"));
  }
}

///////////////////////////////////////////////////////////////////////////////////
//////////////////////// PRODUCTS RECOMMENDATION /////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

/**
 * Get product recommendation in cart
 *
 * @param {PayloadAction<ProductsCompatiblePayload>} action
 * @return {*}  {SagaIterator}
 */
function* getProductRecommendationSaga(payload: number | undefined): SagaIterator {
  try {
    yield put(saveProductRecommendationStatus("LOADING"));

    ///////////////////// make api call
    const { data } = yield call(catalogueService.getProductRecommendation, {
      pageNumber: payload ?? 1,
      pageSize: 12, // NOTE that this effectively renders the pagination redudant.
    });

    const variants = mapVariantsArray(data?.data?.catalogEntryView);

    const catalogue: ProductRecommendationCatalogue = {
      catalogEntryView: variants,
      recordSetCount: data?.data?.recordSetCount,
      recordSetStartNumber: data?.data?.recordSetStartNumber,
      recordSetTotal: data?.data?.recordSetTotal,
    };

    yield put(
      saveProductRecommendation({
        type: "add",
        catalogue,
      })
    );

    yield put(saveProductRecommendationStatus("SUCCESS"));

    ////////////////////////////////////// get prices
    yield put(getProductRecommendationPrices(catalogue));

    ////////////////////////////////////// get availability

    const skuIds: string[] = []; // store all variants' skus' ids that appear in plpVariants

    variants.forEach((variant) => {
      const variantSkuIds = variant.skus
        ? variant.skus?.map((_) => {
            return _.uniqueID;
          })
        : [];
      skuIds.push(...variantSkuIds);
    });

    yield put(getProductAvailability({ ids: skuIds }));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveProductRecommendationStatus("ERROR"));
  }
}

function* getProductRecommendationPricesSaga(
  catalogue: ProductRecommendationCatalogue
): SagaIterator {
  try {
    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }

    if (hasPricePrivilege) {
      const priceIds: string[] = []; // get ids

      catalogue?.catalogEntryView?.forEach((result: Variant) => {
        !result.price && result.uniqueID && priceIds.push(result.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array

      yield put(saveProductRecommendation({ type: "prices", prices: priceData }));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getPDPProductsCompatiblePricesSaga(compatibleProducts: Product[]): SagaIterator {
  ////////////////////////////////////// get prices
  try {
    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }

    if (hasPricePrivilege && compatibleProducts.length > 0) {
      const priceIds: string[] = []; // get ids
      compatibleProducts.forEach((product: Product) => {
        product.uniqueID && priceIds.push(product.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data);

      yield put(
        savePDPProductsCompatible({
          type: "prices",
          prices: priceData,
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getPLPInstagramBadgesSaga(action: PayloadAction<InstagramBadgesPayload>): SagaIterator {
  let stringOfIds = "";
  action.payload.partNumbers.forEach((id: string) => (stringOfIds += id + ","));
  stringOfIds = stringOfIds.substring(0, stringOfIds.length - 1);
  try {
    const getPDPPayload: PDPCmsContents = { type: "CMRCInstagramLink", model: stringOfIds };
    const data = yield call(catalogueService.getPdpCmsContents, getPDPPayload);
    const instagramBadges: ProductInstagramBadge[] = [];
    data?.data?.data?.content?.result?.forEach((_: any) => {
      instagramBadges.push({ productId: _?.model?.[0]?.externalReference, hasBadge: true });
    });
    yield put(saveInstagramBadges(instagramBadges));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* catalogueSaga(): SagaIterator {
  /////////////////// PRE-PLP
  yield takeLatest(getPrePlpContents.type, getPrePlpContentsSaga);
  yield takeEvery(getPrePlpCatalogue.type, getPrePlpCatalogueSaga);
  yield takeEvery(getPrePLPStarsContents.type, getPrePLPStarsContentsSaga);

  /////////////////// PLP
  yield takeLatest(resolvePlpSlug.type, resolvePlpSlugSaga);
  yield takeLatest(getPlpVariants.type, getPlpVariantsSaga);
  yield takeLatest(getPlpRecommendedVariants.type, getPlpRecommendedVariantsSaga);
  yield takeLatest(getPlpLayout.type, getPlpLayoutSaga);
  yield takeLatest(getPlpCatalogue.type, getPlpCatalogueSaga);
  yield takeLatest(getPlpRecommended.type, getPlpRecommendedSaga);
  yield takeLatest(getPlpStarsCatalogue.type, getPlpStarsCatalogueSaga);
  yield takeEvery(getPlpStarsStatistics.type, getPlpStarsStatisticsSaga);
  yield takeLatest(getPLPInstagramBadges.type, getPLPInstagramBadgesSaga);

  yield fork(genericChannelWatcher, {
    actionPattern: getPlpStarsRestOfCatalogue.type,
    saga: getPlpStarsRestOfCatalogueSaga,
    cancelActionPattern: cancelPlpStarsRestOfCatalogue,
    // log: true,
  });
  yield fork(genericChannelWatcher, {
    actionPattern: getPlpStarsRestOfCataloguePrices.type,
    saga: getPlpStarsRestOfCataloguePricesSaga,
    cancelActionPattern: cancelPlpStarsRestOfCatalogue,
    // log: true,
  });

  /////////////////// PDP
  yield takeLatest(resolvePdpSlug.type, resolvePdpSlugSaga);
  yield takeEvery(getVariantDetails.type, getVariantDetailsSaga);
  yield takeLatest(getVariantVideoAvailability.type, getVariantVideoAvailabilitySaga);
  yield takeEvery(getProductWithVariants.type, getProductWithVariantsSaga);
  yield takeEvery(getRTRItem.type, getRTRItemSaga);
  yield takeEvery(getPdpCmsContents.type, getPDPCmsContentsSaga);
  yield takeLatest(getPDPImagesCarousel.type, getPDPImagesCarouselSaga);
  yield takeLatest(getPDPView360.type, getPDPView360Saga);
  yield takeLatest(getAllPDPImages.type, getAllPDPImagesSaga);
  yield takeEvery(getQRCode.type, getQRCodeSaga);
  yield takeEvery(getVMMVTransitions.type, getVMMVTransitionsSaga);
  yield takeLatest(getPDPVariantsList.type, getPDPVariantsListSaga);
  yield takeEvery(setPDPVariantInfo.type, setPDPVariantInfoSaga);
  yield takeEvery(initializePDPVariantInfo.type, initializePDPVariantInfoSaga);
  // yield takeEvery(getPDPVariantInfoAllDoors.type, getPDPVariantInfoAllDoorsSaga);
  yield fork(genericChannelWatcher, {
    actionPattern: getPDPVariantInfoAllDoors.type,
    saga: getPDPVariantInfoAllDoorsSaga,
    cancelActionPattern: cancelPDPVariantInfoAllDoors,
    // log: true,
  });
  yield takeEveryUnlessSameKey<PDPCmsContents>({
    actionPattern: getPDPTechIconsCms.type,
    saga: getPDPTechIconsCmsSaga,
    getUniqueKey: (actionPayload: PDPCmsContents) => actionPayload?.brand ?? "",
  });

  /////////////////// COMMONS
  yield takeEvery(getProductAvailability.type, getProductAvailabilitySaga);
  yield takeLatest(getVariantsExpandedTile.type, getVariantsExpandedTileSaga);

  ///////////////// BESTSELLERS
  yield fork(genericChannelWatcher, {
    actionPattern: getBestSellers.type,
    saga: getBestSellersSaga,
    cancelActionPattern: cancelBestSellers.type,
  });
  yield fork(genericChannelWatcher, {
    actionPattern: getBestSellersPrices.type,
    saga: getBestSellersPricesSaga,
    cancelActionPattern: cancelBestSellers.type,
  });

  ///////////////// ALTERNATIVE PRODUCT
  yield takeLatest(resolveAlternativeProductSlug.type, resolveAlternativeProductSlugSaga);
  yield takeLatest(getAlternativeProduct.type, getAlternativeProductSaga);
  yield takeLatest(getAlternativeVariant.type, getAlternativeVariantSaga);

  ///////////////// KEYLOOK
  yield takeLatest(getPDPCMChannelKeylookCms.type, getPDPCMChannelKeylookCmsSaga);
  yield takeLatest(getPDPCMChannelSportCms.type, getPDPCMChannelSportCmsSaga);
  yield fork(genericChannelWatcher, {
    actionPattern: getPDPKeylookProducts.type,
    saga: getPDPKeylookProductsSaga,
    cancelActionPattern: cancelPDPKeylookProducts.type,
    // log: true,
  });

  yield fork(genericChannelWatcher, {
    actionPattern: getPDPKeylookProductsPrices.type,
    saga: getPDPKeylookProductsPricesSaga,
    cancelActionPattern: cancelPDPKeylookProducts.type,
    // log: true,
  });

  ///////////////// SIMILAR PRODUCT
  yield takeLatest(getPLPSimilarProducts.type, getPLPSimilarProductsSaga);
  yield takeLatest(resolveSimilarProductSlug.type, resolveSimilarProductSlugSaga);

  yield fork(genericChannelWatcher, {
    actionPattern: getPDPSimilarProducts.type,
    saga: getPDPSimilarProductsSaga,
    cancelActionPattern: cancelPDPSimilarProducts.type,
    // log: true,
  });
  yield fork(genericChannelWatcher, {
    actionPattern: getPDPSimilarProductsPrices.type,
    saga: getPDPSimilarProductsPricesSaga,
    cancelActionPattern: cancelPDPSimilarProducts.type,
    // log: true,
  });

  ///////////////// PRODUCTS COMPATIBLE WITH
  yield takeLatest(getPLPProductsCompatible.type, getPLPProductsCompatibleSaga);
  // yield takeLatest(getPDPProductsCompatible.type, getPDPProductsCompatibleSaga);
  yield takeLatest(resolveProductsCompatibleSlug.type, resolveProductsCompatibleSlugSaga);

  yield fork(genericChannelWatcher, {
    actionPattern: getPDPProductsCompatible.type,
    saga: getPDPProductsCompatibleSaga,
    cancelActionPattern: cancelPDPProductsCompatible.type,
    // log: true,
  });
  yield fork(genericChannelWatcher, {
    actionPattern: getPDPProductsCompatiblePrices.type,
    saga: getPDPProductsCompatiblePricesSaga,
    cancelActionPattern: cancelPDPProductsCompatible.type,
    // log: true,
  });

  //////////////// LANDING PAGE
  yield takeLatest(getLPVariantsList.type, getLPVariantsListSaga);

  ///////////////// PRODUCT RECOMMENDATION
  yield fork(genericChannelWatcher, {
    actionPattern: getProductRecommendation.type,
    saga: getProductRecommendationSaga,
    cancelActionPattern: cancelProductRecommendation.type,
  });
  yield fork(genericChannelWatcher, {
    actionPattern: getProductRecommendationPrices.type,
    saga: getProductRecommendationPricesSaga,
    cancelActionPattern: cancelProductRecommendation.type,
  });
}
