import { useDispatch } from "react-redux";
import {
  OrderConfirmationHeaderData,
  OrderConfirmationLensData,
  OrderDetailsPrescriptionAdditionalParams,
  OrderHistoryDetailsEssilor,
  OrderTYPEssilorPayload,
} from "../store/checkout/orderConfirmationEssilorInterfaces";
import {
  ItemLeft,
  ItemRight,
  Ord03Items,
  OrderDetailsGenericParams,
  OrderDetailsResult,
  OrderPrescription,
} from "../store/order-history/orderHistoryInterface";
import { saveOrd03JsonEssilor } from "../store/order-history/orderHistorySlice";
import { FocalTypePrescription } from "../store/rx/rxInterface";
import { hasAtLeastOneValueFilled } from "./utils";

export const mapOrderJsonLensDetails = (orderJSON: any): OrderConfirmationLensData => {
  const mappedData: OrderConfirmationLensData = {
    lensShape: {
      imageUri: orderJSON?.Shape?.Thumbnail,
      caliber: {
        OD: orderJSON?.Shape?.AboxR,
        OS: orderJSON?.Shape?.AboxL,
      },
      height: {
        OD: orderJSON?.Shape?.BboxR,
        OS: orderJSON?.Shape?.BboxL,
      },
      bridge: {
        OD: orderJSON?.Shape?.Dbox,
        OS: orderJSON?.Shape?.Dbox,
      },
      frameType: orderJSON?.Frame?.TypeName,
      shapeMethod: orderJSON?.Shape?.shapeOriginText,
      fileName: orderJSON?.Shape?.FilePath,
    },
    prescriptionDetails: getPrescriptionDetails(orderJSON),
    lensDetails: {
      OD: {
        lensName: orderJSON?.Patient?.RightEye?.Lens?.LensName,
        lensPrice: orderJSON?.Patient?.RightEye?.Lens?.PriceFormated,
        lensTreatment:
          orderJSON?.Patient?.RightEye?.LensDetail?.Treatment?.map((_: any) => _.Value).join(",") ||
          "-",
        lensTreatmentPrice: orderJSON?.Patient?.RightEye?.LensDetail?.Treatment?.map(
          (_: any) => _.PriceFormated
        ).join(","),
        lensColor: orderJSON?.Patient?.RightEye?.LensDetail?.Tint?.map((_: any) => _?.Value).join(
          ","
        ),
        precal: orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Precal
          ? "ORDER_CONFIRMATION_YES"
          : "ORDER_CONFIRMATION_NO",
        easyFit:
          orderJSON?.Patient?.RightEye?.EdgeFinish?.JobTypeCode === "EDG"
            ? "ORDER_CONFIRMATION_YES"
            : "ORDER_CONFIRMATION_NO",
        edgeType: orderJSON?.Shape?.FrameType?.EdgeType?.ResourceName,
        edgeFinish: orderJSON?.Patient?.RightEye?.EdgeFinish?.ResourceName,
        internalChamfer: orderJSON?.Patient?.RightEye?.Chamfer?.Back?.ResourceName,
        externalChamfer: orderJSON?.Patient?.RightEye?.Chamfer?.Front?.ResourceName,
      },
      OS: {
        lensName: orderJSON?.Patient?.LeftEye?.Lens?.LensName,
        lensPrice: orderJSON?.Patient?.LeftEye?.Lens?.PriceFormated,
        lensTreatment:
          orderJSON?.Patient?.LeftEye?.LensDetail?.Treatment?.map((_: any) => _.Value).join(",") ||
          "-",
        lensTreatmentPrice: orderJSON?.Patient?.LeftEye?.LensDetail?.Treatment?.map(
          (_: any) => _.PriceFormated
        ).join(","),
        lensColor: orderJSON?.Patient?.LeftEye?.LensDetail?.Tint?.map((_: any) => _?.Value).join(
          ","
        ),
        precal: orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Precal
          ? "ORDER_CONFIRMATION_YES"
          : "ORDER_CONFIRMATION_NO",
        easyFit:
          orderJSON?.Patient?.LeftEye?.EdgeFinish?.JobTypeCode === "EDG"
            ? "ORDER_CONFIRMATION_YES"
            : "ORDER_CONFIRMATION_NO",
        edgeType: orderJSON?.Shape?.FrameType?.EdgeType?.ResourceName,
        edgeFinish: orderJSON?.Patient?.LeftEye?.EdgeFinish?.ResourceName,
        internalChamfer: orderJSON?.Patient?.LeftEye?.Chamfer?.Back?.ResourceName,
        externalChamfer: orderJSON?.Patient?.LeftEye?.Chamfer?.Front?.ResourceName,
      },
    },
    frameDetails: {
      frameImage: orderJSON?.Frame?.ImageBigName,
      sku: orderJSON?.Frame?.SKU,
      model: orderJSON?.Frame?.ModelName,
      color: orderJSON?.Frame?.ColorName,
      brand: orderJSON?.Frame?.BrandName,
      frameType: orderJSON?.Frame?.TypeName,
      caliber: orderJSON?.Frame?.SizeName,
    },
  };
  return mappedData;
};

export const mapOrderJsonHeaderDetails = (orderJSON: any): OrderConfirmationHeaderData => {
  const mappedData: OrderConfirmationHeaderData = {
    fields: {
      shippingAccount: [],
      clientName: (orderJSON?.Patient?.FirstName + " " + orderJSON?.Patient?.LastName).trim(),
      poNumber: orderJSON?.ExternalOrderID,
      orderRef: orderJSON?.OrderReference,
      comments: orderJSON?.OrderComments,
    },
    summary: {
      isMultidoor: false,
      totalNumber: orderJSON?.Frame?.ShippedData ? 2 : 1,
      totalPrice:
        +orderJSON?.Lens?.PriceFormated +
        +orderJSON?.LensDetail?.Treatment?.map((_: any) => _.PriceFormated).reduce(
          (previous: number, current: number) => (previous += current),
          0
        ) +
        +orderJSON?.LensDetail?.Tint?.PriceFormated +
        +orderJSON?.ShowProcess?.PriceFormated +
        +orderJSON?.JobTypePrice,
    },
  };
  return mappedData;
};

export const mapOrderJsonThankYouPayload = (orderJSON: any): OrderTYPEssilorPayload => {
  const mappedData: OrderTYPEssilorPayload = {
    size: orderJSON?.Frame?.SizeName,
    lensName: orderJSON?.Patient?.RightEye?.Lens?.LensName,
    lensBrand: orderJSON?.Patient?.RightEye?.Lens?.BrandName,
    frameName: orderJSON?.Frame?.ModelName,
    frameBrand: orderJSON?.Frame?.BrandName,
    lensData: {
      design: orderJSON?.Patient?.LeftEye?.Lens?.DesignNameValue,
      material: orderJSON?.Patient?.LeftEye?.Lens?.MaterialNameValue,
      massTint: orderJSON?.Patient?.LeftEye?.LensDetail.Tint.map((_: any) => _.Value).join(", "),
      treatments:
        orderJSON?.Patient?.LeftEye?.LensDetail.Treatment.map((_: any) => _.Value).join(", ") ||
        "-",
      shapePreviewUrl: orderJSON.Shape.Thumbnail,
    },
    frameData: {
      kindOfFrame: "-",
      model: orderJSON.Frame.ModelName,
      color: orderJSON.Frame.ColorName,
      framePreviewUrl: orderJSON.Frame.ImageBigName,
    },
    orderRef: {
      orderReference: orderJSON.OrderReference,
      jobType: orderJSON.JobType.ResourceName,
    },
    orderJSON: orderJSON,
  };
  return mappedData;
};

export const getPDFTechnicalDetailsParams = (orderJSON: any): any => {
  return {
    creationDate: orderJSON?.CreationDate?.split(".")[0].replace(/{-,:}/g, "-"),
    orderId: orderJSON?.ReusedOrderIdBase64,
    jobTypeCode: orderJSON?.JobType?.Code,
    billingName: orderJSON?.BillingAccount?.FirstName,
    billingSurname: orderJSON?.BillingAccount?.LastName,
    billingAddress: orderJSON?.BillingAccount?.Address?.AddressName, //FULL ADDRESS HERE
    shippingName: orderJSON?.ShippingAccount?.FirstName,
    shippingSurname: orderJSON?.ShippingAccount?.LastName,
    shippingAddress: orderJSON?.ShippingAccount?.Address?.AddressName, //FULL ADDRESS HERE
    calculationImage: orderJSON?.Shape?.Thumbnail,
    sphereOD: orderJSON?.Patient?.RightEye?.Prescription?.Sphere,
    cylinderOD: orderJSON?.Patient?.RightEye?.Prescription?.Cylinder,
    axisOD: orderJSON?.Patient?.RightEye?.Prescription?.Axis,
    additionOD: orderJSON?.Patient?.RightEye?.Prescription?.Addition,
    prismDiopInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue1,
    prismDirInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis1,
    prismDiopUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue2,
    prismDirUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis2,
    decentDiopInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationValue1,
    decentDirInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationAxis1,
    decentDiopUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationValue2,
    decentDirUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationAxis2,
    pdOD: orderJSON?.Patient?.RightEye?.Measurements?.PD?.toString(),
    heightOD: (
      orderJSON?.Patient?.RightEye?.Measurements?.Height ||
      orderJSON?.Patient?.RightEye?.Measurements?.OC
    )?.toString(),
    vertexFittedOD: (orderJSON?.Patient?.RightEye?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "REVertex"
    ) as any)?.Value,
    faceformTiltOD: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "WrapAngle"
    ) as any)?.Value,
    pantoScopicOD: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "PantoAngle"
    ) as any)?.Value,
    sphereOS: orderJSON?.Patient?.LeftEye?.Prescription?.Sphere,
    cylinderOS: orderJSON?.Patient?.LeftEye?.Prescription?.Cylinder,
    axisOS: orderJSON?.Patient?.LeftEye?.Prescription?.Axis,
    additionOS: orderJSON?.Patient?.LeftEye?.Prescription?.Addition,
    prismDiopInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue1,
    prismDirInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis1,
    prismDiopUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue2,
    prismDirUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis2,
    decentDiopInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationValue1,
    decentDirInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationAxis1,
    decentDiopUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationValue2,
    decentDirUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationAxis2,
    pdOS: orderJSON?.Patient?.LeftEye?.Measurements?.PD?.toString(),
    heightOS: (
      orderJSON?.Patient?.LeftEye?.Measurements?.Height ||
      orderJSON?.Patient?.LeftEye?.Measurements?.OC
    )?.toString(),
    vertexFittedOS: (orderJSON?.Patient?.LeftEye?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "REVertex"
    ) as any)?.Value,
    faceformTiltOS: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "WrapAngle"
    ) as any)?.Value,
    pantoScopicOS: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "PantoAngle"
    ) as any)?.Value,
    eyecode: orderJSON?.Patient?.HasEyeCodeSelected ? "DIALOG_YES" : "DIALOG_NO",
    dominationEye: orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "DominantEye"
    ), //FIX THIS
    lensIdOD: orderJSON?.Patient?.RightEye?.Lens?.LensId?.toString(),
    lensTypeOD: orderJSON?.Patient?.RightEye?.Lens?.DesignTypeNameValue,
    designOD: orderJSON?.Patient?.RightEye?.Lens?.DesignNameValue,
    materialOD: orderJSON?.Patient?.RightEye?.Lens?.MaterialNameValue,
    tintOD: orderJSON?.Patient?.RightEye?.LensDetail?.Tint?.map((_: any) => _?.Value)?.join(","),
    treatmentsOD: orderJSON?.Patient?.RightEye?.LensDetail?.Treatment?.map(
      (_: any) => _?.Value
    )?.join(","),
    lensIdOS: orderJSON?.Patient?.LeftEye?.Lens?.LensId?.toString(),
    lensTypeOS: orderJSON?.Patient?.LeftEye?.Lens?.DesignTypeNameValue,
    designOS: orderJSON?.Patient?.LeftEye?.Lens?.DesignNameValue,
    materialOS: orderJSON?.Patient?.LeftEye?.Lens?.MaterialNameValue,
    tintOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Tint?.map((_: any) => _?.Value)?.join(","),
    treatmentsOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Treatment?.map(
      (_: any) => _?.Value
    )?.join(","),
    minThicknessOD: orderJSON?.CalculationVisualization?.CalculationRight?.MinThickness,
    maxThicknessOD: orderJSON?.CalculationVisualization?.CalculationRight?.MaxThickness,
    thicknessCenterOD: orderJSON?.CalculationVisualization?.CalculationRight?.CenterThickness,
    weightOD: orderJSON?.CalculationVisualization?.CalculationRight?.Weight,
    baseOD: orderJSON?.CalculationVisualization?.CalculationRight?.Base,
    minThicknessOS: orderJSON?.CalculationVisualization?.CalculationLeft?.MinThickness,
    maxThicknessOS: orderJSON?.CalculationVisualization?.CalculationLeft?.MaxThickness,
    thicknessCenterOS: orderJSON?.CalculationVisualization?.CalculationLeft?.CenterThickness,
    weightOS: orderJSON?.CalculationVisualization?.CalculationLeft?.Weight,
    baseOS: orderJSON?.CalculationVisualization?.CalculationLeft?.Base,
    calculationOD: orderJSON?.CalculationVisualization?.CalculationRight?.DiameterCalculationSource,
    minDiameterOD: orderJSON?.CalculationVisualization?.CalculationRight?.CalculatedMinimumDiameter,
    maxDiameterOD: orderJSON?.CalculationVisualization?.CalculationRight?.CommercialDiameter,
    physicalDiameterOD: orderJSON?.Patient?.RightEye?.LensDetail?.Diameter?.PhysicalDiameter?.toString(),
    selectedDiameterOD: orderJSON?.Patient?.RightEye?.LensDetail?.Diameter?.DiameterNameValue?.toString(),
    calculationOS: orderJSON?.CalculationVisualization?.CalculationLeft?.DiameterCalculationSource,
    minDiameterOS: orderJSON?.CalculationVisualization?.CalculationLeft?.CalculatedMinimumDiameter,
    maxDiameterOS: orderJSON?.CalculationVisualization?.CalculationLeft?.CommercialDiameter,
    physicalDiameterOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Diameter?.PhysicalDiameter?.toString(),
    selectedDiameterOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Diameter?.DiameterNameValue?.toString(),
    prysmOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue1,
    precalOD: orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Precal
      ? "DIALOGS_YES"
      : "DIALOGS_NO",
    thicknessStringOD:
      orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Resource,
    thicknessNumberOD:
      orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessValue?.DefaultThickness ||
      "-",
    frameCurveOD: orderJSON?.Frame?.FrameCurveRight?.toString(),
    prysmOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue1,
    precalOS: orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Precal
      ? "DIALOGS_YES"
      : "DIALOGS_NO",
    thicknessStringOS:
      orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Resource,
    thicknessNumberOS:
      orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessValue?.DefaultThickness,
    frameCurveOS: orderJSON?.Frame?.FrameCurveLeft?.toString(),
    OriginalShapeboxAOD: orderJSON?.Shape?.OriginalAboxR?.toString(),
    OriginalShapeboxBOD: orderJSON?.Shape?.OriginalBboxR?.toString(),
    OriginalShapeboxCOD: orderJSON?.Shape?.OriginalDbox?.toString(),
    OriginalShapeboxAOS: orderJSON?.Shape?.OriginalAboxL?.toString(),
    OriginalShapeboxBOS: orderJSON?.Shape?.OriginalBboxL?.toString(),
    OriginalShapeboxCOS: orderJSON?.Shape?.OriginalDbox?.toString(),
    ShapeDetailsboxAOD: orderJSON?.Shape?.AboxR?.toString(),
    ShapeDetailsboxBOD: orderJSON?.Shape?.BboxR?.toString(),
    ShapeDetailsboxCOD: orderJSON?.Shape?.Dbox?.toString(),
    ShapeDetailsboxAOS: orderJSON?.Shape?.AboxL?.toString(),
    ShapeDetailsboxBOS: orderJSON?.Shape?.BboxL?.toString(),
    ShapeDetailsboxCOS: orderJSON?.Shape?.Dbox?.toString(),
    frameType: orderJSON?.Shape?.FrameType?.ResourceName,
    shapeSelection: orderJSON?.Shape?.shapeOriginText,
    shapeQuality: orderJSON?.Shape?.Quality?.toString(),
    jobType: orderJSON?.JobType?.ResourceName.toString(),
    frameTypeOD: orderJSON?.Frame?.TypeName.toString(),
    edgeTypeOD: orderJSON?.Patient?.RightEye?.EdgeFinish.ResourceName,
    edgeFinishingOD: orderJSON?.Patient?.RightEye?.EdgeFinishValue?.ResourceName,
    frontChamferOD: orderJSON?.Patient?.RightEye?.Chamfer?.Front?.ResourceName,
    backChamferOD: orderJSON?.Patient?.RightEye?.Chamfer?.Back?.ResourceName,
    grooveDepthOD: orderJSON?.Patient?.Groove?.Depth,
    grooveWidthOD: orderJSON?.Patient?.Groove?.Width,
    polishOD: orderJSON?.Patient?.Edg,
    frameTypeOS: orderJSON?.Frame?.TypeName,
    edgeTypeOS: orderJSON?.Patient?.LeftEye?.EdgeFinish.ResourceName,
    edgeFinishingOS: orderJSON?.Patient?.LeftEye?.EdgeFinishValue?.ResourceName,
    frontChamferOS: orderJSON?.Patient?.LeftEye?.Chamfer?.Front?.ResourceName,
    backChamferOS: orderJSON?.Patient?.LeftEye?.Chamfer?.Back?.ResourceName,
    grooveDepthOS: orderJSON?.Patient?.Groove?.Depth,
    grooveWidthOS: orderJSON?.Patient?.Groove?.Width,
    polishOS: orderJSON?.Patient?.EdgePolish,
    //DesignNameValue
  };
};

////////// Replacing json value with ord03 catched in splitDetails

export const mapOrd03Json = (data: any, orderDetailsResult?: Ord03Items, ord03Json?: JSON): any => {
  let dataJson;
  const dispatch = useDispatch();

  const { itemRight, itemLeft, itemGeneric } = orderDetailsResult ?? {};

  //if ord03Json is already mapped, use it to get faster execution otherwise map
  if (ord03Json) dataJson = ord03Json;
  else {
    dataJson = JSON.parse(data);
    if (dataJson) {
      dataJson = mapItemsRight(dataJson, itemRight);
      dataJson = mapItemsLeft(dataJson, itemLeft);
      dataJson = mapItemsGeneric(dataJson, itemRight, itemLeft, itemGeneric);
      dispatch(saveOrd03JsonEssilor(dataJson));
    }
  }

  return dataJson;
};

export const mapTint = (tint: { [key: string]: any }): any[] => {
  const items: any[] = [];
  if (tint)
    for (const key in tint) {
      if (key in tint) {
        const value = tint[key];
        if (value !== undefined && value !== null && value !== "" && value !== "-") {
          items.push({
            Id: 0,
            LensId: 478687,
            OfferId: 3,
            AddedValueId: 27428,
            MasterCode: "ADV-2023-6F",
            SpecialParameterTypeId: 0,
            DisplayOrder: 236,
            Code: "330",
            AddedValueFilterGroupLabel: null,
            ADVId: "87",
            Price: null,
            Ranking: null,
            AddedValueGroupId: 2,
            MasterAddedValueTypeCode: "TINT",
            PSCode: null,
            Selectability: "SELECTABLE",
            AddedValueFamilyId: 13401936,
            MandatoryGroupId: 0,
            AddedValueFamilyTypeId: 2,
            Locale: "itIT",
            Value: value,
            ServiceMandatoryGroupId: -1,
            GroupId: -1,
            MandatoryServiceId: -1,
            StandardFilterAddedValueName: null,
            StandardFilterAddedValueOrder: null,
            PriceFormated: null,
          });
        }
      }
    }
  return items;
};

export const mapTreatment = (treatment?: string): any[] => {
  const items: any[] = [];
  if (treatment)
    items.push({
      Id: 0,
      LensId: 478687,
      OfferId: 3,
      AddedValueId: 162669,
      MasterCode: "ADV-2024-17TWT",
      SpecialParameterTypeId: 0,
      DisplayOrder: 26,
      Code: "048",
      AddedValueFilterGroupLabel: null,
      ADVId: "2",
      Price: null,
      Ranking: null,
      AddedValueGroupId: 1,
      MasterAddedValueTypeCode: "ARHC",
      PSCode: null,
      Selectability: "MANDATORY",
      AddedValueFamilyId: 13400692,
      MandatoryGroupId: 1,
      AddedValueFamilyTypeId: 1,
      Locale: "itIT",
      Value: treatment,
      ServiceMandatoryGroupId: 3891942,
      GroupId: 1,
      MandatoryServiceId: 27236,
      StandardFilterAddedValueName: null,
      StandardFilterAddedValueOrder: null,
      PriceFormated: null,
    });
  return items;
};

export const mapItemsInOrderDetails = (
  orderDetailsResult: OrderDetailsResult
): {
  itemRight: ItemRight | undefined;
  itemLeft: ItemLeft | undefined;
  itemGeneric: OrderDetailsGenericParams | undefined;
} => {
  let itemRight: ItemRight | undefined = undefined;
  let itemLeft: ItemLeft | undefined = undefined;
  let itemGeneric: OrderDetailsGenericParams | undefined = undefined;

  //Check if skuDetail contains left and right eye
  if (orderDetailsResult?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail) {
    //iterate over two arrays, if itemRight is undefined than check and assign, otherwise skip so that compute is faster
    //hasAtLeastOneValueFilled check if the array is for right or left because when find one value than we "assume" it is right or left
    for (const obj of orderDetailsResult?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail) {
      itemRight =
        itemRight == undefined && hasAtLeastOneValueFilled(obj?.prescription?.[0]?.itemRight)
          ? obj?.prescription?.[0]?.itemRight
          : itemRight;

      itemLeft =
        itemLeft == undefined && hasAtLeastOneValueFilled(obj?.prescription?.[0]?.itemLeft)
          ? obj?.prescription?.[0]?.itemLeft
          : itemLeft;
    }
  }

  itemGeneric = {
    orderedDate: orderDetailsResult?.orderedDate,
    rxId: orderDetailsResult?.rxId,
    lensDescription:
      orderDetailsResult?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0]?.prescription?.[0]
        ?.item?.[0]?.lensDescription,
  };

  return { itemRight, itemLeft, itemGeneric };
};

export const mapItemsGeneric = (
  dataJson: any,
  itemRight?: ItemRight,
  itemLeft?: ItemLeft,
  otherParams?: OrderDetailsGenericParams
): any => {
  dataJson.Patient = dataJson?.Patient || {};
  dataJson.Patient.SpecialParameters = dataJson?.Patient?.SpecialParameters || {};
  dataJson.Patient.Groove = dataJson?.Patient?.Groove || {};

  dataJson.Patient.RightEye = dataJson?.Patient?.RightEye || {};
  dataJson.Patient.RightEye.SpecialParameters =
    dataJson?.Patient?.RightEye?.SpecialParameters || {};

  //Eye code
  dataJson.Patient.SpecialParameters.EyeCode =
    itemRight?.eyecode || itemLeft?.eyecode || dataJson?.Patient?.SpecialParameters?.EyeCode;

  //Cape
  dataJson.Patient.SpecialParameters.CAPE =
    itemRight?.headCapeAngle ||
    itemLeft?.headCapeAngle ||
    dataJson?.Patient?.SpecialParameters?.CAPE;

  //Init
  dataJson.Patient.SpecialParameters.Init =
    itemRight?.wearerInitial ||
    itemLeft?.wearerInitial ||
    dataJson?.Patient?.SpecialParameters?.Init;

  // HECoeff
  dataJson.Patient.SpecialParameters.HECoeff =
    itemRight?.hECoeff ||
    itemLeft?.hECoeff ||
    itemRight?.HECoeff ||
    itemLeft?.HECoeff ||
    dataJson?.Patient?.SpecialParameters?.HECoeff;

  // PantoAngle
  dataJson.Patient.SpecialParameters.PantoAngle =
    itemLeft?.pantoTilt || itemRight?.pantoTilt || dataJson?.Patient?.SpecialParameters?.PantoAngle;

  // ReadingDistance
  dataJson.Patient.SpecialParameters.ReadingDistance =
    itemRight?.readingDistance ||
    itemLeft?.readingDistance ||
    dataJson?.Patient?.SpecialParameters?.ReadingDistance;

  // DominantEye
  dataJson.Patient.SpecialParameters.DominantEye =
    itemRight?.dominantEye ||
    itemLeft?.dominantEye ||
    dataJson?.Patient?.SpecialParameters?.DominantEye;

  // NearVisionBehaviour
  dataJson.Patient.SpecialParameters.NearVisionBehaviour =
    itemRight?.nearVisionBehaviour ||
    itemLeft?.nearVisionBehaviour ||
    dataJson?.Patient?.SpecialParameters?.NearVisionBehaviour;

  // WrapAngle
  dataJson.Patient.SpecialParameters.WrapAngle =
    itemRight?.faceformTilt ||
    itemLeft?.faceformTilt ||
    dataJson?.Patient?.SpecialParameters?.WrapAngle;

  // .REHeight
  dataJson.Patient.SpecialParameters.REHeight =
    itemRight?.height || itemLeft?.height || dataJson?.Patient?.SpecialParameters?.REHeight;

  // .LEHeight
  dataJson.Patient.SpecialParameters.LEHeight =
    itemRight?.height || itemLeft?.height || dataJson?.Patient?.SpecialParameters?.LEHeight;

  // .REFarPD
  dataJson.Patient.SpecialParameters.REFarPD =
    itemRight?.farPD || itemLeft?.farPD || dataJson?.Patient?.SpecialParameters?.REFarPD;

  // .LEFarPD
  dataJson.Patient.SpecialParameters.LEFarPD =
    itemRight?.farPD || itemLeft?.farPD || dataJson?.Patient?.SpecialParameters?.LEFarPD;

  // EdgePolish
  dataJson.Patient.EdgePolish =
    itemRight?.edgePolish || itemLeft?.edgePolish || dataJson?.Patient?.EdgePolish;

  // Groove.Depth
  dataJson.Patient.Groove.Depth =
    itemRight?.grooveDepth || itemLeft?.grooveDepth || dataJson?.Patient?.Groove?.Depth;

  // Groove.Width
  dataJson.Patient.Groove.Width =
    itemRight?.grooveWidth || itemLeft?.grooveWidth || dataJson?.Patient?.Groove?.Width;

  // .LEDistancePD
  dataJson.Patient.RightEye.SpecialParameters.LEDistancePD =
    itemRight?.distancePD ||
    itemLeft?.distancePD ||
    dataJson?.Patient?.RightEye?.SpecialParameters?.LEDistancePD;

  // OrderedDate
  dataJson.OrderedDate = otherParams?.orderedDate || dataJson?.OrderedDate;
  dataJson.OrderIdBase64 = otherParams?.rxId ?? dataJson?.OrderIdBase64;

  return dataJson;
};

export const mapItemsLeft = (dataJson: { [key: string]: any }, itemLeft?: ItemLeft): any => {
  dataJson.Patient = dataJson?.Patient || {};
  dataJson.Patient.LeftEye = dataJson?.Patient?.LeftEye || {};
  dataJson.Patient.SpecialParameters = dataJson?.Patient?.SpecialParameters || {};
  dataJson.Patient.LeftEye.Prescription = dataJson?.Patient?.LeftEye?.Prescription || {};
  dataJson.Patient.LeftEye.Lens = dataJson?.Patient?.LeftEye?.Lens || {};
  dataJson.Patient.LeftEye.SpecialParameters = dataJson?.Patient?.LeftEye?.SpecialParameters || {};
  dataJson.Patient.LeftEye.Chamfer = dataJson?.Patient?.LeftEye?.Chamfer || {};
  dataJson.Patient.LeftEye.Chamfer.Back = dataJson?.Patient?.LeftEye?.Chamfer?.Back || {};
  dataJson.Patient.LeftEye.Chamfer.Front = dataJson?.Patient?.LeftEye?.Chamfer?.Front || {};
  dataJson.Patient.LeftEye.EdgeFinish = dataJson?.Patient?.LeftEye?.EdgeFinish || {};
  dataJson.Patient.LeftEye.EdgeFinishValue = dataJson?.Patient?.LeftEye?.EdgeFinishValue || {};
  dataJson.Patient.LeftEye.LensDetail = dataJson?.Patient?.LeftEye?.LensDetail || {};
  dataJson.Patient.LeftEye.LensDetail.Diameter =
    dataJson?.Patient?.LeftEye?.LensDetail?.Diameter || {};
  dataJson.Patient.LeftEye.LensDetail.LensProcess =
    dataJson?.Patient?.LeftEye?.LensDetail?.LensProcess || {};
  dataJson.Patient.LeftEye.Measurements = dataJson?.Patient?.LeftEye?.Measurements || {};
  dataJson.Patient.LeftEye.Measurements.Thickness =
    dataJson?.Patient?.LeftEye?.Measurements?.Thickness || {};
  dataJson.Patient.LeftEye.Measurements.Thickness.ThicknessType =
    dataJson?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType || {};
  dataJson.Patient.LeftEye.Measurements.Thickness.ThicknessValue =
    dataJson?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessValue || {};

  // -> **************** DON'T TOUCH IT
  dataJson.Patient.RightEye = dataJson?.Patient?.RightEye || {};
  dataJson.Patient.RightEye.SpecialParameters =
    dataJson?.Patient?.RightEye?.SpecialParameters || {};
  // <- **************** DON'T TOUCH IT

  // Sphere
  dataJson.Patient.LeftEye.Prescription.Sphere =
    itemLeft?.sphere ?? dataJson?.Patient?.LeftEye?.Prescription?.Sphere;

  // PrismAxis1
  dataJson.Patient.LeftEye.Prescription.PrismAxis1 =
    itemLeft?.direction ?? dataJson?.Patient?.LeftEye?.Prescription?.PrismAxis1;

  // PrismValue2
  dataJson.Patient.LeftEye.Prescription.PrismValue2 =
    itemLeft?.prismUD ?? dataJson?.Patient?.LeftEye?.Prescription?.PrismValue2;

  // PrismAxis2
  dataJson.Patient.LeftEye.Prescription.PrismAxis1 =
    itemLeft?.directionUD ?? dataJson?.Patient?.LeftEye?.Prescription?.PrismAxis1;

  // PD
  dataJson.Patient.LeftEye.Measurements.PD =
    itemLeft?.pd.toString() ?? dataJson?.Patient?.LeftEye?.Measurements?.PD;

  // Height
  dataJson.Patient.LeftEye.Measurements.Height =
    itemLeft?.mountingHeight?.toString() ?? dataJson?.Patient?.LeftEye?.Measurements?.Height;

  // OC
  dataJson.Patient.LeftEye.Measurements.OC =
    itemLeft?.mountingHeight?.toString() ?? dataJson?.Patient?.LeftEye?.Measurements?.OC;

  //Eye code
  dataJson.Patient.LeftEye.SpecialParameters.EyeCode =
    itemLeft?.eyecode ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.EyeCode;

  //Cape
  dataJson.Patient.LeftEye.SpecialParameters.CAPE =
    itemLeft?.headCapeAngle ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.CAPE;

  //Leercd
  dataJson.Patient.SpecialParameters.LEERCD =
    itemLeft?.ercd ?? dataJson?.Patient?.SpecialParameters?.LEERCD;

  //Leercd
  dataJson.Patient.LeftEye.SpecialParameters.LEERCD =
    itemLeft?.ercd ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.LEERCD;

  //Init
  dataJson.Patient.LeftEye.SpecialParameters.Init =
    itemLeft?.wearerInitial ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.Init;

  // HECoeff
  dataJson.Patient.LeftEye.SpecialParameters.HECoeff =
    (itemLeft?.hECoeff || itemLeft?.HECoeff) ??
    dataJson?.Patient?.LeftEye?.SpecialParameters?.HECoeff;

  // PantoAngle
  dataJson.Patient.LeftEye.SpecialParameters.PantoAngle =
    itemLeft?.pantoTilt ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.PantoAngle;

  // ReadingDistance
  dataJson.Patient.LeftEye.SpecialParameters.ReadingDistance =
    itemLeft?.readingDistance ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.ReadingDistance;

  // DominantEye
  dataJson.Patient.LeftEye.SpecialParameters.DominantEye =
    itemLeft?.dominantEye ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.DominantEye;

  // NearVisionBehaviour
  dataJson.Patient.LeftEye.SpecialParameters.NearVisionBehaviour =
    itemLeft?.nearVisionBehaviour ??
    dataJson?.Patient?.LeftEye?.SpecialParameters?.NearVisionBehaviour;

  // WrapAngle
  dataJson.Patient.LeftEye.SpecialParameters.WrapAngle =
    itemLeft?.faceformTilt ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.WrapAngle;

  // .LEVertex
  dataJson.Patient.SpecialParameters.LEVertex =
    itemLeft?.vertexDistance ?? dataJson?.Patient?.SpecialParameters?.LEVertex;

  // .LEVertex
  dataJson.Patient.LeftEye.SpecialParameters.LEVertex =
    itemLeft?.vertexDistance ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.LEVertex;

  // .LEInset
  dataJson.Patient.SpecialParameters.LEInset =
    itemLeft?.inset ?? dataJson?.Patient?.SpecialParameters?.LEInset;

  // ?.LEInset
  dataJson.Patient.LeftEye.SpecialParameters.LEInset =
    itemLeft?.inset ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.LEInset;

  // .LEDistancePD
  dataJson.Patient.LeftEye.SpecialParameters.LEDistancePD =
    itemLeft?.distancePD ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.LEDistancePD;

  // .REHeight
  dataJson.Patient.LeftEye.SpecialParameters.REHeight =
    itemLeft?.height ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.REHeight;

  // .LEHeight
  dataJson.Patient.LeftEye.SpecialParameters.LEHeight =
    itemLeft?.height ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.LEHeight;

  // .REFarPD
  dataJson.Patient.LeftEye.SpecialParameters.REFarPD =
    itemLeft?.farPD ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.REFarPD;

  // .LEFarPD
  dataJson.Patient.LeftEye.SpecialParameters.LEFarPD =
    itemLeft?.farPD ?? dataJson?.Patient?.LeftEye?.SpecialParameters?.LEFarPD;
  // .Lens.Code
  dataJson.Patient.LeftEye.Lens.Code = itemLeft?.lensCode ?? dataJson?.Patient?.LeftEye?.Lens?.Code;

  // Lens.DesignTypeNameValue
  dataJson.Patient.LeftEye.Lens.DesignTypeNameValue =
    itemLeft?.lensDesignTypeNameValue ?? dataJson?.Patient?.LeftEye?.Lens?.DesignTypeNameValue;

  // DesignNameValue
  dataJson.Patient.LeftEye.Lens.DesignNameValue =
    itemLeft?.lensDesignNameValue ?? dataJson?.Patient?.LeftEye?.Lens?.DesignNameValue;

  // MaterialNameValue
  dataJson.Patient.LeftEye.Lens.MaterialNameValue =
    itemLeft?.lensMaterialNameValue ?? dataJson?.Patient?.LeftEye?.Lens?.MaterialNameValue;
  // Treatment
  const treatment = mapTreatment(itemLeft?.treatment);
  dataJson.Patient.LeftEye.LensDetail.Treatment =
    treatment.length > 0 ? treatment : dataJson?.Patient?.LeftEye?.LensDetail?.Treatment;

  // LeftEye.Chamfer.Back.ResourceName
  dataJson.Patient.LeftEye.Chamfer.Back.ResourceName =
    itemLeft?.chamferBackResourceName || dataJson?.Patient?.LeftEye?.Chamfer?.Back?.ResourceName;

  // LeftEye.Chamfer.Front.ResourceName
  dataJson.Patient.LeftEye.Chamfer.Front.ResourceName =
    itemLeft?.chamferFrontResourceName || dataJson?.Patient?.LeftEye?.Chamfer?.Front?.ResourceName;

  // LeftEye.EdgeFinish.ResourceName
  dataJson.Patient.LeftEye.EdgeFinish.ResourceName =
    itemLeft?.edgeFinishResourceName || dataJson?.Patient?.LeftEye?.EdgeFinish?.ResourceName;

  // LeftEye.EdgeFinishValue.ResourceName
  dataJson.Patient.LeftEye.EdgeFinishValue.ResourceName =
    itemLeft?.edgeFinishValueResourceNam ||
    dataJson?.Patient?.LeftEye?.EdgeFinishValue?.ResourceName;

  // LeftEye.LensDetail.Diameter.PhysicalDiameter
  dataJson.Patient.LeftEye.LensDetail.Diameter.PhysicalDiameter =
    itemLeft?.lensDetailDiameterPhysical ||
    dataJson?.Patient?.LeftEye?.LensDetail?.Diameter?.PhysicalDiameter;

  // LeftEye.LensDetail.LensProcess.EquiThinning
  dataJson.Patient.LeftEye.LensDetail.LensProcess.EquiThinning =
    itemLeft?.lensDetailLensProcessEqui ||
    dataJson?.Patient?.LeftEye?.LensDetail?.LensProcess?.EquiThinning;

  // LeftEye.Measurements.Thickness.ThicknessType.Precal
  dataJson.Patient.LeftEye.Measurements.Thickness.ThicknessType.Precal =
    itemLeft?.thicknessTypePrecal ||
    dataJson?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Precal;

  // LeftEye.Measurements.Thickness.ThicknessType.Resource
  dataJson.Patient.LeftEye.Measurements.Thickness.ThicknessType.Resource =
    itemLeft?.thicknessTypeResource ||
    dataJson?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Resource;

  // LeftEye.Measurements.Thickness.ThicknessValue.DefaultThickness
  dataJson.Patient.LeftEye.Measurements.Thickness.ThicknessValue.DefaultThickness =
    itemLeft?.thicknessValueDefaultThickn ||
    dataJson?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessValue?.DefaultThickness;

  // Patient.LeftEye.Prescription.DecentrationAxis1
  dataJson.Patient.LeftEye.Prescription.DecentrationAxis1 =
    itemLeft?.decentrationAxis1 || dataJson?.Patient?.LeftEye?.Prescription?.DecentrationAxis1;

  // Patient.LeftEye.Prescription.DecentrationAxis2
  dataJson.Patient.LeftEye.Prescription.DecentrationAxis2 =
    itemLeft?.decentrationAxis2 || dataJson?.Patient?.LeftEye?.Prescription?.DecentrationAxis2;

  // Patient.LeftEye.Prescription.DecentrationAxisStr1
  dataJson.Patient.LeftEye.Prescription.DecentrationAxisStr1 =
    itemLeft?.decentrationAxisStr2 ||
    dataJson?.Patient?.LeftEye?.Prescription?.DecentrationAxisStr1;

  // Patient.LeftEye.Prescription.DecentrationAxisStr2
  dataJson.Patient.LeftEye.Prescription.DecentrationAxisStr2 =
    itemLeft?.decentrationAxisStr2 ||
    dataJson?.Patient?.LeftEye?.Prescription?.DecentrationAxisStr2;

  // Patient.LeftEye.Prescription.PrismValue2
  dataJson.Patient.LeftEye.Prescription.PrismValue2 =
    itemLeft?.prismUp || dataJson?.Patient?.LeftEye?.Prescription?.PrismValue2;

  // Cylinder
  dataJson.Patient.LeftEye.Prescription.Cylinder =
    itemLeft?.cylinder ?? dataJson?.Patient?.LeftEye?.Prescription?.Cylinder;

  // Axis
  dataJson.Patient.LeftEye.Prescription.Axis =
    itemLeft?.axis ?? dataJson?.Patient?.LeftEye?.Prescription?.Axis;

  // Addition
  dataJson.Patient.LeftEye.Prescription.Addition =
    itemLeft?.add ?? dataJson?.Patient?.LeftEye?.Prescription?.Addition;

  // PrismValue1
  dataJson.Patient.LeftEye.Prescription.PrismValue1 =
    itemLeft?.prismInOut ?? dataJson?.Patient?.LeftEye?.Prescription?.PrismValue1;

  // Tint
  const tints = mapTint({
    fullTint: itemLeft?.fullTint,
    photoTint: itemLeft?.photoTint,
    graduatedTint: itemLeft?.graduatedTint,
  });
  dataJson.Patient.LeftEye.LensDetail.Tint =
    tints.length > 0 ? tints : dataJson?.Patient?.LeftEye?.LensDetail?.Tint;

  // .LEVertex     riga 54 ambigua
  dataJson.Patient.RightEye.SpecialParameters.LEVertex =
    itemLeft?.vertexDistance ?? dataJson?.Patient?.RightEye?.SpecialParameters?.LEVertex;
  //Leercd  check
  dataJson.Patient.RightEye.SpecialParameters.LEERCD =
    itemLeft?.ercd ?? dataJson?.Patient?.RightEye?.SpecialParameters?.LEERCD;

  // .LEInset
  dataJson.Patient.RightEye.SpecialParameters.LEInset =
    itemLeft?.inset ?? dataJson?.Patient?.RightEye?.SpecialParameters?.LEInset;

  return dataJson;
};

export const mapItemsRight = (objectJSON: { [key: string]: any }, itemRight?: ItemRight): any => {
  objectJSON.Patient = objectJSON?.Patient || {};
  objectJSON.Patient.RightEye = objectJSON?.Patient?.RightEye || {};
  objectJSON.Patient.SpecialParameters = objectJSON?.Patient?.SpecialParameters || {};
  objectJSON.Patient.RightEye.Prescription = objectJSON?.Patient?.RightEye?.Prescription || {};
  objectJSON.Patient.RightEye.Lens = objectJSON?.Patient?.RightEye?.Lens || {};
  objectJSON.Patient.RightEye.LensDetail = objectJSON?.Patient?.RightEye?.LensDetail || {};
  objectJSON.Patient.RightEye.SpecialParameters =
    objectJSON?.Patient?.RightEye?.SpecialParameters || {};
  objectJSON.Patient.RightEye.Chamfer = objectJSON?.Patient?.RightEye?.Chamfer || {};
  objectJSON.Patient.RightEye.Chamfer.Back = objectJSON?.Patient?.RightEye?.Chamfer?.Back || {};
  objectJSON.Patient.RightEye.Chamfer.Front = objectJSON?.Patient?.RightEye?.Chamfer?.Front || {};
  objectJSON.Patient.RightEye.EdgeFinish = objectJSON?.Patient?.RightEye?.EdgeFinish || {};
  objectJSON.Patient.RightEye.EdgeFinishValue =
    objectJSON?.Patient?.RightEye?.EdgeFinishValue || {};
  objectJSON.Patient.RightEye.LensDetail.Diameter =
    objectJSON?.Patient?.RightEye?.LensDetail?.Diameter || {};
  objectJSON.Patient.RightEye.LensDetail.LensProcess =
    objectJSON?.Patient?.RightEye?.LensDetail?.LensProcess || {};
  objectJSON.Patient.RightEye.Measurements = objectJSON?.Patient?.RightEye?.Measurements || {};
  objectJSON.Patient.RightEye.Measurements.Thickness =
    objectJSON?.Patient?.RightEye?.Measurements?.Thickness || {};
  objectJSON.Patient.RightEye.Measurements.Thickness.ThicknessType =
    objectJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType || {};
  objectJSON.Patient.RightEye.Measurements.Thickness.ThicknessValue =
    objectJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessValue || {};

  // -> **************** DON'T TOUCH IT
  objectJSON.Patient.LeftEye.SpecialParameters =
    objectJSON?.Patient?.LeftEye?.SpecialParameters || {};
  objectJSON.Patient.LeftEye = objectJSON?.Patient?.LeftEye || {};
  // <- **************** DON'T TOUCH IT

  // Sphere
  objectJSON.Patient.RightEye.Prescription.Sphere =
    itemRight?.sphere ?? objectJSON?.Patient?.RightEye?.Prescription?.Sphere;

  // Cylinder
  objectJSON.Patient.RightEye.Prescription.Cylinder =
    itemRight?.cylinder ?? objectJSON?.Patient?.RightEye?.Prescription?.Cylinder;
  // Axis
  objectJSON.Patient.RightEye.Prescription.Axis =
    itemRight?.axis ?? objectJSON?.Patient?.RightEye?.Prescription?.Axis;
  // Addition
  objectJSON.Patient.RightEye.Prescription.Addition =
    itemRight?.add ?? objectJSON?.Patient?.RightEye?.Prescription?.Addition;

  // RightEye.Chamfer.Back.ResourceName
  objectJSON.Patient.RightEye.Chamfer.Back.ResourceName =
    itemRight?.chamferBackResourceName ||
    objectJSON?.Patient?.RightEye?.Chamfer?.Back?.ResourceName;

  // RightEye.Chamfer.Front.ResourceName
  objectJSON.Patient.RightEye.Chamfer.Front.ResourceName =
    itemRight?.chamferFrontResourceName ??
    objectJSON?.Patient?.RightEye?.Chamfer?.Front?.ResourceName;

  // RightEye.EdgeFinish.ResourceName
  objectJSON.Patient.RightEye.EdgeFinish.ResourceName =
    itemRight?.edgeFinishResourceName ?? objectJSON?.Patient?.RightEye?.EdgeFinish?.ResourceName;

  // RightEye.EdgeFinishValue.ResourceName
  objectJSON.Patient.RightEye.EdgeFinishValue.ResourceName =
    itemRight?.edgeFinishValueResourceNam ??
    objectJSON?.Patient?.RightEye?.EdgeFinishValue?.ResourceName;

  // RightEye.LensDetail.Diameter.PhysicalDiameter
  objectJSON.Patient.RightEye.LensDetail.Diameter.PhysicalDiameter =
    itemRight?.lensDetailDiameterPhysical ??
    objectJSON?.Patient?.RightEye?.LensDetail?.Diameter?.PhysicalDiameter;

  // RightEye.LensDetail.LensProcess.EquiThinning
  objectJSON.Patient.RightEye.LensDetail.LensProcess.EquiThinning =
    itemRight?.lensDetailLensProcessEqui ??
    objectJSON?.Patient?.RightEye?.LensDetail?.LensProcess?.EquiThinning;

  // RightEye.Measurements.Thickness.ThicknessType.Precal
  objectJSON.Patient.RightEye.Measurements.Thickness.ThicknessType.Precal =
    itemRight?.thicknessTypePrecal ??
    objectJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Precal;

  // RightEye.Measurements.Thickness.ThicknessType.Resource
  objectJSON.Patient.RightEye.Measurements.Thickness.ThicknessType.Resource =
    itemRight?.thicknessTypeResource ??
    objectJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Resource;

  // RightEye.Measurements.Thickness.ThicknessValue.DefaultThickness
  objectJSON.Patient.RightEye.Measurements.Thickness.ThicknessValue.DefaultThickness =
    itemRight?.thicknessValueDefaultThickn ??
    objectJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessValue?.DefaultThickness;

  // Patient.RightEye.Prescription.DecentrationAxis1
  objectJSON.Patient.RightEye.Prescription.DecentrationAxis1 =
    itemRight?.decentrationAxis1 ?? objectJSON?.Patient?.RightEye?.Prescription?.DecentrationAxis1;

  // Patient.RightEye.Prescription.DecentrationAxis2
  objectJSON.Patient.RightEye.Prescription.DecentrationAxis2 =
    itemRight?.decentrationAxis2 ?? objectJSON?.Patient?.RightEye?.Prescription?.DecentrationAxis2;

  // Patient.RightEye.Prescription.DecentrationAxisStr1
  objectJSON.Patient.RightEye.Prescription.DecentrationAxisStr1 =
    itemRight?.decentrationAxisStr1 ??
    objectJSON?.Patient?.RightEye?.Prescription?.DecentrationAxisStr1;

  // Patient.RightEye.Prescription.DecentrationAxisStr2
  objectJSON.Patient.RightEye.Prescription.DecentrationAxisStr2 =
    itemRight?.decentrationAxisStr2 ??
    objectJSON?.Patient?.RightEye?.Prescription?.DecentrationAxisStr2;

  // Patient.RightEye.Prescription.PrismValue2
  objectJSON.Patient.RightEye.Prescription.PrismValue2 =
    itemRight?.prismUp ?? objectJSON?.Patient?.RightEye?.Prescription?.PrismValue2;

  // PrismValue1
  objectJSON.Patient.RightEye.Prescription.PrismValue1 =
    itemRight?.prismInOut ?? objectJSON?.Patient?.RightEye?.Prescription?.PrismValue1;
  // PrismAxis1
  objectJSON.Patient.RightEye.Prescription.PrismAxis1 =
    itemRight?.direction ?? objectJSON?.Patient?.RightEye?.Prescription?.PrismAxis1;

  // PrismValue2
  objectJSON.Patient.RightEye.Prescription.PrismValue2 =
    itemRight?.prismUD ?? objectJSON?.Patient?.RightEye?.Prescription?.PrismValue2;

  // PrismAxis2
  objectJSON.Patient.RightEye.Prescription.PrismAxis1 =
    itemRight?.directionUD ?? objectJSON?.Patient?.RightEye?.Prescription?.PrismAxis1;

  // PD
  objectJSON.Patient.RightEye.Measurements.PD =
    itemRight?.pd?.toString() ?? objectJSON?.Patient?.RightEye?.Measurements?.PD;
  // Height
  objectJSON.Patient.RightEye.Measurements.Height =
    itemRight?.mountingHeight?.toString() ?? objectJSON?.Patient?.RightEye?.Measurements?.Height;
  // OC
  objectJSON.Patient.RightEye.Measurements.OC =
    itemRight?.mountingHeight?.toString() ?? objectJSON?.Patient?.RightEye?.Measurements?.OC;
  //Eye code
  objectJSON.Patient.RightEye.SpecialParameters.EyeCode =
    itemRight?.eyecode ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.EyeCode;
  //Init
  objectJSON.Patient.RightEye.SpecialParameters.Init =
    itemRight?.wearerInitial ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.Init;

  // HECoeff
  objectJSON.Patient.RightEye.SpecialParameters.HECoeff =
    (itemRight?.hECoeff || itemRight?.HECoeff) ??
    objectJSON?.Patient?.RightEye?.SpecialParameters?.HECoeff;

  // PantoAngle
  objectJSON.Patient.RightEye.SpecialParameters.PantoAngle =
    itemRight?.pantoTilt ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.PantoAngle;
  //Cape
  objectJSON.Patient.RightEye.SpecialParameters.CAPE =
    itemRight?.headCapeAngle ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.CAPE;

  //reercd
  objectJSON.Patient.SpecialParameters.REERCD =
    itemRight?.ercd ?? objectJSON?.Patient?.SpecialParameters?.REERCD;

  //reercd
  objectJSON.Patient.RightEye.SpecialParameters.REERCD =
    itemRight?.ercd ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.REERCD;

  // ReadingDistance
  objectJSON.Patient.RightEye.SpecialParameters.ReadingDistance =
    itemRight?.readingDistance ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.ReadingDistance;

  // DominantEye
  objectJSON.Patient.RightEye.SpecialParameters.DominantEye =
    itemRight?.dominantEye ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.DominantEye;
  // WrapAngle
  objectJSON.Patient.RightEye.SpecialParameters.WrapAngle =
    itemRight?.faceformTilt ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.WrapAngle;

  // .REVertex
  objectJSON.Patient.SpecialParameters.REVertex =
    itemRight?.vertexDistance ?? objectJSON?.Patient?.SpecialParameters?.REVertex;

  // .REVertex
  objectJSON.Patient.RightEye.SpecialParameters.REVertex =
    itemRight?.vertexDistance ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.REVertex;

  // .REInset
  objectJSON.Patient.SpecialParameters.REInset =
    itemRight?.inset ?? objectJSON?.Patient?.SpecialParameters?.REInset;

  // .REInset
  objectJSON.Patient.RightEye.SpecialParameters.REInset =
    itemRight?.inset ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.REInset;

  // .REDistancePD
  objectJSON.Patient.RightEye.SpecialParameters.REDistancePD =
    itemRight?.distancePD ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.REDistancePD;

  // .LEDistancePD
  objectJSON.Patient.RightEye.SpecialParameters.LEDistancePD =
    itemRight?.distancePD ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.LEDistancePD;

  // NearVisionBehaviour
  objectJSON.Patient.RightEye.SpecialParameters.NearVisionBehaviour =
    itemRight?.nearVisionBehaviour ??
    objectJSON?.Patient?.RightEye?.SpecialParameters?.NearVisionBehaviour;

  // Lens.DesignTypeNameValue
  objectJSON.Patient.RightEye.Lens.DesignTypeNameValue =
    itemRight?.lensDesignTypeNameValue ?? objectJSON?.Patient?.RightEye?.Lens?.DesignTypeNameValue;

  // DesignNameValue
  objectJSON.Patient.RightEye.Lens.DesignNameValue =
    itemRight?.lensDesignNameValue ?? objectJSON?.Patient?.RightEye?.Lens?.DesignNameValue;

  // MaterialNameValue
  objectJSON.Patient.RightEye.Lens.MaterialNameValue =
    itemRight?.lensMaterialNameValue ?? objectJSON?.Patient?.RightEye?.Lens?.MaterialNameValue;

  // Treatment
  const treatment = mapTreatment(itemRight?.treatment);
  objectJSON.Patient.RightEye.LensDetail.Treatment =
    treatment.length > 0 ? treatment : objectJSON?.Patient?.RightEye?.LensDetail?.Treatment;

  // .REHeight
  objectJSON.Patient.RightEye.SpecialParameters.REHeight =
    itemRight?.height ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.REHeight;
  // .LEHeight
  objectJSON.Patient.RightEye.SpecialParameters.LEHeight =
    itemRight?.height ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.LEHeight;

  // .REFarPD
  objectJSON.Patient.RightEye.SpecialParameters.REFarPD =
    itemRight?.farPD ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.REFarPD;

  // .LEFarPD
  objectJSON.Patient.RightEye.SpecialParameters.LEFarPD =
    itemRight?.farPD ?? objectJSON?.Patient?.RightEye?.SpecialParameters?.LEFarPD;

  // .Lens.Code
  objectJSON.Patient.RightEye.Lens.Code =
    itemRight?.lensCode ?? objectJSON?.Patient?.RightEye?.Lens?.Code;

  // Tint
  const tints = mapTint({
    fullTint: itemRight?.fullTint,
    photoTint: itemRight?.photoTint,
    graduatedTint: itemRight?.graduatedTint,
  });
  objectJSON.Patient.RightEye.LensDetail.Tint =
    tints.length > 0 ? tints : objectJSON?.Patient?.RightEye?.LensDetail?.Tint;

  //reercd check
  objectJSON.Patient.LeftEye.SpecialParameters.REERCD =
    itemRight?.ercd ?? objectJSON?.Patient?.LeftEye?.SpecialParameters?.REERCD;
  // .REVertex    riga 53 ambigua
  objectJSON.Patient.LeftEye.SpecialParameters.REVertex =
    itemRight?.vertexDistance ?? objectJSON?.Patient?.LeftEye?.SpecialParameters?.REVertex;
  // .REInset
  objectJSON.Patient.LeftEye.SpecialParameters.REInset =
    itemRight?.inset ?? objectJSON?.Patient?.LeftEye?.SpecialParameters?.REInset;

  objectJSON.Patient.LeftEye.SpecialParameters.REDistancePD =
    itemRight?.distancePD ?? objectJSON?.Patient?.LeftEye?.SpecialParameters?.REDistancePD;

  return objectJSON;
};

export const mapOrderDetailsJSON = (
  orderJSON: any,
  orderDetailsResult?: Ord03Items
): OrderHistoryDetailsEssilor => {
  return {
    prescriptionDetails: getPrescriptionDetailsOrd03(orderJSON, orderDetailsResult),
    prescriptionAdditionalParams: {
      eyecode:
        orderDetailsResult?.itemRight?.eyecode ||
        orderDetailsResult?.itemLeft?.eyecode ||
        orderJSON?.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "EyeCode"
        )?.Value ||
        "-",
      cape:
        orderDetailsResult?.itemRight?.headCapeAngle ||
        orderDetailsResult?.itemLeft?.headCapeAngle ||
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "CAPE")
          ?.Value ||
        "-",
      ercdRight:
        orderDetailsResult?.itemRight?.ercd ||
        orderDetailsResult?.itemLeft?.ercd ||
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "REERCD")
          ?.Value ||
        "-",
      ercdLeft:
        orderDetailsResult?.itemRight?.ercd ||
        orderDetailsResult?.itemLeft?.ercd ||
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "LEERCD")
          ?.Value ||
        "-",
      progressLength:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "PrgLength" //mancante
        )?.Value || "-",
      reInset:
        orderDetailsResult?.itemRight?.inset ||
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "REInset")
          ?.Value ||
        "-",
      leInset:
        orderDetailsResult?.itemLeft?.inset ||
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "LEInset")
          ?.Value ||
        "-",
      wrapAngle:
        orderDetailsResult?.itemRight?.faceformTilt ||
        orderDetailsResult?.itemLeft?.faceformTilt ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "WrapAngle"
        )?.Value ||
        "-",
      initials:
        orderDetailsResult?.itemRight?.wearerInitial ||
        orderDetailsResult?.itemLeft?.wearerInitial ||
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "Init")
          ?.Value ||
        "-",
      // useInsets: orderJSON.Patient?.Right?.SpecialParameters?.find(
      //   (_: any) => _?.JavascriptID === "UseInsets"
      // )?.Value
      //   ? "ORDER_HISTORY_YES"
      //   : "ORDER_HISTORY_NO",
      hestCoeff:
        orderDetailsResult?.itemRight?.hECoeff ||
        orderDetailsResult?.itemLeft?.hECoeff ||
        orderDetailsResult?.itemRight?.HECoeff ||
        orderDetailsResult?.itemLeft?.HECoeff ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "HECoeff" || _?.JavascriptID === "STCoeff"
        )?.Value ||
        "-",
      pantoAngle:
        orderDetailsResult?.itemRight?.pantoTilt ||
        orderDetailsResult?.itemLeft?.pantoTilt ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "PantoAngle"
        )?.Value ||
        "-",
      readingDistance:
        orderDetailsResult?.itemRight?.readingDistance ||
        orderDetailsResult?.itemLeft?.readingDistance ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "ReadingDistance"
        )?.Value ||
        "-",
      dominantEye:
        orderDetailsResult?.itemRight?.dominantEye ||
        orderDetailsResult?.itemLeft?.dominantEye ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "DominantEye"
        )?.Value ||
        "-",
      nvBehaviour:
        orderDetailsResult?.itemRight?.nearVisionBehaviour ||
        orderDetailsResult?.itemLeft?.nearVisionBehaviour ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "NearVisionBehaviour"
        )?.Value ||
        "-",
      reVertex:
        orderDetailsResult?.itemRight?.vertexDistance ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "REVertex"
        )?.Value ||
        "-",
      leVertex:
        orderDetailsResult?.itemLeft?.vertexDistance ||
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "LEVertex"
        )?.Value ||
        "-",
    },
    jobInfo: {
      customerReference: (
        (orderJSON?.Patient?.FirstName || "") +
        " " +
        (orderJSON?.Patient?.LastName || "")
      ).trim(),
      jobTypeCode: orderJSON.JobType.Code,
      jobTypeDescription: orderJSON.JobType.ResourceName,
      rxID: orderJSON.OrderIdBase64,
    },
    lensInfo: {
      lensID:
        orderDetailsResult?.itemRight?.lensCode ||
        orderDetailsResult?.itemLeft?.lensCode ||
        orderJSON?.Patient?.RightEye?.Lens?.Code,
      lensDescription:
        orderDetailsResult?.itemGeneric?.lensDescription || //
        orderDetailsResult?.itemRight?.treatment ||
        orderJSON?.Patient?.RightEye?.Lens?.LensName ||
        "-" +
          " - " +
          orderJSON?.Patient?.RightEye?.Lens?.LensDetail?.Treatment?.map((_: any) => _.Value).join(
            ","
          ) ||
        "-",
      lensTint:
        orderDetailsResult?.itemRight?.fullTint ||
        orderDetailsResult?.itemRight?.photoTint ||
        orderDetailsResult?.itemRight?.graduatedTint ||
        orderJSON?.Patient?.RightEye?.Lens?.LensDetail?.Tint?.map((_: any) => _.Value).join(",") ||
        "-",
      brand: orderJSON?.Patient?.RightEye?.Lens?.BrandCode || "-",
      thumbnail: orderJSON?.Shape?.Thumbnail,
    },
  };
};

export const getPrescriptionDetails = (orderJSON: any): FocalTypePrescription[] => {
  return [
    {
      parameterName: "ESSILOR_PRESCRIPTION_SPHERE",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Sphere || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Sphere || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_CYLINDER",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Cylinder || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Cylinder || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_AXIS",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Axis || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Axis || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_ADDITION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Addition || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Addition || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue1 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue1 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRISM_DIRECTION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis1 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis1 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue2 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue2 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIRECTION_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis2 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis2 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PD",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Measurements?.PD?.toString() || "",
      right: orderJSON?.Patient?.RightEye?.Measurements?.PD?.toString() || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_HEIGHT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        (
          orderJSON?.Patient?.LeftEye?.Measurements?.Height ||
          orderJSON?.Patient?.LeftEye?.Measurements?.OC
        )?.toString() || "",
      right:
        (
          orderJSON?.Patient?.RightEye?.Measurements?.Height ||
          orderJSON?.Patient?.RightEye?.Measurements?.OC
        )?.toString() || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_VERTEX_FITTED",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderJSON.Patient?.LeftEye?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "LEVertex"
        )?.Value || "",
      right:
        orderJSON?.Patient?.RightEye?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "REVertex"
        )?.Value || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_FACEFORM_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "WrapAngle")
          ?.Value || "",
      right:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "WrapAngle")
          ?.Value || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PANTO_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "PantoAngle")
          ?.Value || "",
      right:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "PantoAngle")
          ?.Value || "",
    },
  ];
};

export const getPrescriptionDetailsOrd03 = (
  orderJSON: any,
  orderDetailsResult?: Ord03Items
): FocalTypePrescription[] => {
  return [
    {
      parameterName: "ESSILOR_PRESCRIPTION_SPHERE",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.sphere ||
        orderJSON?.Patient?.LeftEye?.Prescription?.Sphere ||
        "",
      right:
        orderDetailsResult?.itemRight?.sphere ||
        orderJSON?.Patient?.RightEye?.Prescription?.Sphere ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_CYLINDER",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.cylinder ||
        orderJSON?.Patient?.LeftEye?.Prescription?.Cylinder ||
        "",
      right:
        orderDetailsResult?.itemRight?.cylinder ||
        orderJSON?.Patient?.RightEye?.Prescription?.Cylinder ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_AXIS",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.axis || orderJSON?.Patient?.LeftEye?.Prescription?.Axis || "",
      right:
        orderDetailsResult?.itemRight?.axis ||
        orderJSON?.Patient?.RightEye?.Prescription?.Axis ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_ADDITION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.add ||
        orderJSON?.Patient?.LeftEye?.Prescription?.Addition ||
        "",
      right:
        orderDetailsResult?.itemRight?.add ||
        orderJSON?.Patient?.RightEye?.Prescription?.Addition ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.prismInOut ||
        orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue1 ||
        "",
      right:
        orderDetailsResult?.itemRight?.prismInOut ||
        orderJSON?.Patient?.RightEye?.Prescription?.PrismValue1 ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRISM_DIRECTION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.direction ||
        orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis1 ||
        "",
      right:
        orderDetailsResult?.itemRight?.direction ||
        orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis1 ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.prismUD ||
        orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue2 ||
        "",
      right:
        orderDetailsResult?.itemRight?.prismUD ||
        orderJSON?.Patient?.RightEye?.Prescription?.PrismValue2 ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIRECTION_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.directionUD ||
        orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis2 ||
        "",
      right:
        orderDetailsResult?.itemRight?.directionUD ||
        orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis2 ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PD",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemRight?.pd?.toString() ||
        orderJSON?.Patient?.LeftEye?.Measurements?.PD?.toString() ||
        "",
      right:
        orderDetailsResult?.itemRight?.pd?.toString() ||
        orderJSON?.Patient?.RightEye?.Measurements?.PD?.toString() ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_HEIGHT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemRight?.mountingHeight?.toString() ||
        (
          orderJSON?.Patient?.LeftEye?.Measurements?.Height ||
          orderJSON?.Patient?.LeftEye?.Measurements?.OC
        )?.toString() ||
        "",
      right:
        (
          orderJSON?.Patient?.RightEye?.Measurements?.Height ||
          orderJSON?.Patient?.RightEye?.Measurements?.OC
        )?.toString() || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_VERTEX_FITTED",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.vertexDistance ||
        orderJSON.Patient?.LeftEye?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "LEVertex"
        )?.Value ||
        "",
      right:
        orderDetailsResult?.itemRight?.vertexDistance ||
        orderJSON?.Patient?.RightEye?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "REVertex"
        )?.Value ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_FACEFORM_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.faceformTilt ||
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "WrapAngle")
          ?.Value ||
        "",
      right:
        orderDetailsResult?.itemRight?.faceformTilt ||
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "WrapAngle")
          ?.Value ||
        "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PANTO_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderDetailsResult?.itemLeft?.pantoTilt ||
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "PantoAngle")
          ?.Value ||
        "",
      right:
        orderDetailsResult?.itemRight?.pantoTilt ||
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "PantoAngle")
          ?.Value ||
        "",
    },
  ];
};

export const getEssilorPrescriptionValues = (
  prescription: OrderPrescription
): FocalTypePrescription[] => {
  const itemLeft: ItemLeft = prescription.itemLeft;
  const itemRight: ItemRight = prescription.itemRight;

  return [
    {
      parameterName: "ESSILOR_PRESCRIPTION_SPHERE",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.sphere || "",
      right: itemRight?.sphere || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_CYLINDER",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.cylinder || "",
      right: itemRight?.cylinder || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_AXIS",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.axis || "",
      right: itemRight?.axis || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_ADDITION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.add || "",
      right: itemRight?.add || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.prism || "",
      right: itemRight?.prism || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRISM_DIRECTION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.direction || "",
      right: itemRight?.direction || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.prismUD || "",
      right: itemRight?.prismUD || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIRECTION_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.directionUD || "",
      right: itemRight?.directionUD || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PD",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.pd || "",
      right: itemRight?.pd || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_HEIGHT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.ocHt || itemLeft?.mountingHeight || "",
      right: itemRight?.ocHt || itemRight?.mountingHeight || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_VERTEX_FITTED",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.vertexFitted || "",
      right: itemRight?.vertexFitted || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_FACEFORM_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.faceformTilt || "",
      right: itemRight?.faceformTilt || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PANTO_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.pantoTilt || "",
      right: itemRight?.pantoTilt || "",
    },
  ];
};

export const getIsEssilorBrand = (brand: string): boolean => {
  const brandList = ["EL", "VX", "ST", "EY", "TRS", "CRZ", "XPR", "WH", "RI", "HP", "NI", "GW"];
  return brandList.includes(brand);
};

export const isEssilorWithoutSpecialParams = (
  specialParams: OrderDetailsPrescriptionAdditionalParams
): boolean => {
  return (
    !specialParams?.cape &&
    !specialParams?.ercdLeft &&
    !specialParams?.ercdRight &&
    !specialParams?.eyecode &&
    !specialParams?.initials &&
    !specialParams?.leInset &&
    !specialParams?.reInset &&
    !specialParams?.progressLength &&
    !specialParams?.wrapAngle
  );
};

// export const prescriptionalOrd03Object = (
//   orderDetails: OrderDetailsResult | null
// ): OrderDetailsPrescriptionAdditionalParams | undefined => {
//   if (!orderDetails) return undefined;
//   return {
//     cape:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.headCapeAngle ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.headCapeAngle ||
//       "-",
//     eyecode:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.eyecode ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.eyecode ||
//       "-",
//     ercdRight:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.ercd || "-",
//     ercdLeft:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.ercd || "-",
//     initials:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.wearerInitial ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.wearerInitial ||
//       "-",
//     //manca st
//     hestCoeff:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.HECoeff ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.HECoeff ||
//       "-",
//     progressLength: "-", //manca st
//     pantoAngle:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.pantoTilt ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.pantoTilt ||
//       "-",
//     readingDistance:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.readingDistance ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.readingDistance ||
//       "-",
//     dominantEye:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.laterality ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.laterality ||
//       "-",
//     nvBehaviour:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.nearVisionBehaviour ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.nearVisionBehaviour ||
//       "-",
//     wrapAngle:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.faceformTilt ||
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemLeft?.faceformTilt ||
//       "-",
//     reInset:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.inset || "-",
//     leVertex:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.vertexDistance || "-",
//     reVertex:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.vertexDistance || "-",
//     leInset:
//       orderDetails?.deliveryDetail?.[0]?.brandDetails?.[0]?.zskuDetail?.[0].prescription?.[0]
//         ?.itemRight?.inset || "-",
//   };
// };
