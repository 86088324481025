import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";

import useTranslation from "../../../../hooks/useTranslation";
import useCanRender from "../../../../hooks/useCanRender";
import { FirstLevelMenu } from "../../../../interfaces/menuInterfaces";
import {
  selectActiveDoor,
  selectCanBuyModelsGlobal,
  selectIsMultidoor,
  selectIsSubuser,
} from "../../../../store/user/userSlice";
import { selectGetMenuStatus, selectMenu } from "../../../../store/store/storeSlice";
import ConditionalRender from "../../../widgets/ConditionalRender";
import CustomText from "../../../styled-UI/CustomText";
import LoaderNavItem from "../../../styled-UI/loader/menu/LoaderNavItem";
import WrapperLayout from "../../WrapperLayout";
import CategoriesMenu from "./categories-menu/CategoriesMenu";
import ServicesMenu from "./services-menu/ServicesMenu";
import { serviceMenu } from "./services-menu/serviceSections";
import BrandMenu from "./BrandMenu";
import NavbarMenuLinkButton from "./NavbarMenuLinkButton";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/ext-link.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x-icon.svg";
import NewTag from "../../../widgets/tutorial-pills/new-tag/NewTag";
import { externalReferenceNewTag } from "../../../../utils/cmsUtils";
import { getEyemed, redirectToEssilorPro } from "../../../../store/store/storeSagas";

interface Props {
  marginTop?: number;
}

export type MenuLinkButtonType = "EssilorPro" | "Eyemed";

const NavbarMenu = ({ marginTop = 0 }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuData = useSelector(selectMenu);
  const getMenuStatus = useSelector(selectGetMenuStatus);
  const isMultidoor = useSelector(selectIsMultidoor);
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);
  const canShowBrands = canBuyModelsGlobal || canRender("LENS_DIGITAL_CATALOGUE");
  const [toggleMenu, setMenu] = useState<string>("");
  const [showServiceMenu, setShowServiceMenu] = useState<boolean>(false);
  const [menuUpdatedHeight, setMenuUpdatedHeight] = useState<number>(0);
  const [hideServices, setHideServices] = useState<boolean>(false);
  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);
  //// This variable is used in order to check if there is only one item in navbar menu right
  //// in order to dynamically change the width of the border-bottom in the NavBar menuRight style.
  const onlyOneMenuRight = !(canRender("LEONARDO_SECTION") && canRender("ESSILOR_PRO"));

  const showBrands = menuData.find((menuitem: FirstLevelMenu) => {
    return menuitem.identifier === "BRANDS";
  });
  const showBrandsEssilor = menuData.find((_: FirstLevelMenu) => {
    return _.identifier === "ESSILOR";
  });

  useEffect(() => {
    serviceMenu.forEach((column) => {
      column.links?.forEach((value) => {
        if (!showServiceMenu) {
          if (!value.privilege || canRender(value.privilege)) setShowServiceMenu(true);
        }
      });
    });
  }, [serviceMenu]);

  useEffect(() => {
    const menuHeight = document.getElementById("menu-container")?.offsetHeight ?? 0;
    const navbarHeight = document.getElementsByTagName("nav")?.[0].offsetHeight ?? 0;
    const headerHeight = document.getElementById("header")?.offsetHeight ?? 0;
    const viewportHeight = document.body.offsetHeight - navbarHeight - headerHeight;
    if (menuHeight > viewportHeight) {
      setMenuUpdatedHeight(viewportHeight);
    } else {
      setMenuUpdatedHeight(menuHeight);
    }
  }, [toggleMenu]);

  //Hide services options based on catalogue data
  useEffect(() => {
    if (menuData) {
      menuData.forEach((_: FirstLevelMenu) => {
        if (_.name === "PRODUCTS" || _.name === "BRANDS" || _.name === "ESSILOR") {
          if (_.catalogGroupView?.length === 0) {
            setHideServices(true);
          }
        }
      });
    }
  }, [menuData]);

  //MANAGE THE CLICK OUTSIDE THE MENU IN ORDER TO CLOSE IT
  useEffect(() => {
    function handler(event: MouseEvent) {
      if (menuRef.current) {
        const target = event.target as Node;
        if (!menuRef.current.contains(target)) setToggleMenu("");
        event.stopPropagation();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  const setToggleMenu = (value: string) => setMenu(value);

  const handleOnClick = (type: MenuLinkButtonType, callback: () => void) => {
    const callbackParams = {
      callback: (success?: boolean, data?: string) => {
        if (success && data) window.open(data);
        else callback?.();
      },
    };

    dispatch(
      type === "EssilorPro" ? redirectToEssilorPro(callbackParams) : getEyemed(callbackParams)
    );
  };

  return (
    <WrapperMenu ref={menuRef}>
      <MenuLeft>
        {getMenuStatus === "SUCCESS" && (showBrands || showBrandsEssilor) && (
          <>
            <NavElement
              key={showBrands?.uniqueID || showBrandsEssilor?.uniqueID}
              onClick={(e) => {
                const menuId = showBrands?.identifier
                  ? showBrands.identifier
                  : showBrandsEssilor?.identifier
                  ? showBrandsEssilor.identifier
                  : "";
                if (menuId) {
                  toggleMenu === "" || toggleMenu !== menuId
                    ? setToggleMenu(menuId)
                    : setToggleMenu("");
                }
              }}
              data-element-id="MainNav_Brands"
              className="menuLeft"
            >
              <CustomText as="button" fontSizePx={13} color="primary" font="font-bold" uppercase>
                {showBrands ? showBrands.name : translateLabel("MENU_TITLE_BRANDS")}
              </CustomText>

              {toggleMenu !== "" &&
                (toggleMenu === showBrands?.identifier ||
                  toggleMenu === showBrandsEssilor?.identifier) &&
                canShowBrands && (
                  <MenuContainer
                    className={clsx("menu", isMultidoor && "menu-multidoor")}
                    marginTop={marginTop}
                    menuUpdatedHeight={menuUpdatedHeight}
                    id="menu-container"
                  >
                    <Menu>
                      <WrapperLayout>
                        <WrapperButton>
                          <CloseButton
                            onClick={() => {
                              setToggleMenu("");
                            }}
                            data-element-id="MainNav_Menu_Close"
                          >
                            <CloseIcon />
                          </CloseButton>
                        </WrapperButton>
                        {showBrands && (
                          <BrandMenu
                            setToggleMenu={setToggleMenu}
                            brandMenuData={showBrands.catalogGroupView}
                            enableDataDescription
                            isEssilor={false}
                          />
                        )}
                        {showBrands && showBrandsEssilor && <Divider />}
                        {showBrandsEssilor && (
                          <BrandMenu
                            setToggleMenu={setToggleMenu}
                            brandMenuData={showBrandsEssilor.catalogGroupView}
                            enableDataDescription
                            isEssilor={true}
                          />
                        )}
                      </WrapperLayout>
                    </Menu>
                  </MenuContainer>
                )}
            </NavElement>
          </>
        )}
        {getMenuStatus === "SUCCESS" &&
          menuData.map((menuitem: FirstLevelMenu) => {
            return (
              <>
                {canBuyModelsGlobal &&
                  menuitem.identifier !== "BRANDS" &&
                  menuitem.identifier !== "ESSILOR" && (
                    <NavElement
                      key={menuitem.uniqueID}
                      data-element-id="MainNav_Products"
                      onClick={() => {
                        if (menuitem.identifier) {
                          toggleMenu === "" || toggleMenu !== menuitem.identifier
                            ? setToggleMenu(menuitem.identifier)
                            : setToggleMenu("");
                        } else setToggleMenu("");
                      }}
                      className="menuLeft"
                    >
                      <CustomText
                        as="button"
                        fontSizePx={13}
                        color="primary"
                        font="font-bold"
                        uppercase
                      >
                        {menuitem.name}
                      </CustomText>

                      {toggleMenu !== "" && toggleMenu === menuitem.identifier && (
                        <MenuContainer
                          className={clsx("menu", isMultidoor && "menu-multidoor")}
                          marginTop={marginTop}
                          menuUpdatedHeight={menuUpdatedHeight}
                          id="menu-container"
                        >
                          <Menu>
                            {menuitem.identifier === "PRODUCTS" && canBuyModelsGlobal && (
                              <WrapperLayout>
                                <CategoriesMenu
                                  catMenuData={menuitem.catalogGroupView}
                                  setToggleMenu={setToggleMenu}
                                  marginTop={marginTop}
                                />
                              </WrapperLayout>
                            )}
                          </Menu>
                        </MenuContainer>
                      )}
                    </NavElement>
                  )}
              </>
            );
          })}
        {getMenuStatus === "LOADING" && (
          <>
            <NavElement>
              <LoaderNavItem />
            </NavElement>
            <NavElement>
              <LoaderNavItem />
            </NavElement>
          </>
        )}

        {showServiceMenu && serviceMenu.length > 0 && getMenuStatus === "SUCCESS" && !hideServices && (
          <NavElement
            onClick={() => {
              toggleMenu === "" || toggleMenu !== "SERVICES"
                ? setToggleMenu("SERVICES")
                : setToggleMenu("");
            }}
            className="menuLeft"
          >
            <CustomText
              as="button"
              fontSizePx={13}
              color="primary"
              font="font-bold"
              uppercase
              data-element-id="MainNav_Services"
            >
              {translateLabel("SERVICES_AND_REWARDS")}
            </CustomText>

            {toggleMenu && toggleMenu === "SERVICES" && (
              <MenuContainer
                className={clsx("menu", isMultidoor && "menu-multidoor")}
                marginTop={marginTop}
                menuUpdatedHeight={menuUpdatedHeight}
                id="menu-container"
              >
                <Menu>
                  <WrapperLayout>
                    <ServicesMenu hideServices={hideServices} setToggle={setToggleMenu} />
                  </WrapperLayout>
                </Menu>
              </MenuContainer>
            )}
          </NavElement>
        )}

        {showServiceMenu && serviceMenu.length > 0 && getMenuStatus === "LOADING" && (
          <NavElement>
            <LoaderNavItem />
          </NavElement>
        )}
      </MenuLeft>
      <MenuRight>
        {/* {getMenuStatus === "LOADING" && (
        <NavElement>
          <LoaderNavItem />
        </NavElement>
      )} */}
        {getMenuStatus === "SUCCESS" && (
          <ConditionalRender privilege="EYEMED" isNotBOUser>
            <NavElement>
              <RightButton>
                <NavbarMenuLinkButton
                  onDispatch={handleOnClick}
                  text="INSURANCE"
                  popupTitle="ERROR_POPUP_TITLE"
                  popupContent="HOMEPAGE_PAIRING_ERROR_GENERIC_INSURANCE"
                  type="Eyemed"
                  data-element-id="MainNav_Insurance"
                />
                <LinkIcon />
              </RightButton>
              <MenuRightNewTag>
                <NewTag position={externalReferenceNewTag["INSURANCE"]}></NewTag>
              </MenuRightNewTag>
            </NavElement>
          </ConditionalRender>
        )}

        {getMenuStatus === "SUCCESS" && (
          <ConditionalRender privilege="ESSILOR_PRO" isNotBOUser>
            <NavElement>
              <RightButton>
                <NavbarMenuLinkButton
                  onDispatch={handleOnClick}
                  text="ESSILOR_PRO"
                  popupTitle="ERROR_POPUP_TITLE"
                  popupContent="HOMEPAGE_PAIRING_ERROR_GENERIC"
                  type="EssilorPro"
                  data-element-id="MainNav_Essilorpro"
                />
                <LinkIcon />
              </RightButton>
              <MenuRightNewTag>
                <NewTag position={externalReferenceNewTag["ESSILOR_PRO"]}></NewTag>
              </MenuRightNewTag>
            </NavElement>
          </ConditionalRender>
        )}

        {getMenuStatus === "SUCCESS" && (
          <ConditionalRender privilege="LEONARDO_SECTION" isNotBOUser>
            <NavElement>
              <RightButton>
                <CustomText
                  data-element-id="MainNav_Leonardo"
                  className="buttonRight"
                  as="button"
                  fontSizePx={13}
                  color="primary"
                  font="font-bold"
                  uppercase
                  onClick={() =>
                    window.open(globalEnvVariables.leonardoUrl + "/newuni/bff/myel/sso", "_blank")
                  }
                >
                  {translateLabel("LEONARDO")}
                </CustomText>
                <LinkIcon />
              </RightButton>

              <MenuRightNewTag className="leonardoTag">
                <NewTag position={externalReferenceNewTag["LEONARDO_SECTION"]}></NewTag>
              </MenuRightNewTag>
            </NavElement>
          </ConditionalRender>
        )}
      </MenuRight>

      {toggleMenu && (
        <Overlay
          className={clsx("overlay", isMultidoor && "overlay-multidoor")}
          marginTop={marginTop}
        />
      )}
    </WrapperMenu>
  );
};

const WrapperMenu = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const MenuRightNewTag = styled.div`
  display: flex;
  padding-left: 0.625rem;
  div {
    margin: unset;
  }
`;

const MenuLeft = styled.div`
  display: flex;
`;

const MenuRight = styled.div`
  display: flex;
`;

const RightButton = styled.div`
  display: flex;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.primary};
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const NavElement = styled.span<{ onlyOneMenuRight?: boolean }>`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  & > button {
    word-break: unset;
    border-bottom: 2px solid transparent;
  }
  &:hover:not(:has(*:not(button, svg):hover)) {
    &.menuLeft > button {
      border-bottom: 2px solid ${(props) => props.theme.palette.primary};
    }
  }
`;

const MenuContainer = styled.div<{ marginTop?: number; menuUpdatedHeight?: number }>`
  position: fixed;
  left: 0;
  top: ${(props) => props.marginTop + "rem"};
  width: 100%;
  border-top: solid 1px ${(props) => props.theme.palette.gray.medium};
  z-index: 200;

  overflow-y: scroll;
  overflow-x: hidden;
  height: ${(props) => {
    if (props.menuUpdatedHeight) {
      return props.menuUpdatedHeight + "px";
    }
    return "100%";
  }};

  &.menu-multidoor {
    top: ${(props) => props.marginTop + props.theme.multidoorBannerHeightRem + "rem"};
  }

  @media screen and (max-width: 1366px) {
    max-width: 1366px;
    overflow: auto;
  }
`;

const Divider = styled.div`
  min-width: 1024px;
  max-width: 1360px;
  margin: 0 auto;
  height: 1px;
  background-color: ${(props) => props.theme.palette.gray.medium};
`;

const Overlay = styled.div<{ marginTop?: number }>`
  position: fixed;
  left: 0;
  top: ${(props) => props.marginTop + "rem"};
  width: 100%;
  height: 100vh;
  background-color: rgba(1, 9, 23, 0.8);

  &.overlay-multidoor {
    top: ${(props) => props.marginTop + props.theme.multidoorBannerHeightRem + "rem"};
  }
`;

const Menu = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  z-index: 101;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0 0 0;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.white};
`;

const CloseButton = styled.button`
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default NavbarMenu;
